import React from 'react';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon';

interface BAvatarProps {
    address: string;
    diameter: number;
}

const BAvatar: React.FC<BAvatarProps> = ({address, diameter}) => {
    const seed = jsNumberForAddress(address);

    return (
        <div style={{borderRadius: '50%', overflow: 'hidden', width: diameter, height: diameter}}>
            <Jazzicon diameter={diameter} seed={seed}/>
        </div>
    );
};

export default BAvatar;
