import React, {FC} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import HighchartsExporting from "highcharts/modules/exporting";

// HighchartsExporting(Highcharts);

const ChartGas: FC = () => {
    const chartOptions = {
        chart: {
            type: 'line',
            // zoomType: 'x',
            resetZoomButton: {
                position: {
                    align: 'left', // right by default
                    verticalAlign: 'top',
                    //x: -55,
                    //y: 10
                    x: 10,
                    y: 10
                },
                relativeTo: 'plot'
            }
        },
        exporting: {
            enabled: false,
        },
        legend: {
            enabled: true
        },
        credits: {
            enabled: false
        },
        title: {
            useHTML: true,
            text: ''
        },
        accessibility: {
            enabled: false
        },
        subtitle: {
            text: document.ontouchstart === undefined ?
                'Source: Etherscan.io<br>Click and drag in the plot area to zoom in' :
                'Pinch the chart to zoom in'
        },
        xAxis: {
            categories: ["Jan '17", "Jan '18", "Jan '19", "Jan '20", "Jan '21", "Jan '22", "Jan '23"],
            type: 'datetime',
        },
        yAxis: {
            title: {
                text: 'Gas Price (gwei)'
            }
        },
        tooltip: {
            useHTML: true,
            formatter: function () {
                var result = '<table class="text-dark">';
                result += '<tr><td>Max:</td><td> 83 gwei</td></tr>';
                result += '<tr><td>Average:</td><td> 54 gwei</td></tr>';
                result += '<tr><td>Min:</td><td> 20 gwei</td></tr>';
                result += '</table>';

                return result;
            }
        },
        series: [
            {
                name: 'Low Gas Price',
                data: [36, 31, 17, 14, 19, 14],
                color: '#00c9a7'
            }
            ,
            {
                name: 'Average Gas Price',
                data: [34, 29, 16, 13, 18, 12],
                color: '#3498db',
            }
            ,
            {
                name: 'High Gas Price',
                data: [34, 29, 16, 13, 18, 12],
                color: 'rgb(165, 42, 42)',
            }
        ]
    };

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </div>
    );
};

export default ChartGas;
