import React, {FC} from "react";

interface BAvatarData {
    className?: any;
    size?: any;
}

const BAvatar: FC<BAvatarData> = (props) => {
    return (
        <React.Fragment>
            <figure className={`bs-icon-avatar ${props?.className}`}>
                <div className="mx-auto">
                    <img
                        src={`${process.env.REACT_APP_BASE_URL}/tokens/img/${process.env.REACT_APP_BASE_UNIT}.png`}
                        alt="OpenScan.ai"
                        className="xdc-nav__logo logo-light"
                        style={{width: `${props?.size}px`, height: `${props?.size}px`}}
                    />
                </div>
            </figure>
        </React.Fragment>
    );
};

BAvatar.defaultProps = {
    className: "",
    size: 20
};
export default BAvatar;
