import React, {FC} from "react";
import Image404 from "../../images/404.webp";

const B404: FC = () => {

    return <React.Fragment>
        <div className="bs-coming-soon">
            <div className="bs-coming-soon-container">
                <img className="img-fluid" src={Image404} alt="404 Page not Found"/>
                <h2>Page not Found</h2>
            </div>
        </div>
    </React.Fragment>
};

export default B404;
