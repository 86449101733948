import React, {FC} from "react";
import {useSelector} from "react-redux";
// @ts-ignore
import Slider from "react-slick";


const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    fade: true,
    cssEase: "ease-in-out",
};

const Ads: FC = () => {
    const adsList = useSelector((e: Object) => (e as any)?.common?.config?.ads);

    return <React.Fragment>
        {!!adsList?.length && <div className="position-relative"
                                   style={{width: "100%", maxWidth: "350px"}}
        >
            <span className="position-absolute bg-white text-dark shadow-sm rounded-1 small px-1 bg-white z-1" style={{right: '.75rem', top: '-.5rem'}}>Ad</span>
            <Slider {...settings}>
                {adsList?.map((data: any, index: number) => {
                    return <div className="bs-ad-banner" key={index}>
                        <a href={data?.url} target="_blank">
                            <img
                                className="img-fluid"
                                src={data?.image}
                                alt="OpenScan.ai"
                            />
                        </a>
                    </div>
                })}
            </Slider>
        </div>}
    </React.Fragment>;
};

export default Ads;
