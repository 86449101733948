import {useSelector} from "react-redux";
import {get0xAddress, getFiat, getXDCAddress} from "../utils/function";
import moment from "moment";

const defaultFormat = 'DD/MM/YYYY HH:mm:ss'

const useCommon = () => {
    const prefix = useSelector((e: Object) => (e as any)?.common?.prefix);
    const fiat = useSelector((e: Object) => (e as any)?.common?.fiat);
    const isRelativeTimeFormat = useSelector((e: Object) => (e as any)?.common?.isRelativeTimeFormat);
    const isUTCTimeZoneFormat = useSelector((e: Object) => (e as any)?.common?.isUTCTimeZoneFormat);

    return {
        get0x: (address = "") => get0xAddress(address, prefix),
        getXDC: (address = "") => getXDCAddress(address, prefix),
        getFiat: (price = 0, decimal = 7) => getFiat(price, decimal, fiat),
        getTime: (date: any, format= defaultFormat, showRelative = true) => (showRelative && isRelativeTimeFormat) ? moment(date).fromNow() : (isUTCTimeZoneFormat ? moment.utc(date).format(format || defaultFormat) : moment(date).format(format || defaultFormat)),
    };
};

export default useCommon;
