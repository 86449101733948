export const en = {
  translations: {
    "ABI for the implementation contract at": "ABI for the implementation contract at",
    "ABI-encoded": "ABI-encoded",
    "API Documentation": "API Documentation",
    "API Plans": "API Plans",
    "API Support": "API Support",
    "About Us": "About Us",
    "Account": "Account",
    "Account Balance Checker": "Account Balance Checker",
    "Action": "Action",
    "Add Name Tag/Label": "Add Name Tag/Label",
    "Add one more": "Add one more",
    "Additional Info": "Additional Info",
    "Additional Notes": "Additional Notes",
    "Address": "Address",
    "Address of this NFT contract.": "Address of this NFT contract.",
    "Address that deployed this NFT contract.": "Address that deployed this NFT contract.",
    "Advertising": "Advertising",
    "Age": "Age",
    "All Rights Reserved": "All Rights Reserved",
    "All Transactions": "All Transactions",
    "Amount": "Amount",
    "Analytics": "Analytics",
    "Annual Trips": "Annual Trips",

    "Are you the contract creator": "Are you the contract creator",
    "Back to Top": "Back to Top",
    "Balance": "Balance",
    "Balance Checker": "Balance Checker",
    "Base": "Base",
    "Base Fee": "Base Fee",
    "Base Fee Per Gas": "Base Fee Per Gas",
    "Best Coffee in Town": "Best Coffee in Town",
    "Block": "Block",
    "Block Height": "Block Height",
    "Block Number": "Block Number",
    "Block Reward": "Block Reward",
    "Block header withdrawal root hash.": "Block header withdrawal root hash.",
    "Block nonce is a value used during mining to demonstrate proof of work for a block.": "Block nonce is a value used during mining to demonstrate proof of work for a block.",
    "Block proposed on epoch": "Block proposed on epoch",
    "Blocksscan is an independent": "Blocksscan is an independent",
    "Blockchain": "Blockchain",
    "Blocks": "Blocks",
    "Broadcast Transaction": "Broadcast Transaction",
    "Brand Assets": "Brand Assets",
    "Bridge": "Bridge",
    "Burnt": "Burnt",
    "Burnt & Txn Savings Fees": "Burnt & Txn Savings Fees",
    "Byte Code": "Byte Code",
    "Bytecode to Opcode": "Bytecode to Opcode",
    "By using Blocksscan Explorer Services, you consent to our Privacy Policy.": "By using Blocksscan Explorer Services, you consent to our Privacy Policy.",
    "CSV Export": "CSV Export",
    "Call": "Call",
    "Capacity": "Capacity",
    "Careers": "Careers",
    "Careers at BlocksScan": "Careers at BlocksScan",
    "Change Fiat Currency": "Change Fiat Currency",
    "Change": "Change",
    "Charts & stats": "Charts & stats",
    "Charts And Stats": "Charts And Stats",
    "Choose Priority Service": "Choose Priority Service",
    "Choose desired language": "Choose desired language",
    "Choose the theme": "Choose the theme",
    "Circulating Market Cap": "Circulating Market Cap",
    "Circulating Supply": "Circulating Supply",
    "Circulating Supply Market Cap": "Circulating Supply Market Cap",
    "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.": "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.",
    "Classification": "Classification",
    "Click here to": "Click here to",
    "Click to show less": "Click to show less",
    "Click to show more": "Click to show more",
    "Close": "Close",
    "Code": "Code",
    "Code Reader": "Code Reader",
    "CodeRunAI": "CodeRunAI",
    "Coin transfer": "Coin transfer",
    "Collater­alization": "Collater­alization",
    "Collection": "Collection",
    "Collection Name": "Collection Name",
    "Commute Allowance": "Commute Allowance",
    "Community": "Community",
    "Community Support": "Community Support",
    "Company": "Company",
    "Compiler": "Compiler",
    "Compiler Type": "Compiler Type",
    "Compiler Version": "Compiler Version",
    "COMPILER TYPE AND VERSION SELECTION": "Compiler Type and Version Selection",
    "ConfirmationToken": "Confirmation",
    "Confirmation": "Confirmation",
    "Confirmed within": "Confirmed within",
    "Confirming": "Confirming",
    "Contact Us": "Contact Us",
    "Contact Information": "Contact Information",
    "Contract": "Contract",
    "Contract Address": "Contract Address",
    "Contract Code": "Contract Code",
    "Contract Diff Checker": "Contract Diff Checker",
    "Contract File Path": "Contract File Path",
    "Contract Internal Transactions": "Contract Internal Transactions",
    "Contract Licence": "Contract Licence",
    "Contract Name": "Contract Name",
    "Contract Source Code Verified": "Contract Source Code Verified",
    "Contract Verification": "Contract Verification",
    "Contract call": "Contract call",
    "Contract creation": "Contract creation",
    "Contracts": "Contracts",
    "Contracts Internal": "Contracts Internal",
    "Cost per unit of gas spent for the transaction, in Ether and Gwei.": "Cost per unit of gas spent for the transaction, in Ether and Gwei.",
    "Creator": "Creator",
    "Created": "Created",
    "Current owner of this NFT.": "Current owner of this NFT.",
    "Custom Card": "Custom Card",
    "Customize": "Customize",
    "Customize this card by selecting one of the options below": "Customize this card by selecting one of the options below",
    "Daily Transactions": "Daily Transactions",
    "Dark": "Dark",
    "Dark Mode": "Dark Mode",
    "Data": "Data",
    "Data Security": "Data Security",
    "Date for the snapshot": "Date for the snapshot",
    "Decode input data": "Decode input data",
    "Details": "Details",
    "Development": "Development",
    "Dim": "Dim",
    "Directory": "Directory",
    "Display All Chains": "Display All Chains",
    "Displaying current Value; Click to show Value on Day of Txn": "Displaying current Value; Click to show Value on Day of Txn",
    "Donations": "Donations",
    "Drag & drop files here, or click to select files": "Drag & drop files here, or click to select files",
    "Download": "Download",
    "Downloaded": "Downloaded",
    "EVM Version": "EVM Version",
    "Encode": "Encode",
    "Encoded": "Encoded",
    "Encoding": "Encoding",
    "Enter Source Code...": "Enter Source Code...",
    "Enter name here...": "Enter name here...",
    "Ensure that token information is submitted. Check our token update guidelines": "Ensure that token information is submitted. Check our token update guidelines",
    "Event Logs": "Event Logs",
    "Events": "Events",
    "Ex. John Doe": "Ex. John Doe",
    "Example questions": "Example questions",
    "Explorer-as-a-Service": "Explorer-as-a-Service",
    "Export": "Export",
    "Extra Data": "Extra Data",
    "Extra lunch money provided along with the occasional team lunch and dinners.": "Extra lunch money provided along with the occasional team lunch and dinners.",
    "FAQ": "FAQ",
    "Failed": "Failed",
    "Fast": "Fast",
    "Fee": "Fee",
    "Fee Recipient": "Fee Recipient",
    "Feedback Form": "Feedback Form",
    "File": "File",
    "Files": "Files",
    "Filter": "Filter",
    "Filters": "Filters",
    "Filtered": "Filtered",
    "Finalized": "Finalized",
    "Flex Benefits": "Flex Benefits",
    "Flexible working hours": "Flexible working hours",
    "Food Allowance": "Food Allowance",
    "For": "For",
    "For almost anything that improves your life - training courses, fitness plans... even beauty facials!": "For almost anything that improves your life - training courses, fitness plans... even beauty facials!",
    "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.": "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.",
    "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.": "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.",
    "Forums": "Forums",
    "Free Flow Drinks & Snacks": "Free Flow Drinks &  Snacks",
    "From": "From",
    "Fully Diluted Market Cap": "Fully Diluted Market Cap",
    "Functionality": "Functionality",
    "Functions": "Functions",
    "Gas": "Gas",
    "Gas Fees": "Gas Fees",
    "Gas Limit": "Gas Limit",
    "Gas Limit & Usage by Txn": "Gas Limit & Usage by Txn",
    "Gas Price": "Gas Price",
    "Gas Target": "Gas Target",
    "Gas Tracker": "Gas Tracker",
    "Gas Used": "Gas Used",
    "Gas price history": "Gas price history",
    "Gas tracker": "Gas tracker",
    "Gas tracker overview": "Gas tracker overview",
    "General Inquiry": "General Inquiry",
    "Github": "Github",
    "Get Started": "Get Started",
    "Help & Support": "Help & Support",
    "Helpdesk Ticket Number": "Helpdesk Ticket Number",
    "Hex": "Hex",
    "Hide All Chains": "Hide All Chains",
    "Hide Chains": "Hide Chains",
    "Highlighted events of the transaction.": "Highlighted events of the transaction.",
    "Historical Balance at a specific Block No or Date.": "Historical Balance at a specific Block No or Date.",
    "Home": "Home",
    "How We Use Your Information": "How We Use Your Information",
    "How to Start": "How to Start",
    "I can help you in the following scenarios": "I can help you in the following scenarios",
    "I don't have an API Key": "I don't have an API Key",
    "I have an API Key": "I have an API Key",
    "ID": "ID",
    "If using the Solidity compiler, run solc —version to check.": "If using the Solidity compiler, run solc —version to check.",
    "If you have any additional notes, please leave them here": "If you have any additional notes, please leave them here",
    "Implementation": "Implementation",
    "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.": "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.",
    "Indexed": "Indexed",
    "Information We Collect": "Information We Collect",
    "Input Data": "Input Data",
    "Instagram": "Instagram",
    "Internal Transactions": "Internal Transactions",
    "Internal transaction is transactions as a result of contract execution that involves Ether value.": "Internal transaction is transactions as a result of contract execution that involves Ether value.",
    "Is Yul Contract": "Is Yul Contract",
    "Item": "Item",
    "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.": "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.",
    "Join BlocksScan and enhance the world's leading blockchain explorer and search, API & analytics platform.": "Join BlocksScan and enhance the world's leading blockchain explorer and search, API & analytics platform.",
    "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.": "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.",
    "Kindly follow the simple steps below to get your support prioritized.": "Kindly follow the simple steps below to get your support prioritized.",
    "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.": "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.",
    "Knowledge Base": "Knowledge Base",
    "Last Sale": "Last Sale",
    "Last Traded": "Last Traded",
    "Last Updated": "Last Updated",
    "Last updated": "Last updated",
    "Latest Block": "Latest Block",
    "Latest Blocks": "Latest Blocks",
    "Latest Mints": "Latest Mints",
    "Latest Signed Block": "Latest Signed Block",
    "Latest Transaction": "Latest Transaction",
    "Latest Transactions": "Latest Transactions",
    "Latest Trades": "Latest Trades",
    "Latest Transfers": "Latest Transfers",
    "Learn more about": "Learn more about",
    "Learning Sessions": "Learning Sessions",
    "Legacy": "Legacy",
    "License": "License",
    "Light": "Light",
    "List of": "List of",
    "List of {{_tokenType}}20 tokens transferred in the transaction.": "List of {{_tokenType}}20 tokens transferred in the transaction.",
    "List of tokens burnt transferred in the transaction.": "List of tokens burnt transferred in the transaction.",
    "List of tokens mint transferred in the transaction.": "List of tokens mint transferred in the transaction.",
    "Load": "Load",
    "Loading": "Loading",
    "Loading...": "Loading...",
    "Logdata to clipboard": "Logdata to clipboard",
    "Logs": "Logs",
    "Look up": "Look up",
    "Lookup": "Lookup",
    "Low load": "Low load",
    "MED GAS PRICE": "Med Gas Price",
    "Main Interfaces (.vy or .json)": "Main Interfaces (.vy or .json)",
    "Market": "Market",
    "Market Cap": "Market Cap",
    "Market Capitalization": "Market Capitalization",
    "Marketplaces": "Marketplaces",
    "Marketplaces trading this NFT.": "Marketplaces trading this NFT.",
    "Max": "Max",
    "Max Priority": "Max Priority",
    "Max Price": "Max Price",
    "Max Total Supply": "Max Total Supply",
    "Media & Press": "Media & Press",
    "Medical Benefits": "Medical Benefits",
    "Method ID": "Method ID",
    "Method Name": "Method Name",
    "Method": "Method",
    "Method id": "Method id",
    "Metadata": "Metadata",
    "Min Price": "Min Price",
    "Missing": "Missing",
    "Mode": "Mode",
    "More Details": "More Details",
    "More Info": "More Info",
    "Multichain Info": "Multichain Info",
    "Multichain Portfolio": "Multichain Portfolio",
    "Name": "Name",
    "Name Tag": "Name Tag",
    "NEVER": "Never",
    "NEVER send us these.": "NEVER send us these.",
    "NEW": "New",
    "NFT Address": "NFT Address",
    "NFT Latest Mints": "NFT Latest Mints",
    "NFT Top Mints": "NFT Top Mints",
    "NFT Transfer": "NFT Transfer",
    "NFT Transfers": "NFT Transfers",
    "NFT's unique token ID.": "NFT's unique token ID.",
    "NFT": "NFT",
    "Node": "Node",
    "Nonce": "Nonce",
    "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.": "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.",
    "Normal": "Normal",
    "Note: Filtered by  totalSupply() , method returned a zero value.": "Note: Filtered by  totalSupply() , method returned a zero value.",
    "Note": "Note",
    "NOT": "Not",
    "No Record Found": "No Record Found",
    "No transactions found.": "No transactions found.",
    "Number": "Number",
    "Number of beacon withdrawals in this block.": "Number of beacon withdrawals in this block.",
    "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.": "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.",
    "OAuth": "OAuth",
    "Office Entertainment": "Office Entertainment",
    "Onchain Market Cap": "Onchain Market Cap",
    "OpenAI API key": "OpenAI API key",
    "Optimization": "Optimization",
    "Optimization Enabled": "Optimization Enabled",
    "Optimization Runs": "Optimization Runs",
    "Order": "Order",
    "Others": "Others",
    "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.": "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.",
    "Our team will review your request and contact you using an official": "Our team will review your request and contact you using an official",
    "Overview": "Overview",
    "Owner": "Owner",
    "Owners": "Owners",
    "Page": "Page",
    "Parent Hash": "Parent Hash",
    "Pending Transaction": "Pending Transaction",
    "Pending Transactions": "Pending Transactions",
    "Pending...": "Pending...",
    "Percentage": "Percentage",
    "Perks and Benefits": "Perks and Benefits",
    "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.": "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.",
    "Phone": "Phone",
    "Platform": "Platform",
    "Please Select Your Message Subject": "Please Select Your Message Subject",
    "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that": "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that",
    "Please use this form to get in touch for priority support or featured listing with our team.": "Please use this form to get in touch for priority support or featured listing with our team.",
    "Position": "Position",
    "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.": "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.",
    "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.": "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.",
    "Power": "Power",
    "Powered by BlocksScan": `Powered by ${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"}`,
    "BlocksScan is a Block Explorer and Analytics Platform for": `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} is a Block Explorer and Analytics Platform for`,
    "Present": "Present",
    "Preserve": "Preserve",
    "Press": "Press",
    "Price": "Price",
    "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below": "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below",
    "Privacy": "Privacy",
    "Privacy Policy": "Privacy Policy",
    "Private Name Tags": "Private Name Tags",
    "Processing": "Processing",
    "Products": "Products",
    "Profile": "Profile",
    "Programs": "Programs",
    "Project": "Project",
    "Properties": "Properties",
    "Proposed On": "Proposed On",
    "Proxy": "Proxy",
    "Publish": "Publish",
    "Published": "Published",
    "Quantity": "Quantity",
    "Rank": "Rank",
    "Read": "Read",
    "Read Contract": "Read Contract",
    "Read Proxy": "Read Proxy",
    "Real-time": "Real-time",
    "Receipt": "Receipt",
    "Recent": "Recent",
    "Record": "Record",
    "Recover": "Recover",
    "Recovery": "Recovery",
    "Recycle": "Recycle",
    "Redo": "Redo",
    "Refresh": "Refresh",
    "Register": "Register",
    "Registration": "Registration",
    "Related": "Related",
    "Release": "Release",
    "Reload": "Reload",
    "Removal": "Removal",
    "Remove": "Remove",
    "Removed": "Removed",
    "Removing": "Removing",
    "Renew": "Renew",
    "Replace": "Replace",
    "Request Removal of Name Tag": "Request Removal of Name Tag",
    "Request": "Request",
    "Requester Email": "Requester Email",
    "Requester Name": "Requester Name",
    "Requests": "Requests",
    "Resend": "Resend",
    "Reset": "Reset",
    "Resources": "Resources",
    "Response": "Response",
    "Responses": "Responses",
    "Restore": "Restore",
    "Restrict": "Restrict",
    "Result": "Result",
    "Results": "Results",
    "Resume": "Resume",
    "Retain": "Retain",
    "Retrieve": "Retrieve",
    "Return": "Return",
    "Returned": "Returned",
    "Returning": "Returning",
    "Review": "Review",
    "Rewards": "Rewards",
    "Rewrite": "Rewrite",
    "Rich": "Rich",


    "Right": "Right",
    "Rights": "Rights",
    "Role": "Role",
    "Roles": "Roles",
    "Rollback": "Rollback",
    "Root": "Root",
    "Route": "Route",
    "Routing": "Routing",
    "Row": "Row",
    "Rules": "Rules",
    "Run": "Run",
    "Running": "Running",
    "Sales": "Sales",
    "Save Changes": "Save Changes",
    "Saved Filters": "Saved Filters",
    "Saving": "Saving",
    "Scenario": "Scenario",
    "Schemas": "Schemas",
    "Schedule": "Schedule",
    "Scheduled": "Scheduled",
    "Scheduling": "Scheduling",
    "Schema": "Schema",
    "Scope": "Scope",
    "Screen": "Screen",
    "Scripts": "Scripts",
    "Search": "Search",
    "Ask AI | Search by Address | Txn Hash | Block | Token... ": "Ask AI | Search by Address | Txn Hash | Block | Token... ",
    "Searches": "Searches",
    "Searching": "Searching",
    "Second": "Second",
    "Seconds": "Seconds",
    "Sec": "Sec",
    "Secret": "Secret",
    "Section": "Section",
    "Secure": "Secure",
    "Securing": "Securing",
    "Security": "Security",
    "Security Audit": "Security Audit",
    "See more details": "See more details",
    "See preview of the transaction details.": "See preview of the transaction details.",
    "Select": "Select",
    "Selected": "Selected",
    "Selection": "Selection",
    "Send": "Send",
    "Send Message": "Send Message",
    "Sender": "Sender",
    "Sending": "Sending",
    "Send native": "Send native",
    "Sequence": "Sequence",
    "Server": "Server",
    "Service": "Service",
    "Services": "Services",
    "Session": "Session",
    "Sessions": "Sessions",
    "Set": "Set",
    "Setting": "Setting",
    "Settings": "Settings",
    "Setup": "Setup",
    "Share": "Share",
    "Shared": "Shared",
    "Sharing": "Sharing",
    "Show": "Show",
    "Show More Chains": "Show More Chains",
    "Show less": "Show less",
    "Show more": "Show more",
    "Showing": "Showing",
    "Signer": "Signer",
    "Similar Contract Search": "Similar Contract Search",
    "Simple steps for getting Priority Support.": "Simple steps for getting Priority Support.",
    "Size": "Size",
    "Slow": "Slow",
    "Slot and epoch this block is proposed on.": "Slot and epoch this block is proposed on.",
    "Smart Contract": "Smart Contract",
    "Smart Contract Search": "Smart Contract Search",
    "Smart Contracts": "Smart Contracts",
    "Snapshot": "Snapshot",
    "Snapshots": "Snapshots",
    "Social": "Social",
    "Software": "Software",
    "Solidity": "Solidity",
    "Solution": "Solution",
    "Solutions": "Solutions",
    "Source Code": "Source Code",
    "Sources *.sol or *.yul files": "Sources *.sol or *.yul files",
    "Sources and Metadata JSON": "Sources and Metadata JSON",
    "Span": "Span",
    "Special": "Special",
    "Specific": "Specific",
    "Specification": "Specification",
    "Specifications": "Specifications",
    "Specified": "Specified",
    "Specify": "Specify",
    "Specs": "Specs",
    "Sponsor": "Sponsor",
    "Sponsored": "Sponsored",
    "Sponsoring": "Sponsoring",
    "Sponsors": "Sponsors",
    "Spotlight": "Spotlight",
    "Stable": "Stable",
    "Staging": "Staging",
    "Standard JSON Input": "Standard JSON Input",
    "Start": "Start",
    "Starting": "Starting",
    "State": "State",
    "State Diffrence": "State Difference",
    "State Root": "State Root",
    "StateRoot": "State Root",
    "State of the art coffee machine and experienced baristas in the house for the coffee lovers to thrive.": "State of the art coffee machine and experienced baristas in the house for the coffee lovers to thrive.",
    "Statistics": "Statistics",
    "Status": "Status",
    "Step 1": "Step 1",
    "Step 2": "Step 2",
    "Step 3": "Step 3",
    "Steps": "Steps",
    "Storage Of Assets": "Storage Of Assets",
    "Store": "Store",
    "Stored": "Stored",
    "Storing": "Storing",
    "Stream": "Stream",
    "Streaming": "Streaming",
    "Streams": "Streams",
    "Strength": "Strength",
    "String": "String",
    "Strings": "Strings",
    "Structure": "Structure",
    "Structures": "Structures",
    "Style": "Style",
    "Styles": "Styles",
    "Submit": "Submit",
    "Submitted": "Submitted",
    "Submitting": "Submitting",
    "Subscription": "Subscription",
    "Subscriptions": "Subscriptions",
    "Subsequent": "Subsequent",
    "Success": "Success",
    "Successful": "Successful",
    "Successfully": "Successfully",
    "Suggest Transaction Action": "Suggest Transaction Action",
    "Suggestions": "Suggestions",
    "Summary": "Summary",
    "Support": "Support",
    "Supported": "Supported",
    "Supporting": "Supporting",
    "Supports": "Supports",
    "Supply": "Supply",
    "Surface": "Surface",
    "Survey": "Survey",
    "Surveys": "Surveys",
    "Suspend": "Suspend",
    "Suspended": "Suspended",
    "Suspending": "Suspending",
    "Swap": "Swap",
    "Swapping": "Swapping",
    "Switch": "Switch",
    "Switching": "Switching",
    "Symbol": "Symbol",
    "Symbols": "Symbols",
    "Sync": "Sync",
    "Synchronize": "Synchronize",
    "Synchronized": "Synchronized",
    "Synchronizing": "Synchronizing",
    "System": "System",
    "Systems": "Systems",
    "TV": "TV",
    "Table": "Table",
    "Tables": "Tables",
    "Tag": "Tag",
    "Tags": "Tags",
    "Take": "Take",
    "Taken": "Taken",
    "Taking": "Taking",
    "Target": "Target",
    "Targets": "Targets",
    "Task": "Task",
    "Tasks": "Tasks",
    "Team": "Team",
    "Teams": "Teams",
    "Technical": "Technical",
    "Technique": "Technique",
    "Techniques": "Techniques",
    "Technology": "Technology",
    "Template": "Template",
    "Templates": "Templates",
    "Temporary": "Temporary",
    "Terminate": "Terminate",
    "Terminated": "Terminated",
    "Terminating": "Terminating",
    "Terms": "Terms",
    "Test": "Test",
    "Tested": "Tested",
    "Testing": "Testing",
    "Tests": "Tests",
    "Text": "Text",
    "Theme": "Theme",
    "Themes": "Themes",
    "Therapy": "Therapy",
    "This block is finalized.": "This block is finalized.",
    "This block is finilized.": "This block is finalized.",
    "This page allows you to customize your browsing experience on Blocksscan.": "This page allows you to customize your browsing experience on Blocksscan.",
    "This NFT's unique token ID.": "This NFT's unique token ID.",
    "Thread": "Thread",
    "Threads": "Threads",
    "Threshold": "Threshold",
    "Thresholds": "Thresholds",
    "Ticket": "Ticket",
    "Tickets": "Tickets",
    "Time": "Time",
    "Timestamp": "Timestamp",
    "Title": "Title",
    "Titles": "Titles",
    "To": "To",
    "Token Contract": "Token Contract",
    "Token Contract Address": "Token Contract Address",
    "Token Creation": "Token Creation",
    "Token Holding": "Token Holding",
    "Token ID": "Token ID",
    "Token Inventory": "Token Inventory",
    "Token Name": "Token Name",
    "Token Standard": "Token Standard",
    "Token Transactions": "Token Transactions",
    "Token Transfers": "Token Transfers",
    "Token Update": "Token Update",
    "Token creation": "Token creation",
    "Token transfer": "Token transfer",
    "Tokens": "Tokens",
    "Tokens Transferred": "Tokens Transferred",
    "Tokens burnt": "Tokens burnt",
    "Tokens minted": "Tokens minted",
    "Tokens transferred in the transaction.": "Tokens transferred in the transaction.",
    "ToDo": "ToDo",
    "To clipboard": "To clipboard",
    "Toggle": "Toggle",
    "Toggled": "Toggled",
    "Toggling": "Toggling",
    "Tool": "Tool",
    "Tools": "Tools",
    "Top Accounts": "Top Accounts",
    "Top Contract": "Top Contract",
    "Top Contracts": "Top Contracts",
    "Top Mints": "Top Mints",
    "Top NFT": "Top NFT",
    "Top NFTs": "Top NFTs",
    "Top Statistics": "Top Statistics",
    "Top Token": "Top Token",
    "Top Tokens": "Top Tokens",
    "Topic": "Topic",
    "Topics": "Topics",
    "Total": "Total",
    "Total Accounts": "Total Accounts",
    "Total Assets": "Total Assets",
    "Total Difficulty": "Total difficulty of the chain until this block.",
    "Total Holders": "Total Holders",
    "Total Transfers": "Total Transfers",
    "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.": "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.",
    "Total gas limit provided by all transactions in the block.": "Total gas limit provided by all transactions in the block.",
    "Total gas used in the block and its percentage of gas filled in the block.": "Total gas used in the block and its percentage of gas filled in the block.",
    "Total transactions": "Total transactions",
    "Trace": "Trace",
    "Traces": "Traces",
    "Tracking": "Tracking",
    "Trade": "Trade",
    "Trades": "Trades",
    "Trading": "Trading",
    "Transaction": "Transaction",
    "Transaction Action": "Transaction Action",
    "Transaction Details": "Transaction Details",
    "Transaction Fee": "Transaction Fee",
    "Transaction Hash": "Transaction Hash",
    "Transaction Type": "Transaction Type",
    "Transactions": "Transactions",
    "Transactions Internal": "Transactions Internal",
    "Transcript": "Transcript",
    "Transcripts": "Transcripts",
    "Transfer": "Transfer",
    "Transferred": "Transferred",
    "Transferring": "Transferring",
    "Transfers": "Transfers",
    "Translate": "Translate",
    "Translated": "Translated",
    "Translating": "Translating",
    "Translation": "Translation",
    "Translations": "Translations",
    "Trending": "Trending",
    "Trends": "Trends",
    "Trigger": "Trigger",
    "Triggered": "Triggered",
    "Triggering": "Triggering",
    "Triggers": "Triggers",
    "Troubleshoot": "Troubleshoot",
    "Troubleshooting": "Troubleshooting",
    "Trust": "Trust",
    "Trusted": "Trusted",
    "Trusting": "Trusting",
    "Try": "Try",
    "Trying": "Trying",
    "Tx Fee": "Tx Fee",
    "Tx Hash": "Tx Hash",
    "Tx Hash|Block|Age": "Tx Hash|Block|Age",
    "Tx row trace to clipboard": "Tx row trace to clipboard",
    "Tx": "Tx",
    "Txn": "Txn",
    "Txn Savings": "Txn Savings",
    "Txn Type": "Txn Type",
    "Txns": "Txns",
    "Type": "Type",
    "Type here ...": "Type here ...",
    "Type of Backing": "Type of Backing",
    "Type of Peg": "Type of Peg",
    "Types": "Types",
    "UI": "UI",
    "URL": "URL",
    "URLs": "URLs",
    "Undo": "Undo",
    "Undone": "Undone",
    "Unfinished": "Unfinished",
    "Unit": "Unit",
    "Units": "Units",
    "Universe": "Universe",
    "Unknown": "Unknown",
    "Unlink": "Unlink",
    "Unlinked": "Unlinked",
    "Unlinking": "Unlinking",
    "Unlock": "Unlock",
    "Unlocked": "Unlocked",
    "Unlocking": "Unlocking",
    "Unread": "Unread",
    "Unresolved": "Unresolved",
    "Unseen": "Unseen",
    "Unsubscribe": "Unsubscribe",
    "Unsubscribed": "Unsubscribed",
    "Unsubscribing": "Unsubscribing",
    "Untagged": "Untagged",
    "Untagging": "Untagging",
    "Update": "Update",
    "Update Proxy Contract's Implementation Address": "Update Proxy Contract's Implementation Address",
    "Update Token Info": "Update Token Info",
    "Updated": "Updated",
    "Updating": "Updating",
    "Upgrade": "Upgrade",
    "Upgraded": "Upgraded",
    "Upgrading": "Upgrading",
    "Upload": "Upload",
    "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.": "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.",
    "Upload all Solidity or Yul contract source files": "Upload all Solidity or Yul contract source files",
    "Upload the standard input JSON file created during contract compilation": "Upload the standard input JSON file created during contract compilation",
    "Uploaded": "Uploaded",
    "Uploading": "Uploading",
    "Uptime": "Uptime",
    "Us": "Us",
    "Usage": "Usage",
    "Use": "Use",
    "Used": "Used",
    "User": "User",
    "Users": "Users",
    "Using": "Using",
    "Utilities": "Utilities",
    "Utility": "Utility",
    "Validate": "Validate",
    "Validated": "Validated",
    "Validating": "Validating",
    "Validation": "Validation",
    "Value": "Value",
    "Values": "Values",
    "Variable": "Variable",
    "Variables": "Variables",
    "Variant": "Variant",
    "Variants": "Variants",
    "Varying": "Varying",
    "Vault": "Vault",
    "Vaults": "Vaults",
    "Vector": "Vector",
    "Vectors": "Vectors",
    "Verification": "Verification",
    "Verification Method": "Verification Method",
    "Verified": "Verified",
    "Verified At": "Verified At",
    "Verified Contracts": "Verified Contracts",
    "Verified Smart Contract": "Verified Smart Contract",
    "Verifying": "Verifying",
    "Version": "Version",
    "Versions": "Versions",
    "Verify & Publish": "Verify & Publish",
    "Verify And Publish": "Verify And Publish",
    "Verify Contract": "Verify Contract",
    "View": "View",
    "View Blocks": "View Blocks",
    "Views": "Views",
    "Viewing": "Viewing",
    "Visible": "Visible",
    "Visit": "Visit",
    "Visiting": "Visiting",
    "Visitor": "Visitor",
    "Visitors": "Visitors",
    "Visual": "Visual",
    "Visuals": "Visuals",
    "Volume": "Volume",
    "Volume 24h": "Volume 24h",
    "Volumes": "Volumes",
    "Vote": "Vote",
    "Voted": "Voted",
    "Voter": "Voter",
    "Voters": "Voters",
    "Voting": "Voting",
    "Votes": "Votes",
    "Vyper Online Compiler": "Vyper Online Compiler",
    "WAITING": "WAITING",
    "Wallet": "Wallet",
    "Wallet / Exchange / Project related issues": "Wallet / Exchange / Project related issues",
    "Wallets": "Wallets",
    "Warning": "Warning",
    "Warnings": "Warnings",
    "Watch": "Watch",
    "Watching": "Watching",
    "Web": "Web",
    "Webpage": "Webpage",
    "Webpages": "Webpages",
    "Website": "Website",
    "Websites": "Websites",
    "Welcome": "Welcome",
    "Welcome to Blocksscan Explorer Services": "Welcome to Blocksscan Explorer Services",
    "Wellness": "Wellness",
    "We're Hiring": "We're Hiring",
    "What are the functions or methods available in this contract?": "What are the functions or methods available in this contract?",
    "What does this contract do?": "What does this contract do?",
    "What is the logical structure of this contract's code?": "What is the logical structure of this contract's code?",
    "Where to find my API Key?": "Where to find my API Key?",
    "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not": "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not",
    "White": "White",
    "Who": "Who",
    "Whole": "Whole",
    "Wholesale": "Wholesale",
    "Widget": "Widget",
    "Widgets": "Widgets",
    "Width": "Width",
    "Widths": "Widths",
    "Wiki": "Wiki",
    "Wikis": "Wikis",
    "Willing": "Willing",
    "Win": "Win",
    "Window": "Window",
    "Windows": "Windows",
    "Winning": "Winning",
    "Wins": "Wins",
    "Wire": "Wire",
    "Wires": "Wires",
    "Wiring": "Wiring",
    "Wisdom": "Wisdom",
    "Wish": "Wish",
    "Wished": "Wished",
    "Wishes": "Wishes",
    "Wishing": "Wishing",
    "Withdraw": "Withdraw",
    "Withdrawal": "Withdrawal",
    "Withdrawals": "Withdrawals",
    "WithdrawalsRoot": "Withdrawals Root",
    "Withdrawing": "Withdrawing",
    "Withdrawn": "Withdrawn",
    "With": "With",
    "Within": "Within",
    "Without": "Without",
    "Witness": "Witness",
    "Witnessed": "Witnessed",
    "Witnesses": "Witnesses",
    "Witnessing": "Witnessing",
    "Work": "Work",
    "Worked": "Worked",
    "Working": "Working",
    "Works": "Works",
    "Workspace": "Workspace",
    "Workspaces": "Workspaces",
    "World": "World",
    "Worldwide": "Worldwide",
    "Worry": "Worry",
    "Worried": "Worried",
    "Worries": "Worries",
    "Worrying": "Worrying",
    "Worth": "Worth",
    "Write": "Write",
    "Write Contract": "Write Contract",
    "Write Proxy": "Write Proxy",
    "Write me a smart contract of voting.": "Write me a smart contract of voting.",
    "Writes": "Writes",
    "Writing": "Writing",
    "Written": "Written",
    "Wrong": "Wrong",
    "Wrote": "Wrote",
    "X (Twitter)": "X (Twitter)",
    "XML": "XML",
    "XRay": "XRay",
    "Xrays": "Xrays",
    "Year": "Year",
    "Years": "Years",
    "Yellow": "Yellow",
    "Yes": "Yes",
    "Yesterday": "Yesterday",
    "Yield": "Yield",
    "Yielding": "Yielding",
    "Yields": "Yields",
    "You can Lookup the Account": "You can Lookup the Account",
    "Your browser does not support the video tag.": "Your browser does not support the video tag.",
    "Your private keys with anyone.": "Your private keys with anyone.",
    "Your Rights": "Your Rights",
    "Youth": "Youth",
    "Zone": "Zone",
    "Zones": "Zones",
    "Zoning": "Zoning",
    "Zoom": "Zoom",
    "Zoomed": "Zoomed",
    "Zooming": "Zooming",
    "Zooms": "Zooms",
    "rsync-builder in 12 secs": "rsync-builder in 12 secs",
    "rsyncbuilder": "rsync-builder",
    "sec": "Sec",
    "tokens with a value of": "Tokens with a value of",
    "to clipboard": "To clipboard",
    "tokens transferred in the transaction.": "Tokens transferred in the transaction.",
    "withdrawals in this block": "Withdrawals in this block",
    "we": "We",
    "us": "Us",
    "our": "Our"
  },
};
