export const jp = {
  translations: {
    welcome: "日本語",
    Settings: "設定",
    "This page allows you to customize your browsing experience on Blocksscan.":
      "このページでは、ブラウジング エクスペリエンスをカスタマイズできます。ブロックスキャン",
    Address: "住所",
    Overview: "概要",
    Balance: "バランス",
    Value: "価値",
    "More Info": "より詳しい情報",
    "Private Name Tags": "プライベートネームタグ",
    Add: "追加",
    "LAST TXN SENT": "最後のTXN送信",
    from: "から",
    "FIRST TXN SENT": "最初のTXN送信",
    "Multichain Info": "マルチチェーン情報",
    day: "日",
    days: "日間",
    hour: "時間",
    hours: "時間",
    minute: "分",
    minutes: "分",
    second: "秒",
    seconds: "秒",
    ago: "前",
    "Multichain Portfolio": "マルチチェーンポートフォリオ",
    Transactions: "トランザクション",
    "Internal Transactions": "内部トランザクション",
    Transfers: "転送",
    "NFT Transfers": "NFT転送",
    "Token Holding": "トークン保有",
    "Token Inventory": "トークンインベントリ",
    Events: "イベント",
    Contract: "契約",
    Rewards: "報酬",
    Analytics: "分析",
    Download: "ダウンロード",
    "CSV Export": "CSVエクスポート",
    Tokens: "トークン",
    Chains: "チェーン",
    "Hide Chains": "チェーンを隠す",
    "Show More Chains": "さらにチェーンを表示",
    "Hide All Chains": "すべてのチェーンを非表示にする",
    "Display All Chains": "すべてのチェーンを表示",
    "Name Tag": "名札",
    Percentage: "パーセンテージ",
    Capacity: "容量",
    Owner: "所有者",
    "Latest Signed Block": "最新の署名付きブロック",
    Status: "状態",
    "Contract Name": "契約名",
    Compiler: "コンパイラ",
    Version: "バージョン",
    Txns: "テクニクス",
    Verified: "確認済み",
    Audit: "監査",
    License: "ライセンス",
    "New Smart Contracts": "新しいスマートコントラクト",
    "New Verified Contracts": "新しい確認済みの契約",
    "Smart Contracts": "スマートコントラクト",
    "Verified Smart Contract": "検証済みのスマートコントラクト",
    Optimization: "最適化",
    "Constructor args": "コンストラクターの引数",
    "Verified Contracts": "検証済みの契約",
    Solidity: "堅牢性",
    Accounts: "アカウント",
    "Block Height": "ブロックの高さ",
    ToDo: "ToDo",
    Finalized: "確定済み",
    Timestamp: "タイムスタンプ",
    "Proposed On": "提案日",
    "Block proposed on epoch": "エポックで提案されたブロック",
    Withdrawals: "出金",
    "withdrawals in this block": "このブロックでの出金",
    Fee: "手数料",
    "Fee Recipient": "料金受取人",
    "rsync-builder in 12 secs": "12秒でrsync-ビルダー",
    "Block Reward": "ブロック報酬",
    "Total Difficulty": "総合難易度",
    Size: "サイズ",
    bytes: "バイト",
    "Gas Used": "使用ガス",
    "Gas Target": "ガスターゲット",
    "Gas Limit": "ガスリミット",
    "Base Fee Per Gas": "ガスごとの基本料金",
    "Burnt Fees": "焼損手数料",
    "Extra Data": "追加データ",
    rsyncbuilder: "rsyncビルダー",
    Hash: "ハッシュ",
    "Parent Hash": "親ハッシュ",
    StateRoot: "ステートルート",
    WithdrawalsRoot: "引き出しルート",
    Nonce: "ノンス",
    "More Details": "さらに詳しく",
    "Click to show less": "クリックして表示を少なくする",
    "Click to show more": "クリックしてさらに表示",
    "Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block.":
      "ブロック番号とも呼ばれます。ブロックチェーンの長さを示すブロックの高さは、新しいブロックの追加後に増加します。",
    "The finality status of the block.": "ブロックのファイナリティステータス。",
    "This block is finilized.": "このブロックは完成しました。",
    "The date and time at which a block is produced.":
      "ブロックが生成された日時。",
    "Slot and epoch this block is proposed on.":
      "このブロックが提案されているスロットとエポック。",
    "The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves Ether value.":
      "ブロック内のトランザクションの数。内部トランザクションとは、イーサ価値を伴う契約の実行結果としてのトランザクションです。",
    "Number of beacon withdrawals in this block.":
      "このブロック内のビーコン引き出しの数。",
    "Address receiving fees from transactions in this block.":
      "このブロックのトランザクションからの受信手数料に対処します。",
    "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.":
      "ブロックごとに、ブロックプロデューサーには、ブロック内のすべてのトランザクションに対して支払われた手数料に加えて、有限量のイーサが報酬として与えられます。",
    "Total difficulty of the chain until this block.":
      "このブロックまでのチェーンの合計難易度。",
    "The block size is actually determined by the block's gas limit.":
      "ブロック サイズは、実際にはブロックのガス制限によって決まります。",
    "The total gas used in the block and its percentage of gas filled in the block.":
      "ブロック内で使用される総ガスと、ブロック内に充填されているガスの割合。",
    "Total gas limit provided by all transactions in the block.":
      "ブロック内のすべてのトランザクションによって提供される合計ガス制限。",
    "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.":
      "ロンドン アップグレード後、これは、ブロックに含まれる TX に必要な最小ガス使用乗数を表します。",
    "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.":
      "ロンドンのアップグレード後、これは消費される TX 料金の一部を表します: ガスあたりの基本料金 * 使用したガス。",
    "Any data that can be included by the block producer in the block.":
      "ブロックプロデューサーがブロックに含めることができる任意のデータ。",
    "The hash of the block header of the current block.":
      "現在のブロックのブロックヘッダーのハッシュ。",
    "The hash of the block from which this block was generated, also known as its parent block.":
      "このブロックの生成元となったブロックのハッシュ。親ブロックとも呼ばれます。",
    "The root of the state trie.": "ステートトライのルート。",
    "Block header withdrawal root hash.":
      "ブロックヘッダーの撤回ルートハッシュ。",
    "Block nonce is a value used during mining to demonstrate proof of work for a block.":
      "ブロック ノンスは、マイニング中にブロックの作業証明を示すために使用される値です。",
    Block: "ブロック",
    Blocks: "ブロック",
    Age: "年",
    Node: "ノード",
    "Base Fee": "基本料金",
    "See preview of the transaction details.":
      "トランザクションの詳細のプレビューを参照してください。",
    "Additional Info": "追加情報",
    confirmation: "確認",
    "Transaction Action": "トランザクションアクション",
    Transfer: "移行",
    To: "に",
    "Transaction Fee": "取引手数料",
    "Gas Info": "ガス情報",
    "See more details": "詳細を見る",
    "Tx Hash": "送信ハッシュ",
    Type: "タイプ",
    From: "から",
    "Tx Fee": "送信料金",
    "in the position": "位置にある",
    "A TxHash or transaction hash is a unique 66-character identifier that is generated whenever a transaction is executed.":
      "TxHash またはトランザクション ハッシュは、トランザクションが実行されるたびに生成される一意の 66 文字の識別子です。",
    "Transaction Hash": "トランザクションハッシュ",
    "TxHash to clipboard": "ハッシュをクリップボードに送信",
    "The status of the transaction.": "トランザクションのステータス。",
    "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.":
      "トランザクションが記録されるブロックの番号。ブロック確認は、トランザクションが生成されてから追加されたブロックの数を示します。",
    Confirmation: "確認",
    "Pending...": "保留中...",
    "The date and time at which a transaction is produced.":
      "トランザクションが生成された日時。",
    "Confirmed within": "以内に確認済み",
    sec: "秒",
    "Highlighted events of the transaction.":
      "トランザクションの強調表示されたイベント。",
    "The sending party of the transaction.": "トランザクションの送信側。",
    "The receiving party of the transaction (could be a contract address).":
      "トランザクションの受信側 (契約アドレスの場合もあります)。",
    created: "作成した",
    "List of {{_tokenType}}20 tokens transferred in the transaction.":
      "トランザクションで転送された {{_tokenType}}20 個のトークンのリスト。",
    "Tokens Transferred": "転送されたトークン",
    For: "にとって",
    " tokens transferred in the transaction.":
      "トランザクションで転送されたトークン。",
    "List of": "のリスト",
    "Token ID": "トークンID",
    "List of tokens mint transferred in the transaction.":
      "トランザクションで転送されたトークンのリスト。",
    "Tokens minted": "鋳造されたトークン",
    "List of tokens burnt transferred in the transaction.":
      "トランザクションで転送された、燃焼されたトークンのリスト。",
    "Tokens burnt": "焼かれたトークン",
    "The value being transacted in Ether and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction.":
      "イーサおよび法定通貨で取引される価値。注: 法定通貨価値 (利用可能な場合) をクリックすると、取引時の過去の価値を確認できます。",
    "Amount paid to process the transaction in Ether and fiat value.":
      "取引を処理するためにイーサおよび法定通貨で支払われた金額。",
    "Actual Tx Cost/Fee": "実際の送信コスト/料金",
    "Cost per unit of gas spent for the transaction, in Ether and Gwei.":
      "取引に使われたガス1単位あたりのコスト。単位はイーサとグワイ。",
    "Gas Price": "ガス価格",
    "Maximum amount of gas allocated for the transaction & the amount eventually used. Normal ETH transfers involve 21,000 gas units while contracts involve higher values.":
      "トランザクションに割り当てられたガスの最大量と最終的に使用された量。通常の ETH 送金には 21,000 ガスユニットが含まれますが、契約にはより高い価値が含まれます。",
    "Gas Limit & Usage by Txn": "トランザクションごとのガス制限と使用量",
    "Base Fee refers to the network Base Fee at the time of the block, while Max Fee & Max Priority Fee refer to the max amount a user is willing to pay for their tx & to give to the block producer respectively.":
      "基本料金はブロック時のネットワーク基本料金を指しますが、最大料金と最大優先料金はそれぞれユーザーがトランザクションに対して支払ってもよい最大金額とブロック作成者に渡す最大金額を指します。",
    "Gas Fees": "ガス料金",
    Max: "最大",
    "Max Priority": "最大優先度",
    Base: "ベース",
    "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.":
      "このトランザクションで消費されたETHの合計額と、ユーザーがこのトランザクションのために支払うことを望んでいた金額から節約された手数料の合計額。",
    "Burnt & Txn Savings Fees": "バーン＆トランザクションセービング手数料",
    Burnt: "焼けた",
    "Txn Savings": "トランザクションの節約",
    "Other data related to this transaction.":
      "この取引に関連するその他のデータ。",
    "Other Attributes": "その他の属性",
    "Txn Type": "取引タイプ",
    Position: "位置",
    Legacy: "遺産",
    "Input Data": "入力データ",
    Hex: "ヘックス",
    "to clipboard": "クリップボードに",
    "Decode input data": "入力データをデコードする",
    "Method id": "メソッドID",
    Call: "コール",
    Name: "名称",
    Data: "データ",
    "Logdata to clipboard": "ログデータをクリップボードへ",
    "Address to clipboard": "クリップボードへのアドレス",
    Indexed: "インデックス付き",
    Topics: "トピック",
    Text: "テキスト",
    Number: "番号",
    TO: "へ",
    number: "番号",
    "Tx row trace to clipboard":
      "トランザクション行のトレースをクリップボードへ",
    "No trace entries found.": "トレースエントリーは見つからなかった。",
    Before: "前",
    After: "その後",
    "State Diffrence": "州の違い",
    Token: "トークン",
    "Transaction Details": "取引内容",
    "Token transfers": "トークン・トランスファー",
    Logs: "過去ログ",
    state: "状態",
    "Raw trace": "生のトレース",
    Method: "方法",
    "Careers at BlocksScan": "BlocksScan でのキャリア",
    "Join BlocksScan and enhance the world's leading blockchain explorer and search, API &amp; analytics platform.":
      "BlocksScanに参加し、世界をリードするブロックチェーンエクスプローラー、検索、API、分析プラットフォームを強化しましょう。",
    "Perks and Benefits": "特典とメリット",
    "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.":
      "私たちのビジョンは単なる壮大なビジョンではありません。それを実現するための企業と文化でバックアップします。",
    "Free Flow Drinks &amp; Snacks": "フリーフロードリンク＆ドリンクおやつ",
    "Never worry about a lack of refreshments or tasty morsels to munch on while adding value to the team.":
      "チームに価値をもたらしながら、軽食やおいしい食べ物が不足することを心配する必要はありません。",
    "Best Coffee in Town": "町で最高のコーヒー",
    "State-of-the-art coffee machine and experienced baristas in the house for the coffee lovers to thrive.":
      "最先端のコーヒーマシンと経験豊富なバリスタがコーヒー愛好家をサポートします。",
    "Annual Trips": "年間旅行",
    "Enjoy a break from the daily grind with sponsored trips to cool new locations and experiences.":
      "スポンサー付きのクールな新しい場所や体験への旅行で、日々の忙しさから離れて休暇をお楽しみください。",
    "Office Entertainment": "オフィスエンターテインメント",
    "Games and gadgets to distract yourself with while thinking up novel solutions to hard problems.":
      "難しい問題に対する斬新な解決策を考えながら気を紛らわせるゲームやガジェット。",
    "Food Allowance": "食費手当",
    "Extra lunch money provided along with the occasional team lunch and dinners.":
      "臨時のチームランチとディナーに加えて、追加のランチマネーが提供されます。",
    "Commute Allowance": "通勤手当",
    "Concerns with transportation and parking eased so that team members can focus on delivering value.":
      "交通手段や駐車場に関する懸念が軽減され、チームメンバーは価値の提供に集中できるようになりました。",
    "Flex Benefits": "フレックス特典",
    "For almost anything that improves your life - training courses, fitness plans... even beauty facials!":
      "トレーニング コース、フィットネス プラン、さらにはビューティー フェイシャルなど、あなたの生活を向上させるほぼすべてのことに対応しています。",
    "Medical Benefits": "医療給付金",
    "Coverage of outpatient, dental and optical services from a registered clinic of your choice.":
      "選択した登録クリニックの外来、歯科、光学サービスをカバーします。",
    "Flexible working hours": "フレキシブルな労働時間",
    "Contribute to the team effort without worrying about what time to clock in.":
      "打刻時間を気にすることなく、チームの努力に貢献します。",
    "Learning Sessions": "学習セッション",
    "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.":
      "知識と経験豊富なチームメイトとともに、業界の最新の進歩とベスト プラクティスを常に把握してください。",
    "Working at BlocksScan": "BlocksScan での作業",
    "BlocksScan is a team pioneering at the forefront of the blockchain industry. Our platform provides the infrastructure for users and developers to access and build decentralized applications powering billions of dollars in value.":
      "BlocksScan は、ブロックチェーン業界の最前線で先駆者となるチームです。当社のプラットフォームは、ユーザーと開発者が数十億ドルの価値を生み出す分散型アプリケーションにアクセスして構築するためのインフラストラクチャを提供します。",
    "We are made up of diverse backgrounds and skillsets, with a combined passion for providing equitable access to open financial systems. While we strive for excellence in all that we do, we don't take ourselves too seriously either. We love to explore new frontiers and have fun in doing so.":
      "私たちは多様な背景とスキルセットで構成されており、オープンな金融システムへの公平なアクセスを提供することに共通の情熱を持っています。私たちは、行うすべてのことにおいて卓越性を追求する一方で、自分自身をあまり真剣に考えていません。私たちは新しいフロンティアを探索するのが大好きで、それを楽しんでいます。",
    "Understand Contract Functionalities":
      "契約機能の理解契約の仕組みを理解する",
    "What are the functions or methods available in this contract?":
      "この契約ではどのような機能や方法が利用できますか？",
    "Design a WithdrawBalance() method to withdraw contract balance.":
      "契約残高を引き出すための WithdrawBalance() メソッドを設計します。",
    "What is the logical structure of this contract's code?":
      "この契約のコードの論理構造は何ですか?",
    "Is this a proxy contract?": "これは代理人契約ですか？",
    "Write me a smart contract of voting.":
      "投票のスマートな契約書を書いてくれ。",
    "What does this contract do?": "この契約は何をするのでしょうか?",
    "How does SafeMath change the contract's state?":
      "SafeMath はどのように契約の状態を変更するのですか？",
    "Code Reader": "コードリーダー",
    NEW: "新しい",
    "Understand and use smart contracts better with CodeRunAI.":
      "CodeRunAI を使用したスマート コントラクトをよりよく理解し、使用できるようになります。",
    Mode: "モード",
    "I have an API Key": "APIキーを持っています",
    "Any open source contract": "あらゆるオープンソース契約",
    "Any question": "ご質問は何でも",
    "I don't have an API Key": "API キーを持っていません",
    "Popular open source contracts": "人気のオープンソース契約",
    "Example questions": "質問例",
    DEMO: "デモ",
    "OpenAI API key": "OpenAI APIキー",
    "Where to find my API Key?": "API キーはどこで見つけられますか?",
    "Save it to the browser": "ブラウザに保存する",
    "GPT Model": "GPTモデル",
    "Smart Contract": "スマート契約",
    "Contract Address": "契約住所",
    "Contract Code": "契約コード",
    Load: "負荷",
    Trending: "トレンド",
    "Contract Files": "契約ファイル",
    "Source Code": "ソースコード",
    "Popular Contracts": "人気のある契約",
    "No Record Found": "レコードが見つかりません",
    "Loading...": "読み込み中...",
    "Hi, there.": "こんにちは。",
    "I can help you in the following scenarios":
      "以下のような場面でお手伝いさせていただきます",
    ME: "自分",
    "Enter any question you are interested in":
      "興味のある質問を入力してください",
    Signer: "署名者",
    "Send native": "ネイティブを送る",
    "Add one more": "もう 1 つ追加",
    Read: "読む",
    Write: "書く",
    Loading: "ローディング",
    "Method Name": "メソッド名",
    Permalink: "パーマリンク",
    Code: "コード",
    "Read Contract": "契約を読む",
    "Read Proxy": "プロキシを読む",
    "Write Contract": "契約書作成",
    "Write Proxy": "書き込みプロキシ",
    "Contract Source Code Verified": "契約ソースコードの検証済み",
    "Are you the contract creator": "あなたは契約作成者ですか",
    "Verify And Publish": "検証して公開する",
    "your contract source code today": "今日の契約ソースコード",
    "EVM Version": "EVMバージョン",
    "Optimization Runs": "最適化の実行",
    "Contract File Path": "契約ファイルのパス",
    "Compiler Version": "コンパイラのバージョン",
    "Optimization Enabled": "最適化が有効です",
    "Verified At": "にて確認済み。",
    "Constructor Arguments": "コンストラクターの引数",
    "ABI-encoded": "ABI エンコード",
    "Byte Code": "バイトコード",
    File: "ファイル",
    of: "の",
    "ABI for the implementation contract at": "での実施契約のためのABI",
    "Type of Backing": "バッキングの種類",
    "Type of Peg": "ペグの種類",
    "Collater­alization": "担保化",
    "Storage Of Assets": "資産の保管",
    Market: "市場",
    "Market Capitalization": "時価総額",
    "Circulating Supply": "循環供給",
    "Click here to": "ここをクリックしてください",
    update: "アップデート",
    "the token information / general information": "トークン情報/一般情報",
    Metadata: "メタデータ",
    Missing: "行方不明",
    Chain: "チェーン",
    "Portfolio %": "ポートフォリオ",
    Price: "価格",
    Amount: "額",
    Showing: "ショーイング",
    "tokens with a value of": "の値を持つトークン",
    Sponsored: "スポンサー付き",
    Complier: "コンパイラ",
    "Token Name": "トークン名",
    "Total Holders": "総保有者数",
    "Collection Name": "コレクション名",
    Validator: "バリデーター",
    in: "で",
    View: "ビュー",
    "Latest Transactions": "最新の取引",
    "Latest Blocks": "最新ブロック",
    Customize: "カスタマイズ",
    "NFT Address": "NFTアドレス",
    "all Blocks": "すべてのブロック",
    "Top Tokens": "トップ・トークン",
    "Top Contracts": "トップ契約",
    "Top NFTs": "トップNFT",
    "Ask AI | Search by Address | Txn Hash | Block | Token... ":
      "アドレス／取引ハッシュ／ブロック／トークンで検索... ",
    "No transactions found.": "取引は見つかりませんでした。",
    // "BlocksScan Explorer": "ブロックスキャンエクスプローラー",
    "Market Cap": "時価総額",
    "Total Transactions": "総取引高",
    "MED GAS PRICE": "メドガス価格",
    "TOTAL BLOCK": "トータルブロック",
    "GasTracker Estimate for Average Gas":
      "ガストラッカーによる平均ガス見積もり",
    "Total Accounts": "総アカウント数",
    "Daily Transactions": "日々の取引",
    "Contact Us": "お問い合わせ",
    "Feedback Form": "フィードバックフォーム",
    "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that":
      "ブロックエクスプローラーサービスに直接関連する問題についてのみサポートできることをご理解ください。お問い合わせの前に、以下のことをお知らせください。",
    "Pending Transaction": "保留中の取引",
    "We do not": "私たちはしない",
    "process transactions": "トランザクションを処理する",
    "and are therefore unable to expedite, cancel or replace them. If you are having issues with pending transactions, please refer":
      "したがって、それらを早めたり、キャンセルしたり、交換したりすることはできません。保留中のトランザクションに関する問題が発生した場合は、こちらを参照してください。",
    here: "此処",
    "Blocksscan is an independent": "Blocksscan は独立したものです",
    "block explorer": "ブロックエクスプローラー",
    "unrelated to other service providers (unless stated explicitly otherwise) and is therefore unable to provide a precise response for inquiries that are specific to other service providers.":
      "他のサービスプロバイダーとは無関係であるため（明示的に別段の記載がない限り）、他のサービスプロバイダーに固有の問い合わせに対して正確な回答を提供することはできません。",
    "Knowledge Base": "知識ベース",
    "The links on the right might already have the answer to your inquiry. If they don't, perhaps one of the other articles in our":
      "右側のリンクには、あなたの問い合わせに対する答えがすでに掲載されている可能性があります。そうでない場合は、おそらく他の記事のいずれかが必要です。",
    "page does.": "ページはそうします。",
    "Community Support": "コミュニティサポート",
    "We will never ask for your private keys or passwords.":
      "私たちがあなたの秘密キーやパスワードを尋ねることは決してありません。",
    "NEVER send us these.": "決して私たちにこれらを送ってはいけません。",
    "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not":
      "タイムリーに対応するよう最善を尽くしておりますが、お問い合わせが殺到していることがよくあります。しないでください",
    "spam us": "私たちにスパムメールを送信する",
    "Wallet / Exchange / Project related issues":
      "ウォレット / エクスチェンジ / プロジェクト関連の問題",
    "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.":
      "ウォレット サービス プロバイダー、取引所、またはプロジェクト/契約所有者に連絡してさらなるサポートを求めてください。プラットフォームに関連する問題やプラットフォームから発生する問題については、彼らの方がより適切な立場にあるためです。",
    Subject: "主題",
    "Please Select Your Message Subject": "メッセージの件名を選択してください",
    "General Inquiry": "一般的なお問い合わせ",
    Advertising: "広告",
    "Update Token Info": "トークン情報の更新",
    "Add Name Tag/Label": "ネームタグ/ラベルの追加",
    "Request Removal of Name Tag": "名札の削除をリクエストする",
    "Suggest Transaction Action": "トランザクションアクションの提案",
    "Update Proxy Contract's Implementation Address":
      "プロキシ契約の実装アドレスの更新",
    "Report Phishing Address": "フィッシングアドレス報告",
    "Security Audit": "セキュリティ監査",
    "Priority Support": "優先サポート",
    "API Support": "APIサポート",
    "1. Inquiries": "1.問い合わせ",
    "2. Submissions": "2. 提出物",
    "3. Security": "3. セキュリティ",
    "Help & Support": "ヘルプ＆サポート",
    "24/7 support. Our team is here to help you and make sure your product is up to date.":
      "年中無休のサポート。私たちのチームがお客様をサポートし、製品が最新の状態であることを確認します。",
    Sales: "販売",
    "Have a presale question about our products and features? Or looking for a refund?":
      "当社の製品と機能について販売前の質問がありますか?それとも返金をお探しですか?",
    "Media & Press": "メディアとプレス",
    "Online awards and publications. Get our online media resources and learn about our company.":
      "オンラインの賞と出版物。オンライン メディア リソースを入手して、当社について学びましょう。",
    "Tx Hash|Block|Age": "トランザクション・ハッシュ|ブロック|年齢",
    "12 days ago": "12日前",
    "Event Logs": "イベントログ",
    Mint: "ミント",
    Transaction: "トランザクション",
    Success: "成功",
    Failed: "失敗",
    Pending: "未決",
    "Contract call": "契約コール",
    "Contract creation": "契約書の作成",
    "Token transfer": "トークンの転送",
    "Token creation": "トークンの作成",
    "Coin transfer": "コイン・トランスファー",
    REMASC: "報酬マネージャーのスマートコントラクト",
    Bridge: "ブリッジ",
    Rank: "ランク",
    Quantity: "数量",
    Item: "アイテム",
    Details: "詳細",
    ID: "身元",
    "Last Traded": "最後の取引",
    "Estimated Value on Day of Txn": "取引当日の推定価格",
    "Displaying current Value; Click to show Value on Day of Txn":
      "現在の値を表示します。クリックすると取引当日の価値が表示されます",
    Action: "アクション",
    "Verify & Publish Contract Source Code": "契約ソースコードの検証と公開",
    "COMPILER TYPE AND VERSION SELECTION":
      "コンパイラーのタイプとバージョンの選択",
    "Contract Licence": "契約ライセンス",
    "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.":
      "ベスト プラクティスとして、すべての契約ソース コード所有者、発行者、および作成者は、提供される検証済みの契約ソース コードに付随するライセンスも指定することをお勧めします。",
    "Please select": "選んでください",
    "Verification Method": "検証方法",
    "Compiler Type": "コンパイラ・タイプ",
    "Enter name here...": "ここに名前を入力...",
    "Is Yul Contract": "Yulコントラクトですか",
    "The compiler version is specified in pragma solidity X.X.X. Use the compiler version rather than the nightly build. If using the Solidity compiler, run solc —version to check.":
      "コンパイラのバージョンはプラグマ Solidity X.X.X で指定します。夜間ビルドではなくコンパイラ バージョンを使用してください。 Solidity コンパイラーを使用している場合は、solc — バージョンを実行して確認します。",
    "The EVM version the contract is written for. If the bytecode does not match the version, we try to verify using the latest EVM version.":
      "コントラクトが書かれている Ethereum 仮想マシンのバージョン。バイトコードがバージョンと一致しない場合は、最新の Ethereum 仮想マシン バージョンを使用して検証を試みます。",
    "Type here ...": "ここに入力 ...",
    "We recommend using flattened code. This is necessary if your code utilizes a library or inherits dependencies. Use the POA solidity flattener or the Truffle flattener.":
      "フラット化されたコードを使用することをお勧めします。これは、コードがライブラリを利用する場合、または依存関係を継承する場合に必要です。 Proof-Of-Authority (PoA) Solidity Flattener または Truffle Flattener を使用します。",
    "Enter Source Code...": "ソースコードを入力...",
    "Standard JSON Input": "標準の JavaScript オブジェクト表記法 (JSON) 入力",
    "Sources and Metadata JSON": "ソースとメタデータ JSON",
    "Sources *.sol or *.yul files": "ソース *.sol または *.yul ファイル",
    "Main Interfaces (.vy or .json)":
      "メインインターフェイス (.vy または .json)",
    "Upload the standard input JSON file created during contract compilation":
      "コントラクトのコンパイル中に作成された標準入力 JSON ファイルをアップロードします",
    "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.":
      "すべての Solidity コントラクト ソース ファイルとコントラクトのコンパイル中に作成された JSON メタデータ ファイルをアップロードします。",
    "Upload all Solidity or Yul contract source files":
      "すべての Solidity または Yul 契約のソース ファイルをアップロードする",
    "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below":
      "プライマリコンパイル済みVyperコントラクト。バイトコードがデプロイされているメイン コントラクトのみをここに追加します。他のすべてのファイルは下のインターフェイス ボックスにアップロードできます。",
    "Drag & drop files here, or click to select files":
      "ここにファイルをドラッグ アンド ドロップするか、クリックしてファイルを選択します",
    "Verify & Publish": "検証して公開する",
    Reset: "リセット",
    "Gas tracker": "ガストラッカー",
    "Network utilization": "ネットワーク利用率",
    "low load": "低負荷",
    "Last updated": "最終更新",
    Fast: "速い",
    Normal: "ノーマル",
    Slow: "遅い",
    "Gas price history": "ガス価格の履歴",
    "Charts & stats": "チャートと統計",
    "Account Balance Checker": "口座残高チェッカー",
    "You can Lookup the Account": "アカウントを検索できます",
    "Historical Balance at a specific Block No or Date.":
      "特定のブロック番号または日付における過去の残高。",
    Account: "アカウント",
    "Date for the snapshot": "スナップショットの日付",
    "Block Number": "ブロック番号",
    Lookup: "見上げる",
    "Choose desired language": "希望の言語を選択",
    "Choose the theme": "テーマを選ぶ",
    "Dark Mode": "ダークモード",
    "Do you want to show relative time? eg.": "相対時間を表示しますか?例えば。",
    "Do you want to show UTC time? eg.": "UTC時間を表示しますか?例えば。",
    "Change Fiat Currency": "不換紙幣の変更",
    Use: "使用",
    "prefix instead of": "の代わりに接頭辞",
    "NFT Latest Mints": "NFT最新ミント",
    "Token Id": "トークン・アイデンティティ",
    "NFT Transfer": "NFTトランスファー",
    "Your browser does not support the video tag.":
      "お使いのブラウザはビデオタグをサポートしていません。",
    "Min Price": "最低価格",
    "Last Sale": "ラストセール",
    "Current owner of this NFT.": "このNFTの現オーナー。",
    "Address of this NFT contract.": "このNFT契約の住所",
    "Address that deployed this NFT contract.":
      "この NFT コントラクトをデプロイしたアドレス。",
    Creator: "クリエイター",
    "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.":
      "画像の保存方法によるNFTの分類： オンチェーンとはブロックチェーン上にデータが保存されているものを指し、オフチェーンとはインターネット上の別の場所にデータが保存されているものを指す。",
    Classification: "分類",
    "This NFT's unique token ID.": "このNFTの固有のトークンID。",
    "The standard followed by this NFT.": "このNFTに続く標準。",
    "Token Standard": "トークン規格",
    "Marketplaces trading this NFT.": "このNFTを取引するマーケットプレイス。",
    Marketplaces: "マーケットプレイス",
    Properties: "プロパティ",
    Description: "説明",
    Collection: "コレクション",
    Volume: "音量",
    Change: "変化",
    "Max Price": "最高価格",
    "NFT Top Mints": "NFTトップミント",
    Owners: "所有者",
    "Total Assets": "総資産",
    "Note: Filtered by  totalSupply() , method returned a zero value.":
      "注：トータル・サプライ()でフィルタリングされ、メソッドはゼロ値を返した。",
    "A Paid Service": "支払いサービス",
    "At BlocksScan, we handle a substantial volume of support requests daily. While we strive our best to respond to each one in a timely manner, we recognize that certain projects may want to submit their token information quicker than our standard turnaround time.":
      "BlocksScanでは、毎日大量のサポートリクエストを処理しています。その一つ一つにタイムリーに対応するよう最善を尽くしていますが、プロジェクトによっては、トークン情報を標準納期よりも早く提出したい場合もあることを認識しています。",
    "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.":
      "トークンの更新を迅速に行うことに加えて、契約の検証など、その他の緊急または技術的な問題に対する優先サポートも提供します。",
    "Kindly follow the simple steps below to get your support prioritized.":
      "以下の簡単な手順に従って、サポートの優先順位を決定してください。",
    "Get Started": "始めましょう",
    "How to Start": "始め方",
    "Simple steps for getting Priority Support.":
      "優先サポートを受けるための簡単な手順。",
    "Step 1": "ステップ1",
    "Check Token Information": "トークン情報の確認",
    "Ensure that token information is submitted. Check our token update guidelines":
      "トークン情報が送信されていることを確認してください。トークン更新ガイドラインを確認してください",
    "Step 2": "ステップ2",
    "Provide Token Update Request": "トークン更新リクエストを提供する",
    "Provide us with the token update request information in the contact form below.":
      "下のコンタクトフォームにトークン更新リクエスト情報を入力してください。",
    "Step 3": "ステップ3",
    "We'll Review Your Request": "リクエストを検討させていただきます",
    "Our team will review your request and contact you using an official":
      "私たちのチームは、あなたのリクエストを検討し、公式に連絡します",
    "Please use this form to get in touch for priority support or featured listing with our team.":
      "優先サポートや注目のリストについて当社チームにご連絡いただくには、このフォームをご利用ください。",
    "Requester Name": "依頼者名",
    "Requester Email": "リクエスタのメールアドレス",
    "Token Contract Address": "トークンコントラクトアドレス",
    "Choose Priority Service": "優先サービスを選択してください",
    "Token Update": "トークンの更新",
    "Featured Listing": "注目のリスト",
    "Contract Verification": "契約の検証",
    Others: "その他",
    "Learn more about": "詳しくはこちら",
    "Helpdesk Ticket Number": "ヘルプデスクチケット番号",
    "Additional Notes": "その他の注意事項",
    Note: "注意",
    "We will": "私たちは",
    NOT: "ない",
    "request payment via direct deposit. All payments will be directed through our official payment channel.":
      "直接入金による支払いをリクエストします。すべての支払いは、当社の公式支払いチャネルを通じて行われます。",
    NEVER: "なし",
    "ask for your private key or seed words. Please":
      "秘密鍵またはシードワードを尋ねます。お願いします",
    "DO NOT SHARE": "共有しないでください",
    "your private keys with anyone.": "あなたの秘密鍵を誰にでも共有できます。",
    "Send Message": "メッセージを送信",
    "Ex. John Doe": "例 - ジョン・ドウ",
    "If you have any additional notes, please leave them here":
      "その他の注意事項があれば、ここに書き残してください。",
    "Privacy Policy": "プライバシーポリシー",
    "Welcome to Blocksscan Explorer Services":
      "Blocksscan Explorer サービスへようこそ",
    we: "私たち",
    us: "私たち",
    our: "私たちの",
    "We are committed to respecting the privacy and security of our users' information. This Privacy Policy outlines the types of information we may collect from you or that you may provide when you use our services, and our practices for collecting, using, maintaining, protecting, and disclosing that information.":
      "当社はユーザーの情報のプライバシーとセキュリティを尊重することに尽力しています。このプライバシー ポリシーでは、当社がお客様から収集する情報の種類、またはお客様が当社のサービスを使用する際に提供する可能性のある情報の種類、およびその情報の収集、使用、維持、保護、開示に関する当社の慣行について概説します。",
    "Information We Collect": "当社が収集する情報",
    "We collect several types of information from and about users of our Blocksscan Explorer Services, including":
      "当社は、Blocksscan Explorer サービスのユーザーから、およびユーザーに関するいくつかの種類の情報を収集します。",
    "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.":
      "個人情報: 登録時または当社サービスの使用中に提供される、名前、電子メール アドレス、支払い詳細など、お客様を個人的に特定するデータ。",
    "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.":
      "非個人情報： IPアドレス、ブラウザの種類、アクセス時間など、個人を特定するものではないが、当社サービスの利用に関連するデータ。",
    "Transactional Information: Data related to transactions conducted on the network, which may include public blockchain data.":
      "取引情報： ネットワーク上で行われた取引に関するデータで、パブリックブロックチェーンデータを含む場合がある。",
    "How We Use Your Information": "お客様の情報の使用方法",
    "We use the information that we collect about you or that you provide to us, including any personal information":
      "当社は、お客様について収集した情報、またはお客様が当社に提供した情報（個人情報を含む）を使用します。",
    "To present our services and its contents to you.":
      "当社のサービスおよびその内容をお客様にご紹介するため。",
    "To provide you with information, products, or services that you request from us.":
      "お客様が当社に求める情報、製品、サービスを提供するため。",
    "To fulfill any other purpose for which you provide it.":
      "お客様が提供するその他の目的を達成するため。",
    "To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.":
      "当社の義務を履行し、お客様と当社との間で締結された契約から生じる権利を行使するため。",
    "To notify you about changes to our service or any products or services we offer or provide through it.":
      "当社のサービス、または当社が提供または提供する製品やサービスの変更についてお客様に通知するため。",
    "For any other purpose with your consent.":
      "その他、お客様の同意を得た目的のため。",
    "Data Security": "データセキュリティ",
    "We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. However, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our services.":
      "当社は、お客様の個人情報を偶発的な紛失や不正なアクセス、使用、改ざん、開示から保護するための対策を講じています。しかしながら、インターネットを介した情報の送信は完全に安全というわけではありません。当社は、お客様の個人情報を保護するために最善を尽くしますが、当社のサービスに送信されるお客様の個人情報のセキュリティを保証することはできません。",
    "Data Retention": "データ保持",
    "We will retain your personal information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.":
      " 当社は、お客様のアカウントが有効である限り、またはお客様にサービスを提供するために必要な限り、お客様の個人情報を保持します。当社は、当社の法的義務の遵守、紛争の解決、および契約の執行のために必要なお客様の情報を保持し、使用します。",
    "Your Rights": "あなたの権利",
    "You have the right to request access to, correct, or delete any personal information that you have provided to us. You may also object to or request restrictions on the processing of your personal information.":
      "お客様は、当社に提供した個人情報へのアクセス、訂正、削除を要求する権利を有します。また、個人情報の処理に異議を唱えたり、制限を要求したりすることもできます。",
    "Changes to Our Privacy Policy": "プライバシーポリシーの変更",
    "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.":
      "当社のプライバシーポリシーに変更があった場合は、このページに掲載する方針です。プライバシーポリシーの最終改訂日は、ページの上部に記載されています。利用者は、当社が利用者の最新かつ有効で配信可能な電子メールアドレスを持っていることを確認し、当社のウェブサイトおよびこのプライバシーポリシーを定期的に訪問して、変更がないかどうかを確認する責任を負うものとします。",
    "Contact Information": "連絡先",
    "To ask questions or comment about this privacy policy and our privacy practices, contact us at":
      "このプライバシー ポリシーおよび当社のプライバシー慣行についてご質問またはコメントが必要な場合は、次のアドレスまでお問い合わせください。",
    "By using Blocksscan Explorer Services, you consent to our Privacy Policy.":
      "お客様は、Blocksscan Explorerのサービスを利用することにより、当社のプライバシーポリシーに同意したものとみなされます。",
    "Max Total Supply": "最大総供給量",
    "Note: Filtered by  totalSupply() , method returned a zero value":
      "注：合計供給量()によってフィルタリングされ、メソッドはゼロ値を返した。",
    Holders: "ホルダー",
    "Total Transfers": "振替高",
    Implementation: "実施",
    "Fully Diluted Market Cap": "完全希薄化後時価総額",
    "Circulating Supply Market Cap": "循環供給市場キャップ",
    "Other Info": "他の情報",
    "Token Contract": "トークンコントラクト",
    "FILTERED BY TOKEN HOLDER": "トークン保有者ごとにフィルタリング",
    BALANCE: "バランス",
    Inventory: "在庫",
    Info: "情報",
    With: "と",
    decimals: "小数点以下",
    "Volume 24h": "ボリューム24時間",
    "Circulating Market Cap": "流通時価総額",
    "Onchain Market Cap": "オンチェーン時価総額",
    "Token Transactions": "トークン取引",
    "X (Twitter)": "X（ツイッター）",
    Github: "ギットハブ",
    Facebook: "フェイスブック",
    Instagram: "インスタグラム",
    "Back to Top": "このページのトップへ",
    "Powered by BlocksScan": `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} の提供`,
    "BlocksScan is a Block Explorer and Analytics Platform for":
       `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"}はブロックエクスプローラーであり、次のような分析プラットフォームです。`,
    "a decentralized smart contracts platform.":
      "分散型スマート・コントラクト・プラットフォームである。",
    Company: "会社",
    "About Us": "私たちについて",
    "Brand Assets": "ブランド資産",
    Careers: "キャリア",
    "We're Hiring": "募集中です",
    Terms: "条項",
    Privacy: "プライバシー",
    Community: "コミュニティ",
    "API Documentation": "APIドキュメント",
    "Network Status": "ネットワークステータス",
    Newsletters: "ニュースレター",
    Products: "プロダクツ",
    Services: "サービス",
    "Explorer-as-a-Service": "エクスプローラー・アズ・ア・サービス",
    "API Plans": "APIプラン",
    "All Rights Reserved": "無断転載を禁じます",
    Donations: "寄付",
    Home: "家",
    CodeRunAI: "コードランAI",
    Blockchain: "ブロックチェーン",
    "Pending Transactions": "未決済取引",
    "Contract Internal Transactions": "コントラクト内部取引",
    "View Blocks": "ブロックを見る",
    "Top Accounts": "トップアカウント",
    "Token Transfers": "トークン譲渡",
    "Top Mints": "トップミント",
    "Latest Trades": "最新の取引",
    "Latest Transfers": "最新の振替",
    "Latest Mints": "最新のミント",
    Resources: "リソース",
    "Charts And Stats": "チャートと統計",
    "Top Statistics": "トップの統計",
    Leaderboard: "リーダーボード",
    Directory: "ディレクトリ",
    Developers: "開発者",
    "Verify Contract": "契約の確認",
    "Similar Contract Search": "類似契約検索",
    "Smart Contract Search": "スマートコントラクトの検索",
    "Contract Diff Checker": "契約差分チェッカー",
    "Vyper Online Compiler": "Vyperオンラインコンパイラー",
    "Bytecode to Opcode": "バイトコードからオペコードへ",
    "Broadcast Transaction": "ブロードキャストトランザクション",
    More: "もっと",
    "Balance Checker": "バランスチェッカー",
    "Gas Tracker": "ガストラッカー",
    Light: "ライト",
    Dim: "薄暗い",
    Dark: "暗い",
    Gas: "ガス",
    "Last Updated": "最終更新日",
    "Gas tracker overview": "ガストラッカーの概要",
    "All Transactions": "すべての取引",
    "Custom Card": "カスタムカード",
    "Customize this card by selecting one of the options below":
      "以下のオプションのいずれかを選択して、このカードをカスタマイズします",
    "Advanced Filters": "高度なフィルター",
    Present: "現在",
    "Saved Filters": "保存されたフィルター",
    Close: "閉じる",
    "Save Changes": "変更内容を保存",
    "Top NFT": "トップNFT",
    "Top Contract": "トップコントラクト",
    "Top Token": "トップ・トークン",
    "Latest Transaction": "最新の取引",
    "Latest Block": "最新のブロック",
    Live: "ライブ",
    WIP: "仕掛品",
  },
};
