import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {en} from "./i18n.translations";
import {jp} from "./jp/common.translation";
import {ru} from "./ru/common.translation";
import {hi} from "./hi/common.translation";
import {cn} from "./cn/common.translation";
import {kr} from "./kr/common.translation";
import {pt} from "./pt/common.translation";
import {fr} from "./fr/common.translation";
import {du} from "./du/common.translation";
import {es} from "./es/common.translation";
import {tu} from "./tu/common.translation";
import {ua} from "./ua/common.translation";
import {vi} from "./vi/common.translation";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en,
      fr,
      pt,
      cn,
      kr,
      ru,
      jp,
      hi,
      du,
      es,
      tu,
      ua,
      vi,
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
