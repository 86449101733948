import {createSlice} from '@reduxjs/toolkit'

export const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    value: 0,
  },
  reducers: {
    dashboardTest: (state) => {
      state.value -= 1
    },
  },
})

// Action creators are generated for each case reducer function
export const { dashboardTest } = dashboard.actions

export default dashboard.reducer
