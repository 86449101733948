export const hi = {
  translations: {
    welcome: "नमस्ते",
    Settings: "सेटिंग्स",
    "This page allows you to customize your browsing experience on Blocksscan.":
      "इस पृष्ठ पर आप ब्लॉकस्कैन पर अपने ब्राउज़िंग अनुभव को अनुकूलित कर सकते हैं।",
    Address: "पता",
    Overview: "अवलोकन",
    Balance: "शेषधन",
    Value: "मूल्य",
    "More Info": "अधिक जानकारी",
    "Private Name Tags": "निजी नाम टैग",
    Add: "जोड़ें",
    "LAST TXN SENT": "अंतिम TXN भेजा गया",
    from: "से",
    "FIRST TXN SENT": "पहला TXN भेजा गया",
    "Multichain Info": "मल्टीचेन जानकारी",
    day: "दिन",
    days: "दिन",
    hour: "घंटा",
    hours: "घंटे",
    minute: "मिनट",
    minutes: "मिनट",
    second: "सेकंड",
    seconds: "सेकंड",
    ago: "पहले",
    "Multichain Portfolio": "मल्टीचेन पोर्टफोलियो",
    Transactions: "लेन-देन",
    "Internal Transactions": "आंतरिक लेन-देन",
    Transfers: "स्थानांतरण",
    "NFT Transfers": "NFT स्थानांतरण",
    "Token Holding": "टोकन धारण",
    "Token Inventory": "टोकन इन्वेंटरी",
    Events: "घटनाएँ",
    Contract: "अनुबंध",
    Rewards: "पुरस्कार",
    Analytics: "विश्लेषिकी",
    Download: "डाउनलोड",
    "CSV Export": "CSV निर्यात",
    Tokens: "टोकन",
    Chains: "श्रृंखलाएँ",
    "Hide Chains": "श्रृंखलाएँ छिपाएँ",
    "Show More Chains": "अधिक श्रृंखलाएँ दिखाएँ",
    "Hide All Chains": "सभी श्रृंखलाएँ छिपाएँ",
    "Display All Chains": "सभी श्रृंखलाएँ दिखाएँ",
    "Name Tag": "नाम टैग",
    Percentage: "प्रतिशत",
    Capacity: "क्षमता",
    Owner: "मालिक",
    "Latest Signed Block": "नवीनतम साइन किया गया ब्लॉक",
    Status: "स्थिति",
    "Contract Name": "अनुबंध का नाम",
    Compiler: "कंपाइलर",
    Version: "संस्करण",
    Txns: "ट्रांजैक्शन",
    Verified: "सत्यापित",
    Audit: "मंचन",
    License: "लाइसेंस",
    "New Smart Contracts": "नए स्मार्ट अनुबंध",
    "New Verified Contracts": "नए सत्यापित अनुबंध",
    "Smart Contracts": "स्मार्ट अनुबंध",
    "Verified Smart Contract": "सत्यापित स्मार्ट अनुबंध",
    Optimization: "अनुकूलन",
    "Constructor args": "निर्माता तत्व",
    "Verified Contracts": "सत्यापित अनुबंध",
    Solidity: "सॉलिडिटी",
    Accounts: "खाते",
    "Block Height": "ब्लॉक ऊंचाई",
    ToDo: "करने के लिए",
    Finalized: "समाप्त",
    Timestamp: "समय-चिह्न",
    "Proposed On": "पर प्रस्तावित",
    "Block proposed on epoch": "एपॉक पर प्रस्तावित ब्लॉक",
    Withdrawals: "निकासी",
    "withdrawals in this block": "इस ब्लॉक में निकासी",
    Fee: "शुल्क",
    "Fee Recipient": "शुल्क प्राप्तकर्ता",
    "rsync-builder in 12 secs": "12 सेकंड में आरएसएन्क-निर्माता",
    "Block Reward": "ब्लॉक पुरस्कार",
    "Total Difficulty": "कुल कठिनाई",
    Size: "आकार",
    bytes: "बाइट्स",
    "Gas Used": "उपयुक्त गैस",
    "Gas Target": "गैस लक्ष्य",
    "Gas Limit": "गैस सीमा",
    "Base Fee Per Gas": "प्रति गैस मूल शुल्क",
    "Burnt Fees": "जले हुए शुल्क",
    "Extra Data": "अतिरिक्त डेटा",
    rsyncbuilder: "आरएसएनसी निर्माता",
    Hash: "हैश",
    "Parent Hash": "माता हैश",
    StateRoot: "रूट स्थिति",
    WithdrawalsRoot: "निकासी रूट",
    Nonce: "नॉन्स",
    "More Details": "अधिक विवरण",
    "Click to show less": "कम दिखाने के लिए क्लिक करें",
    "Click to show more": "और दिखाने के लिए क्लिक करें",
    "Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block.":
      "ब्लॉक संख्या के रूप में भी जाना जाता है। ब्लॉकचेन की लंबाई को संकेत करने वाली ब्लॉक ऊंचाई, नए ब्लॉक के जोड़ने के बाद बढ़ जाती है।",
    "The finality status of the block.": "ब्लॉक की अंतिमता स्थिति।",
    "This block is finilized.": "यह ब्लॉक पूर्ण हुआ है।",
    "The date and time at which a block is produced.":
      "एक ब्लॉक उत्पन्न होने की तारीख और समय।",
    "Slot and epoch this block is proposed on.":
      "इस ब्लॉक की प्रस्तावित स्लॉट और एपॉक।",
    "The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves Ether value.":
      "ब्लॉक में लेनदेनों की संख्या। आंतरिक लेनदेन कार्यसिद्धि के परिणामस्वरूप लेनदेन है जो ईथर मूल्य को शामिल करता है।",
    "Number of beacon withdrawals in this block.":
      "इस ब्लॉक में बीकन निकासी की संख्या।",
    "Address receiving fees from transactions in this block.":
      "इस ब्लॉक की लेनदेन से शुल्क प्राप्त करने वाला पता।",
    "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.":
      "प्रत्येक ब्लॉक के लिए, ब्लॉक उत्पादक को ब्लॉक में सभी लेनदेनों के लिए भुगतान किए गए शुल्क के ऊपर एक सीमित मात्रा में ईथर का इनाम दिया जाता है।",
    "Total difficulty of the chain until this block.":
      "इस ब्लॉक तक श्रृंखला की कुल कठिनाई।",
    "The block size is actually determined by the block's gas limit.":
      "ब्लॉक का आकार वास्तव में ब्लॉक की गैस सीमा द्वारा निर्धारित किया जाता है।",
    "The total gas used in the block and its percentage of gas filled in the block.":
      "ब्लॉक में प्रयुक्त कुल गैस और ब्लॉक में भरे गए गैस का प्रतिशत।",
    "Total gas limit provided by all transactions in the block.":
      "ब्लॉक में सभी लेनदेनों द्वारा प्रदान की गई कुल गैस सीमा।",
    "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.":
      "लंदन अपग्रेड के बाद, यह एक ट्रांजैक्शन को ब्लॉक में शामिल किया जाने के लिए आवश्यक न्यूनतम गैस उपयोग गुणक को दर्शाता है।",
    "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.":
      "लंदन अपग्रेड के बाद, यह उस ट्रांजैक्शन शुल्क का हिस्सा दर्शाता है जो जलाया जाता है: baseFeePerGas * gasUsed।",
    "Any data that can be included by the block producer in the block.":
      "ब्लॉक उत्पादक द्वारा ब्लॉक में शामिल किए जाने वाले कोई भी डेटा।",
    "The hash of the block header of the current block.":
      "वर्तमान ब्लॉक के ब्लॉक हेडर का हैश।",
    "The hash of the block from which this block was generated, also known as its parent block.":
      "इस ब्लॉक के जिस ब्लॉक से उत्पन्न हुआ था, उसका हैश, जिसे इसके माता-पिता ब्लॉक भी कहा जाता है।",
    "The root of the state trie.": "राज्य ट्राय की रूट।",
    "Block header withdrawal root hash.": "ब्लॉक हेडर वापसी रूट हैश।",
    "Block nonce is a value used during mining to demonstrate proof of work for a block.":
      "ब्लॉक के लिए कार्य का प्रमाण दिखाने के लिए खनन के दौरान उपयोग की जाने वाली एक मान होती है।",
    Block: "ब्लॉक",
    Blocks: "ब्लॉक",
    Age: "आयु",

    Node: "नोड",
    "Base Fee": "बेस शुल्क",
    "See preview of the transaction details.":
      "लेनदेन विवरण का पूर्वावलोकन देखें।",
    "Additional Info": "अतिरिक्त जानकारी",
    confirmation: "पुष्टि",
    "Transaction Action": "लेनदेन क्रिया",
    Transfer: "स्थानांतरण",
    To: "को",
    "Transaction Fee": "लेनदेन शुल्क",
    "Gas Info": "गैस सूचना",
    "See more details": "अधिक जानकारी देखें",
    "Tx Hash": "ट्रांजैक्शन हैश",
    Type: "प्रकार",
    From: "से",
    "Tx Fee": "ट्रांजैक्शन शुल्क",
    "in the position": "पद में",
    "A TxHash or transaction hash is a unique 66-character identifier that is generated whenever a transaction is executed.":
      "एक TxHash या लेनदेन हैश एक अद्वितीय 66-वर्णीय पहचानकर्ता है जो हर बार जनित होता है जब एक लेनदेन को क्रियान्वित किया जाता है।",
    "Transaction Hash": "लेनदेन हैश",
    "TxHash to clipboard": "हैश को क्लिपबोर्ड पर भेजें",
    "The status of the transaction.": "लेनदेन की स्थिति।",
    "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.":
      "लेनदेन दर्ज किए जाने वाले ब्लॉक की संख्या। ब्लॉक पुष्टिकरण यह दिखाते हैं कि लेनदेन उत्पन्न होने के बाद कितने ब्लॉक जोड़े गए हैं।",
    Confirmation: "पुष्टि",
    "Pending...": "लंबित...",
    "The date and time at which a transaction is produced.":
      "जिस दिनांक और समय पर लेनदेन उत्पन्न हुआ है।",
    "Confirmed within": "के भीतर पुष्टि की गई",
    sec: "सेकंड",
    "Highlighted events of the transaction.": "लेनदेन के चर्चित घटनाएँ।",
    "The sending party of the transaction.": "लेनदेन के भेजने वाला पक्ष।",
    "The receiving party of the transaction (could be a contract address).":
      "लेनदेन के प्राप्त करने वाला पक्ष (यह एक कॉन्ट्रैक्ट पता हो सकता है)।",
    created: "बनाया गया",
    "List of {{_tokenType}}20 tokens transferred in the transaction.":
      "लेनदेन में स्थानांतरित {{_tokenType}}20 टोकनों की सूची।",
    "Tokens Transferred": "स्थानांतरित टोकन",
    For: "के लिए",
    " tokens transferred in the transaction.": "लेनदेन में स्थानांतरित टोकन।",
    "List of": "की सूची",
    "Token ID": "टोकन आईडी",
    "List of tokens mint transferred in the transaction.":
      "लेनदेन में स्थानांतरित टोकनों की सूची।",
    "Tokens minted": "टोकन बनाए गए",
    "List of tokens burnt transferred in the transaction.":
      "लेनदेन में स्थानांतरित, जलाए गए टोकनों की सूची।",
    "Tokens burnt": "जलाए गए टोकन",
    "The value being transacted in Ether and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction.":
      "ईथर और फिएट मूल्य में सौदा हो रहा है। ध्यान दें: आप लेनदेन के समय के इतिहासी मूल्य देखने के लिए फिएट मूल्य पर क्लिक कर सकते हैं।",
    "Amount paid to process the transaction in Ether and fiat value.":
      "ईथर और फिएट मूल्य में लेनदेन को प्रोसेस करने के लिए भुगतान किया गया राशि।",
    "Actual Tx Cost/Fee": "वास्तविक लेनदेन लागत/शुल्क",
    "Cost per unit of gas spent for the transaction, in Ether and Gwei.":
      "लेनदेन के लिए खर्च किया गया गैस प्रति इकाई, ईथर और ग्वी में।",
    "Gas Price": "गैस मूल्य",
    "Maximum amount of gas allocated for the transaction & the amount eventually used. Normal ETH transfers involve 21,000 gas units while contracts involve higher values.":
      "लेनदेन के लिए नियत की गई गैस की अधिकतम राशि और अंत में उपयोग की गई राशि। सामान्य ईएथ ट्रांजैक्शन 21,000 गैस इकाइयों को शामिल करते हैं जबकि अनुबंधों में अधिक मूल्यों को शामिल करते हैं।",
    "Gas Limit & Usage by Txn": "लेनदेन द्वारा गैस सीमा और उपयोग",
    "Base Fee refers to the network Base Fee at the time of the block, while Max Fee & Max Priority Fee refer to the max amount a user is willing to pay for their tx & to give to the block producer respectively.":
      "बेस शुल्क ब्लॉक के समय नेटवर्क बेस शुल्क को संदर्भित करता है, जबकि अधिकतम शुल्क और अधिकतम प्राथमिकता शुल्क उस मान को संदर्भित करते हैं जिसका उपयोग एक उपयोगकर्ता उनके tx के लिए भुगतान करने के लिए और ब्लॉक उत्पादक को देने के लिए करना चाहता है।",
    "Gas Fees": "गैस शुल्क",
    Max: "मैक्स",
    "Max Priority": "अधिकतम प्राथमिकता",
    Base: "बेस",
    "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.":
      "इस ट्रांजैक्शन से जली ईटीएच की कुल राशि और उन शुल्कों की कुल बचत जिसकी राशि उपयोगकर्ता इस ट्रांजैक्शन के लिए भुगतान करने के लिए तत्पर था।",
    "Burnt & Txn Savings Fees": "जली और ट्रांजैक्शन बचत शुल्क",
    Burnt: "जला हुआ",
    "Txn Savings": "ट्रांजैक्शन बचत",
    "Other data related to this transaction.":
      "इस लेनदेन से संबंधित अन्य डेटा।",
    "Other Attributes": "अन्य विशेषताएं",
    "Txn Type": "ट्रांजैक्शन प्रकार",
    Position: "स्थिति",
    Legacy: "विरासत",
    "Input Data": "इनपुट डेटा",
    Hex: "हेक्स",
    "to clipboard": "क्लिपबोर्ड पर",
    "Decode input data": "इनपुट डेटा को डिकोड करें",
    "Method id": "मेथड आईडी",
    Call: "कॉल",
    Name: "नाम",
    Data: "डेटा",
    "Logdata to clipboard": "लॉगडेटा क्लिपबोर्ड पर",
    "Address to clipboard": "क्लिपबोर्ड पर पता",
    Indexed: "सूचीबद्ध",
    Topics: "विषय",
    Text: "टेक्स्ट",
    Number: "संख्या",
    TO: "को",
    number: "संख्या",
    "Tx row trace to clipboard": "ट्रांजैक्शन पंक्ति का ट्रेस क्लिपबोर्ड पर",
    "No trace entries found.": "कोई ट्रेस प्रविष्टियाँ नहीं मिलीं।",
    Before: "पहले",
    After: "बाद में",
    "State Diffrence": "राज्य अंतर",
    Token: "टोकन",
    "Transaction Details": "लेनदेन विवरण",
    "Token transfers": "टोकन स्थानांतरण",
    Logs: "लॉग",
    state: "राज्य",
    "Raw trace": "कच्चा ट्रेस",
    Method: "विधि",
    "Careers at BlocksScan": "ब्लॉकस्कैन पर करियर",
    "Join BlocksScan and enhance the world's leading blockchain explorer and search, API &amp; analytics platform.":
      "ब्लॉकस्कैन में शामिल हों और दुनिया के प्रमुख ब्लॉकचेन एक्सप्लोरर और खोज, एपीआई और विश्लेषण प्लेटफ़ॉर्म को मजबूत बनाएं।",
    "Perks and Benefits": "लाभ और फायदे",
    "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.":
      "हमारा यह बस एक महान दृष्टिकोण नहीं है। हम इसे साकार करने के लिए विशेषज्ञ कंपनी और संस्कृति के साथ समर्थन प्रदान करते हैं।",
    "Free Flow Drinks &amp; Snacks": "मुक्त फ्लो ड्रिंक्स और स्नैक्स",
    "Never worry about a lack of refreshments or tasty morsels to munch on while adding value to the team.":
      "टीम को मूल्य जोड़ते समय आरामदायक प्रसाधनों या स्वादिष्ट टुकड़ों की कमी की चिंता कभी न करें।",
    "Best Coffee in Town": "शहर में सबसे अच्छा कॉफी",
    "State-of-the-art coffee machine and experienced baristas in the house for the coffee lovers to thrive.":
      "कॉफी प्रेमियों के लिए उन्नत कॉफी मशीन और अनुभवी बारिस्टा।",
    "Annual Trips": "वार्षिक यात्राएँ",
    "Enjoy a break from the daily grind with sponsored trips to cool new locations and experiences.":
      "नए ठंडे स्थानों और अनुभवों पर स्पॉन्सर की गई यात्राओं के साथ दिनचर्या से राहत का आनंद लें।",
    "Office Entertainment": "कार्यालय मनोरंजन",
    "Games and gadgets to distract yourself with while thinking up novel solutions to hard problems.":
      "मुश्किल समस्याओं के नवीनतम समाधानों को सोचते समय खुद को बहकाने के लिए खेल और गैजेट।",
    "Food Allowance": "खाने की अनुमति",
    "Extra lunch money provided along with the occasional team lunch and dinners.":
      "अनुकूल टीम लंच और डिनर के साथ अतिरिक्त लंच पैसे प्रदान किए गए।",
    "Commute Allowance": "यातायात भत्ता",
    "Concerns with transportation and parking eased so that team members can focus on delivering value.":
      "यातायात और पार्किंग के साथ संबंधित चिंताएं कम कर टीम सदस्य मूल्य प्रदान करने पर ध्यान केंद्रित कर सकते हैं।",
    "Flex Benefits": "फ्लेक्स लाभ",
    "For almost anything that improves your life - training courses, fitness plans... even beauty facials!":
      "अपने जीवन को सुधारने वाले लगभग हर कार्य के लिए - प्रशिक्षण पाठ्यक्रम, फिटनेस योजनाएँ ... यहां तक ​​कि सौंदर्य फेसियल्स भी!",
    "Medical Benefits": "चिकित्सा लाभ",
    "Coverage of outpatient, dental and optical services from a registered clinic of your choice.":
      "अपने पसंदीदा पंजीकृत क्लिनिक से आउटपेशेंट, डेंटल और ऑप्टिकल सेवाओं का कवरेज।",
    "Flexible working hours": "लचीले काम के समय",
    "Contribute to the team effort without worrying about what time to clock in.":
      "टाइम क्लॉक करने का समय क्या है इस बारे में चिंता किए बिना टीम प्रयास में योगदान दें।",
    "Learning Sessions": "सीखने के सत्र",
    "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.":
      "ज्ञानी और अनुभवी सहकर्मीयों के साथ उद्योग में नवीनतम अब तक की प्रगतियों और सर्वोत्तम प्रथाओं के साथ कदम रखें।",
    "Working at BlocksScan": "ब्लॉकस्कैन पर काम करना",
    "BlocksScan is a team pioneering at the forefront of the blockchain industry. Our platform provides the infrastructure for users and developers to access and build decentralized applications powering billions of dollars in value.":
      "ब्लॉकस्कैन ब्लॉकचेन उद्योग के मुख्यांतर नेतृत्व करने वाली एक टीम है। हमारा प्लेटफ़ॉर्म उपयोगकर्ताओं और डेवलपर्स के लिए व्यवस्था प्रदान करता है जो मूल्य में बिलियंस डॉलर को संचालित करने वाले वितरित एप्लिकेशन्स को एक्सेस और निर्माण करने के लिए।",
    "We are made up of diverse backgrounds and skillsets, with a combined passion for providing equitable access to open financial systems. While we strive for excellence in all that we do, we don't take ourselves too seriously either. We love to explore new frontiers and have fun in doing so.":
      "हम विविध पृष्ठभूमियों और कौशल सेट्स से मिलकर बने हैं, जिनमें खुले वित्तीय प्रणालियों के लिए समान उपयोग के लिए एक संयुक्त प्रेम है। हम जो कुछ भी करते हैं, उसमें उत्कृष्टता के लिए प्रयासरत रहते हैं, हम अपने आप को भी अधिक गंभीर नहीं लेते। हम नए सीमाओं का अन्वेषण करने में रुचि रखते हैं और इसे करते समय मजा करते हैं।",
    "Understand Contract Functionalities": "समझें संविदा कार्यक्षमताएँ",
    "What are the functions or methods available in this contract?":
      "इस संविदा में कौन-कौन से कार्य हैं या उपलब्ध हैं?",
    "Design a WithdrawBalance() method to withdraw contract balance.":
      "संविदा शेष राशि निकालने के लिए WithdrawBalance() विधि डिज़ाइन करें।",
    "What is the logical structure of this contract's code?":
      "इस संविदा के कोड का तार्किक संरचना क्या है?",
    "Is this a proxy contract?": "क्या यह एक प्रॉक्सी संविदा है?",
    "Write me a smart contract of voting.":
      "मुझे वोटिंग का एक स्मार्ट संविदा लिखें।",
    "What does this contract do?": "यह संविदा क्या करती है?",
    "How does SafeMath change the contract's state?":
      "सेफ़मैथ कैसे संविदा की स्थिति को बदलता है?",
    "Code Reader": "कोड रीडर",
    NEW: "नया",
    "Understand and use smart contracts better with CodeRunAI.":
      "CodeRunAI के साथ स्मार्ट कॉन्ट्रैक्ट को बेहतर समझें और उपयोग करें।",
    Mode: "मोड",
    "I have an API Key": "मेरे पास एपीआई कुंजी है",
    "Any open source contract": "कोई भी ओपन सोर्स अनुबंध",
    "Any question": "कोई भी प्रश्न",
    "I don't have an API Key": "मेरे पास एपीआई कुंजी नहीं है",
    "Popular open source contracts": "लोकप्रिय ओपन सोर्स अनुबंध",
    "Example questions": "उदाहरण प्रश्न",
    DEMO: "डेमो",
    "OpenAI API key": "OpenAI एपीआई कुंजी",
    "Where to find my API Key?": "मेरी एपीआई कुंजी कहाँ मिलेगी?",
    "Save it to the browser": "ब्राउज़र में सहेजें",
    "GPT Model": "जीपीटी मॉडल",
    "Smart Contract": "स्मार्ट कॉन्ट्रैक्ट",
    "Contract Address": "अनुबंध पता",
    "Contract Code": "अनुबंध कोड",
    Load: "लोड",
    Trending: "ट्रेंडिंग",
    "Contract Files": "अनुबंध फ़ाइलें",
    "Source Code": "स्रोत कोड",
    "Popular Contracts": "लोकप्रिय अनुबंध",
    "No Record Found": "कोई रिकॉर्ड नहीं मिला",
    "Loading...": "लोड हो रहा है...",
    "Hi, there.": "नमस्ते।",
    "I can help you in the following scenarios":
      "मैं आपकी निम्नलिखित स्थितियों में मदद कर सकता हूँ",
    ME: "मैं",
    "Enter any question you are interested in":
      "जिस प्रश्न में आप रुचि रखते हैं, उसे दर्ज करें",
    Signer: "हस्ताक्षरकर्ता",
    "Send native": "मूल भेजें",
    "Add one more": "एक और जोड़ें",
    Read: "पढ़ें",
    Write: "लिखें",
    Loading: "लोड हो रहा है",
    "Method Name": "विधि का नाम",
    Permalink: "पर्मालिंक",
    Code: "कोड",
    "Read Contract": "अनुबंध पढ़ें",
    "Read Proxy": "प्रॉक्सी पढ़ें",
    "Write Contract": "अनुबंध लिखें",
    "Write Proxy": "प्रॉक्सी लिखें",
    "Contract Source Code Verified": "अनुबंध स्रोत कोड सत्यापित है",
    "Are you the contract creator": "क्या आप अनुबंध निर्माता हैं",
    "Verify And Publish": "सत्यापित करें और प्रकाशित करें",
    "your contract source code today": "आपका अनुबंध स्रोत कोड आज",
    "EVM Version": "ईवीएम संस्करण",
    "Optimization Runs": "अनुकूलन चल रहा है",
    "Contract File Path": "अनुबंध फ़ाइल पथ",
    "Compiler Version": "कंपाइलर संस्करण",
    "Optimization Enabled": "अनुकूलन सक्षम है",
    "Verified At": "पर सत्यापित किया गया।",
    "Constructor Arguments": "निर्माता के तर्क",
    "ABI-encoded": "ABI संक्षिप्त",
    "Byte Code": "बाइट कोड",
    File: "फ़ाइल",
    of: "का",
    "ABI for the implementation contract at":
      "पर क्रियान्वयन अनुबंध के लिए ABI",
    "Type of Backing": "पीछे के प्रकार",
    "Type of Peg": "पेग के प्रकार",
    "Collater­alization": "गारंटी",
    "Storage Of Assets": "संपत्तियों का संग्रह",
    Market: "बाजार",
    "Market Capitalization": "बाजारीकरण",
    "Circulating Supply": "परिसंचारी आपूर्ति",
    "Click here to": "यहाँ क्लिक करें",
    update: "अपडेट",
    "the token information / general information":
      "टोकन जानकारी / सामान्य जानकारी",
    Metadata: "मेटाडेटा",
    Missing: "गायब",
    Chain: "श्रृंखला",
    "Portfolio %": "पोर्टफोलियो %",
    Price: "मूल्य",
    Amount: "रकम",
    Showing: "दिखा रहा है",
    "tokens with a value of": "के मूल्य के साथ टोकन",
    Sponsored: "प्रायोजित",
    Complier: "कंपाइलर",
    "Token Name": "टोकन नाम",
    "Total Holders": "कुल धारकों",
    "Collection Name": "संग्रह का नाम",
    Validator: "मान्यकर्ता",
    in: "में",
    View: "देखें",
    "Latest Transactions": "नवीनतम लेनदेन",
    "Latest Blocks": "नवीनतम ब्लॉक",
    Customize: "कस्टमाइज़ करें",
    "NFT Address": "एनएफटी पता",
    "all Blocks": "सभी ब्लॉक",
    "Top Tokens": "शीर्ष टोकन",
    "Top Contracts": "शीर्ष अनुबंध",
    "Top NFTs": "शीर्ष एनएफटी",
    "Ask AI | Search by Address | Txn Hash | Block | Token... ":
      "पते / ट्रांजैक्शन हैश / ब्लॉक / टोकन द्वारा खोजें... ",
    "No transactions found.": "कोई लेन-देन नहीं मिली।",
    //401 started from jp
    "Market Cap": "मार्केट कैप",
    "MED GAS PRICE": "मेड गैस मूल्य",
    "TOTAL BLOCK": "कुल ब्लॉक",
    "GasTracker Estimate for Average Gas": "औसत गैस के लिए गैसट्रैकर अनुमान",
    "Total Accounts": "कुल खाते",
    "Daily Transactions": "दैनिक लेन-देन",
    "Contact Us": "संपर्क करें",
    "Feedback Form": "प्रतिक्रिया फ़ॉर्म",
    "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that":
      "कृपया समझें कि हम केवल ब्लॉक एक्सप्लोरर सेवाओं से संबंधित समस्याओं में सहायता कर सकते हैं। लिखने से पहले, कृपया जान लें कि",
    "Pending Transaction": "लंबित लेन-देन",
    "We do not": "हम नहीं करते हैं",
    "process transactions": "लेन-देन प्रक्रिया",
    "and are therefore unable to expedite, cancel or replace them. If you are having issues with pending transactions, please refer":
      "और इसलिए उन्हें त्वरित करने, रद्द करने या पुनः स्थानांतरित करने में असमर्थ हैं। यदि आपको लंबित लेन-देन की समस्या है, तो कृपया संदर्भ दें",
    here: "यहाँ",
    "Blocksscan is an independent": "ब्लॉकस्कैन एक स्वतंत्र",
    "block explorer": "ब्लॉक एक्सप्लोरर",
    "unrelated to other service providers (unless stated explicitly otherwise) and is therefore unable to provide a precise response for inquiries that are specific to other service providers.":
      "अन्य सेवा प्रदाताओं से संबंधित नहीं है (यह किसी अन्य विशेष रूप से उल्लेखित नहीं है) और इसलिए अन्य सेवा प्रदाताओं के लिए विशेष पूछताछ के लिए सटीक प्रतिक्रिया प्रदान करने में असमर्थ है।",
    "Knowledge Base": "ज्ञान आधार",
    "The links on the right might already have the answer to your inquiry. If they don't, perhaps one of the other articles in our":
      "दाईं ओर के लिंक में आपके प्रश्न का उत्तर हो सकता है। यदि नहीं हैं, तो हमारे अन्य लेखों में से कोई भी हो सकता है",
    "page does.": "पृष्ठ करता है।",
    "Community Support": "समुदाय समर्थन",
    "We will never ask for your private keys or passwords.":
      "हम कभी आपकी निजी कुंजी या पासवर्ड के लिए पूछेंगे नहीं।",
    "NEVER send us these.": "कभी इन्हें हमें न भेजें।",
    "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not":
      "हम उचित समय में प्रतिक्रिया देने के लिए पूरी कोशिश करते हैं, लेकिन अक्सर हमारे पास प्रश्नों की एक पिछली सूची होती है। कृपया इसे न करें",
    "spam us": "हमें स्पैम करें",
    "Wallet / Exchange / Project related issues":
      "वॉलेट / एक्सचेंज / परियोजना संबंधित मुद्दे",
    "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.":
      "आपके वॉलेट सेवा प्रदाता, एक्सचेंज या प्रोजेक्ट/अनुबंध स्वामी से आगे की समर्थन के लिए संपर्क करें क्योंकि वे अपने प्लेटफ़ॉर्म से संबंधित और इस परिस्थितियों पर आपकी मदद करने के लिए अधिक उपयुक्त हैं।",
    Subject: "विषय",
    "Please Select Your Message Subject": "कृपया अपना संदेश विषय चुनें",
    "General Inquiry": "सामान्य पूछताछ",
    Advertising: "विज्ञापन",
    "Update Token Info": "टोकन जानकारी अपडेट करें",
    "Add Name Tag/Label": "नाम टैग/लेबल जोड़ें",
    "Request Removal of Name Tag": "नाम टैग की हटाने का अनुरोध करें",
    "Suggest Transaction Action": "लेन-देन क्रिया का सुझाव दें",
    "Update Proxy Contract's Implementation Address":
      "प्रॉक्सी अनुबंध के कार्यान्वयन पते को अपडेट करें",
    "Report Phishing Address": "फिशिंग पते की रिपोर्ट करें",
    "Security Audit": "सुरक्षा मुआयना",
    "Priority Support": "प्राथमिकता समर्थन",
    "API Support": "API समर्थन",
    "1. Inquiries": "1. पूछताछ",
    "2. Submissions": "2. सबमिशन्स",
    "3. Security": "3. सुरक्षा",
    "Help & Support": "सहायता और समर्थन",
    "24/7 support. Our team is here to help you and make sure your product is up to date.":
      "24/7 समर्थन। हमारी टीम यहाँ है आपकी मदद के लिए और सुनिश्चित करने के लिए कि आपका उत्पाद अप-टू-डेट है।",
    Sales: "बिक्री",
    "Have a presale question about our products and features? Or looking for a refund?":
      "हमारे उत्पादों और सुविधाओं के बारे में प्री-सेल प्रश्न हैं? या वापसी के लिए देख रहे हैं?",
    "Media & Press": "मीडिया और प्रेस",
    "Online awards and publications. Get our online media resources and learn about our company.":
      "ऑनलाइन पुरस्कार और प्रकाशन। हमारे ऑनलाइन मीडिया संसाधन प्राप्त करें और हमारी कंपनी के बारे में जानें।",
    "Tx Hash|Block|Age": "ट्रांजैक्शन हैश|ब्लॉक|आयु",
    "12 days ago": "12 दिन पहले",
    Mint: "मिंट",
    Transaction: "लेन-देन",
    Success: "सफलता",
    Failed: "विफल",
    Pending: "लंबित",
    "Contract call": "अनुबंध कॉल",
    "Contract creation": "अनुबंध सृजन",
    "Token transfer": "टोकन स्थानांतरण",
    "Token creation": "टोकन सृजन",
    "Coin transfer": "सिक्का स्थानांतरण",
    REMASC: "एमी एमैनेजर स्मार्ट कंट्रैक्ट",
    Bridge: "पुल",
    Rank: "श्रेणी",
    Quantity: "मात्रा",
    Item: "आइटम",
    Details: "विवरण",
    ID: "आईडी",
    "Last Traded": "अंतिम व्यापार",
    "Estimated Value on Day of Txn": "ट्रांजैक्शन के दिन का अनुमानित मूल्य",
    "Displaying current Value; Click to show Value on Day of Txn":
      "वर्तमान मूल्य प्रदर्शित कर रहे हैं; ट्रांजैक्शन के दिन के मूल्य को दिखाने के लिए क्लिक करें",
    Action: "कार्रवाई",
    "Verify & Publish Contract Source Code":
      "अनुबंध स्रोत कोड को सत्यापित और प्रकाशित करें",
    "COMPILER TYPE AND VERSION SELECTION": "कॉम्पायलर प्रकार और संस्करण चयन",
    "Contract Licence": "अनुबंध लाइसेंस",
    "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.":
      "श्रेष्ठ अभ्यासों के लिए, सभी अनुबंध स्रोत कोड धारकों, प्रकाशकों और लेखकों को अपने सत्यापित अनुबंध स्रोत कोड के लिए भी संलग्न लाइसेंस को उल्लेखित करने की सलाह दी जाती है।",
    "Please select": "कृपया चुनें",
    "Verification Method": "सत्यापन विधि",
    "Compiler Type": "कॉम्पायलर प्रकार",
    "Enter name here...": "यहाँ नाम दर्ज करें...",
    "Is Yul Contract": "क्या यूल अनुबंध है",
    "The compiler version is specified in pragma solidity X.X.X. Use the compiler version rather than the nightly build. If using the Solidity compiler, run solc —version to check.":
      "कॉम्पायलर संस्करण pragma solidity X.X.X में निर्दिष्ट किया गया है। नाइटली बिल्ड की बजाय कॉम्पायलर संस्करण का उपयोग करें। Solidity कंपाइलर का उपयोग कर रहे हैं, solc —version चेक करने के लिए।",
    "The EVM version the contract is written for. If the bytecode does not match the version, we try to verify using the latest EVM version.":
      "कोन्ट्रैक्ट के लिए लिखे गए EVM संस्करण। यदि bytecode संस्करण से मेल नहीं खाता है, तो हम नवीनतम EVM संस्करण का उपयोग करके सत्यापन करने का प्रयास करते हैं।",
    "Type here ...": "यहां टाइप करें ...",
    "We recommend using flattened code. This is necessary if your code utilizes a library or inherits dependencies. Use the POA solidity flattener or the Truffle flattener.":
      "हम फ्लैटन कोड का उपयोग करने की सिफारिश करते हैं। यह आवश्यक है यदि आपका कोड एक पुस्तकालय का उपयोग करता है या निर्भरताओं को विरासत में लेता है। POA solidity flattener या Truffle flattener का उपयोग करें।",
    "Enter Source Code...": "स्रोत कोड दर्ज करें...",
    "Standard JSON Input": "मानक JSON इनपुट",
    "Sources and Metadata JSON": "स्रोत और मेटाडेटा JSON",
    "Sources *.sol or .yul files": ".sol या *.yul फ़ाइलें स्रोत",
    "Main Interfaces (.vy or .json)": "मुख्य इंटरफेस (.vy या .json)",
    "Upload the standard input JSON file created during contract compilation":
      "कॉन्ट्रैक्ट कंपाइलेशन के दौरान बनाई गई मानक इनपुट JSON फ़ाइल अपलोड करें",
    "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.":
      "सभी सॉलिडिटी कॉन्ट्रैक्ट स्रोत फ़ाइलें और कॉन्ट्रैक्ट कॉम्पाइलेशन के दौरान बनाई गई JSON मेटाडेटा फ़ाइलें अपलोड करें।",
    "Upload all Solidity or Yul contract source files":
      "सभी सॉलिडिटी या Yul कॉन्ट्रैक्ट स्रोत फ़ाइलें अपलोड करें",
    "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below":
      "मुख्य संकलित वायपर कॉन्ट्रैक्ट। यहां केवल मुख्य कॉन्ट्रैक्ट को जोड़ें जिसका bytecode डिप्लॉय किया गया है, नीचे इंटरफेस बॉक्स में अन्य सभी फ़ाइलें अपलोड की जा सकती हैं",
    "Drag & drop files here, or click to select files":
      "यहां फ़ाइलें खींचें और छोड़ें, या फ़ाइलें चुनने के लिए क्लिक करें",
    "Verify & Publish": "सत्यापित करें और प्रकाशित करें",
    Reset: "रीसेट",
    "Gas tracker": "गैस ट्रैकर",
    "Network utilization": "नेटवर्क उपयोग",
    "low load": "कम लोड",
    "Last updated": "अंतिम अपडेट",
    Fast: "तेज",
    Normal: "सामान्य",
    Slow: "धीमा",
    "Gas price history": "गैस मूल्य इतिहास",
    "Charts & stats": "चार्ट और आँकड़े",
    "Account Balance Checker": "खाता संतुलन चेकर",
    "You can Lookup the Account": "आप खाता खोज सकते हैं",
    "Historical Balance at a specific Block No or Date.":
      "किसी विशेष ब्लॉक नंबर या तिथि पर ऐतिहासिक संतुलन।",
    Account: "खाता",
    "Date for the snapshot": "स्नैपशॉट के लिए तिथि",
    Lookup: "लुकअप",
    "Choose desired language": "चाहिए भाषा चुनें",
    "Choose the theme": "थीम चुनें",
    "Dark Mode": "डार्क मोड",
    "Do you want to show relative time? eg.":
      "क्या आप संबंधित समय दिखाना चाहते हैं? जैसे।",
    "Do you want to show UTC time? eg.":
      "क्या आप यूटीसी समय दिखाना चाहते हैं? जैसे।",
    "Change Fiat Currency": "फिएट मुद्रा बदलें",
    Use: "उपयोग करें",
    "prefix instead of": "के बजाय उपसर्ग",
    "NFT Latest Mints": "NFT नवीनतम मिन्ट",
    "Token Id": "टोकन आईडी",
    "NFT Transfer": "NFT स्थानांतरण",
    "Your browser does not support the video tag.":
      "आपका ब्राउज़र वीडियो टैग का समर्थन नहीं करता है।",
    "Min Price": "न्यूनतम मूल्य",
    "Last Sale": "अंतिम बिक्री",
    "Current owner of this NFT.": "इस NFT का वर्तमान मालिक।",
    "Address of this NFT contract.": "इस NFT अनुबंध का पता।",
    "Address that deployed this NFT contract.":
      "इस NFT अनुबंध को डिप्लॉय करने वाला पता।",
    Creator: "निर्माता",
    "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.":
      "छवि को संचित करने के तरीके के आधार पर NFT का वर्गीकरण: ऑन-चेन उन्हें संकलित करने के लिए है जिनमें डेटा ब्लॉकचेन पर संग्रहीत है जबकि ऑफ-चेन उन्हें संग्रहीत है जिनका डेटा इंटरनेट पर कहीं और है।",
    Classification: "वर्गीकरण",
    "This NFT's unique token ID.": "इस NFT की अद्वितीय टोकन आईडी।",
    "The standard followed by this NFT.": "इस NFT द्वारा पालन किया गया मानक।",
    "Token Standard": "टोकन मानक",
    "Marketplaces trading this NFT.": "इस NFT को व्यापार करने वाले बाजार।",
    Marketplaces: "बाजार",
    Properties: "गुण",
    Description: "विवरण",
    Collection: "संग्रह",
    Volume: "मात्रा",
    Change: "परिवर्तन",
    "Max Price": "अधिकतम मूल्य",
    "NFT Top Mints": "NFT शीर्ष मिन्ट्स",
    Owners: "मालिक",
    "Total Assets": "कुल संपत्तियाँ",
    "Note: Filtered by totalSupply() , method returned a zero value.":
      "ध्यान दें: totalSupply() द्वारा फ़िल्टर किया गया, विधि ने एक शून्य मान लौटाया।",
    "A Paid Service": "एक भुगतान सेवा",
    "At BlocksScan, we handle a substantial volume of support requests daily. While we strive our best to respond to each one in a timely manner, we recognize that certain projects may want to submit their token information quicker than our standard turnaround time.":
      "BlocksScan में, हम रोजाना एक ठोस मात्रा में समर्थन अनुरोधों को संभालते हैं। हम प्रत्येक का समयपरक रूप से प्रतिक्रिया देने का प्रयास करते हैं, हालांकि हम स्वीकार करते हैं कि कुछ प्रोजेक्ट अपनी टोकन जानकारी को हमारे मानक उलटी समय से भी तेजी से सबमिट करना चाहते हैं।",
    "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.":
      "टोकन अपडेट को तेजी से करने के अलावा, हम अन्य अत्यावश्यक या तकनीकी मामलों के लिए भी प्राथमिकता समर्थन प्रदान करते हैं, जैसे कि अनुबंध सत्यापन।",
    "Kindly follow the simple steps below to get your support prioritized.":
      "कृपया नीचे दिए गए सरल चरणों का पालन करें ताकि आपका समर्थन प्राथमिकता दिया जा सके।",
    "Get Started": "शुरुआत करें",
    "How to Start": "कैसे शुरू करें",
    "Simple steps for getting Priority Support.":
      "प्राथमिकता समर्थन प्राप्त करने के लिए सरल चरण।",
    "Step 1": "चरण 1",
    "Check Token Information": "टोकन जानकारी की जाँच करें",
    "Ensure that token information is submitted. Check our token update guidelines":
      "सुनिश्चित करें कि टोकन जानकारी प्रस्तुत की गई है। हमारी टोकन अपडेट दिशा-निर्देशों की जाँच करें",
    "Step 2": "चरण 2",
    "Provide Token Update Request": "टोकन अपडेट अनुरोध प्रदान करें",
    "Provide us with the token update request information in the contact form below.":
      "नीचे के संपर्क फ़ॉर्म में टोकन अपडेट अनुरोध जानकारी प्रदान करें।",
    "Step 3": "चरण 3",
    "We'll Review Your Request": "हम आपके अनुरोध की समीक्षा करेंगे",
    "Our team will review your request and contact you using an official":
      "हमारी टीम आपके अनुरोध की समीक्षा करेगी और आपसे आधिकारिक रूप से संपर्क करेगी",
    "Please use this form to get in touch for priority support or featured listing with our team.":
      "कृपया इस फ़ॉर्म का उपयोग करें ताकि हमारे टीम के साथ प्राथमिकता समर्थन या विशेष लिस्टिंग के लिए संपर्क में रहें।",
    "Requester Name": "अनुरोधक का नाम",
    "Requester Email": "अनुरोधक का ईमेल",
    "Token Contract Address": "टोकन अनुबंध पता",
    "Choose Priority Service": "प्राथमिकता सेवा चुनें",
    "Token Update": "टोकन अपडेट",
    "Featured Listing": "विशेष सूची",
    "Contract Verification": "अनुबंध सत्यापन",
    Others: "अन्य",
    "Learn more about": "अधिक जानें",
    "Helpdesk Ticket Number": "हेल्पडेस्क टिकट नंबर",
    "Additional Notes": "अतिरिक्त नोट",
    Note: "ध्यान दें",
    "We will": "हम",
    NOT: "नहीं",
    "request payment via direct deposit. All payments will be directed through our official payment channel.":
      "सीधे जमा के माध्यम से भुगतान का अनुरोध करेंगे। सभी भुगतान हमारे आधिकारिक भुगतान चैनल के माध्यम से किया जाएगा।",
    NEVER: "कभी नहीं",
    "ask for your private key or seed words. Please":
      "आपकी निजी कुंजी या बीज शब्द मांगेंगे। कृपया",
    "DO NOT SHARE": "साझा न करें",
    "your private keys with anyone.":
      "अपनी निजी कुंजियों को किसी के साथ साझा न करें।",
    "Send Message": "संदेश भेजें",
    "Ex. John Doe": "उदा - जॉन डो",
    "If you have any additional notes, please leave them here":
      "यदि आपके पास कोई अतिरिक्त नोट हैं, तो कृपया उन्हें यहां छोड़ें",
    "Privacy Policy": "गोपनीयता नीति",
    "Welcome to Blocksscan Explorer Services":
      "Blocksscan एक्सप्लोरर सेवाओं में आपका स्वागत है",
    we: "हम",
    us: "हमें",
    our: "हमारा",
    "We are committed to respecting the privacy and security of our users' information. This Privacy Policy outlines the types of information we may collect from you or that you may provide when you use our services, and our practices for collecting, using, maintaining, protecting, and disclosing that information.":
      "हम हमारे उपयोगकर्ताओं की जानकारी के गोपनीयता और सुरक्षा का सम्मान करने के प्रति प्रतिबद्ध हैं। इस गोपनीयता नीति में हम उन जानकारियों के प्रकारों की संक्षेपित सूची देते हैं जो हम आपसे या जो आप हमारी सेवाओं का उपयोग करते समय प्रदान कर सकते हैं, और हमारे जीवनशैली के लिए जो भाग उन जानकारियों को एकत्र करने, उपयोग करने, बनाए रखने, संरक्षित करने और खुलासा करने के लिए हमारी प्रथाओं को संकेत करती है।",
    "Information We Collect": "हमारे द्वारा जुटाई गई जानकारी",
    "We collect several types of information from and about users of our Blocksscan Explorer Services, including":
      "हमारे Blocksscan Explorer सेवाओं के उपयोगकर्ताओं से और उनके बारे में हम कई प्रकार की जानकारी जुटाते हैं, जिनमें",
    "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.":
      "व्यक्तिगत जानकारी: आपकी पहचान करने वाला डेटा, जैसे कि आपका नाम, ईमेल पता, और पंजीकरण के समय या हमारी सेवाओं के उपयोग के दौरान प्रदान की गई भुगतान विवरण।",
    "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.":
      "गैर-व्यक्तिगत जानकारी: जो आपकी पहचान नहीं करती है, लेकिन आपकी हमारी सेवाओं के उपयोग से जुड़ी होती है, जैसे कि आईपी पते, ब्राउज़र प्रकार, और पहुँच समय।",
    "Transactional Information: Data related to transactions conducted on the network, which may include public blockchain data.":
      "लेन-देन संबंधित जानकारी: नेटवर्क पर किए गए लेन-देन से संबंधित डेटा, जिसमें सार्वजनिक ब्लॉकचेन डेटा भी शामिल हो सकता है।",
    "How We Use Your Information": "आपकी जानकारी का उपयोग कैसे करते हैं",
    "We use the information that we collect about you or that you provide to us, including any personal information":
      "हम आपके बारे में जो जानकारी जुटाते हैं या जो आप हमें प्रदान करते हैं, इसमें किसी भी व्यक्तिगत जानकारी शामिल है",
    "To present our services and its contents to you.":
      "हमारी सेवाओं और उसकी सामग्री को आपके सामने प्रस्तुत करने के लिए।",
    "To provide you with information, products, or services that you request from us.":
      "आपको हमसे मांगी गई जानकारी, उत्पाद, या सेवाएं प्रदान करने के लिए।",
    "To fulfill any other purpose for which you provide it.":
      "जिस अन्य उद्देश्य के लिए आप इसे प्रदान करते हैं, उसे पूरा करने के लिए।",
    "To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.":
      "जो कार्य हमें आपके और हमारे बीच के किए गए कोई भी अनुबंध से उत्पन्न होने वाले अपने कर्तव्यों को निष्पादित करने और हमारे अधिकारों को प्रभावी करने के लिए।",
    "To notify you about changes to our service or any products or services we offer or provide through it.":
      "हमारी सेवा में परिवर्तनों के बारे में आपको सूचित करने के लिए। या उसके माध्यम से हम कोई भी उत्पाद या सेवाएं प्रदान करते हैं जो हम या हम उसके माध्यम से प्रदान करते हैं।",
    "For any other purpose with your consent.":
      "आपकी सहमति के साथ किसी भी अन्य उद्देश्य के लिए।",
    "Data Security": "डेटा सुरक्षा",
    "Data Retention": "डेटा रिटेंशन",
    "We will retain your personal information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.":
      "हम आपकी व्यक्तिगत जानकारी को तब तक रखेंगे जब तक आपका खाता सक्रिय है या जब आपको सेवाएं प्रदान करने की आवश्यकता हो। हम आपकी जानकारी को हमारे कानूनी दायित्वों को पूरा करने, विवादों को सुलझाने और हमारे समझौतों को प्रभावी बनाने के लिए आवश्यक रूप से रखेंगे और उपयोग करेंगे।",
    "Your Rights": "आपके अधिकार",
    "You have the right to request access to, correct, or delete any personal information that you have provided to us. You may also object to or request restrictions on the processing of your personal information.":
      "आपके पास हमें दी गई किसी भी व्यक्तिगत जानकारी का उपयोग तक पहुँच, सही करने, या हटाने का अधिकार है। आप अपनी व्यक्तिगत जानकारी की प्रोसेसिंग पर विरोध कर सकते हैं या बाधाएँ लगा सकते हैं।",
    "Changes to Our Privacy Policy": "हमारी गोपनीयता नीति में परिवर्तन",
    "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.":
      "हमारा नीति है कि हम हमारी गोपनीयता नीति में जो भी परिवर्तन करते हैं, उन्हें इस पृष्ठ पर पोस्ट करें। गोपनीयता नीति की अंतिम संशोधन तिथि पृष्ठ के शीर्ष पर पहचानी गई है। आपको यह सुनिश्चित करने की जिम्मेदारी है कि हमारे पास आपके लिए एक नवीनतम, सक्रिय और प्राप्तियों के लिए ईमेल पता है और नियमित रूप से हमारी वेबसाइट और इस गोपनीयता नीति को जांचने के लिए हमारे वेबसाइट और इस गोपनीयता नीति पर यात्रा करने की जिम्मेदारी है।",
    "Contact Information": "संपर्क जानकारी",
    "To ask questions or comment about this privacy policy and our privacy practices, contact us at":
      "इस गोपनीयता नीति और हमारे गोपनीयता अभ्यासों के बारे में प्रश्न पूछने या टिप्पणी करने के लिए, हमसे संपर्क करें:",
    "By using Blocksscan Explorer Services, you consent to our Privacy Policy.":
      "ब्लॉक्सस्कैन एक्सप्लोरर सेवाओं का उपयोग करके, आप हमारी गोपनीयता नीति को स्वीकार करते हैं।",
    "Max Total Supply": "अधिकतम कुल आपूर्ति",
    "Note: Filtered by totalSupply() , method returned a zero value":
      "ध्यान दें: totalSupply() द्वारा फ़िल्टर किया गया, विधि ने एक शून्य मूल्य लौटाया।",
    "Total Transfers": "कुल स्थानांतरण",
    Implementation: "कार्यान्वयन",
    "Fully Diluted Market Cap": "पूरी तरह से पतला बाजार अधिरक्ति",
    "Circulating Supply Market Cap": "चालू आपूर्ति बाजार कैप",
    "Other Info": "अन्य जानकारी",
    "Token Contract": "टोकन अनुबंध",
    "FILTERED BY TOKEN HOLDER": "टोकन धारक द्वारा फ़िल्टर",
    BALANCE: "संतुलन",
    Inventory: "इन्वेंट्री",
    Info: "जानकारी",
    With: "साथ",
    decimals: "दशमलव",
    "Volume 24h": "24 घंटे की आवाज़",
    "Circulating Market Cap": "सर्कुलेटिंग मार्केट कैप",
    "Onchain Market Cap": "ऑनचेन मार्केट कैप",
    "Token Transactions": "टोकन लेन-देन",
    "X (Twitter)": "X (ट्विटर)",
    Github: "गिटहब",
    Facebook: "फेसबुक",
    Instagram: "इंस्टाग्राम",
    "Back to Top": "वापस शीर्ष पर",
    "Powered by BlocksScan": `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} द्वारा संचालित`,
    "BlocksScan is a Block Explorer and Analytics Platform for":
      `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} ब्लॉक एक्सप्लोरर और विश्लेषण प्लेटफ़ॉर्म है जो कि`,
    "a decentralized smart contracts platform.":
      "एक केंद्रहीन स्मार्ट कॉन्ट्रैक्ट्स प्लेटफ़ॉर्म है।",
    Company: "कंपनी",
    "About Us": "हमारे बारे में",
    "Brand Assets": "ब्रांड संपत्तियाँ",
    Careers: "करियर",
    "We're Hiring": "हम भर्ती कर रहे हैं",
    Terms: "शर्तें",
    Privacy: "गोपनीयता",
    Community: "समुदाय",
    "API Documentation": "एपीआई दस्तावेज़ीकरण",
    "Network Status": "नेटवर्क स्थिति",
    Newsletters: "समाचार पत्रिकाएँ",
    Products: "उत्पाद",
    Services: "सेवाएँ",
    "Explorer-as-a-Service": "एक्सप्लोरर-जैसे-सेवा",
    "API Plans": "एपीआई योजनाएँ",
    "All Rights Reserved": "सभी अधिकार सुरक्षित हैं",
    Donations: "दान",
    Home: "होम",
    CodeRunAI: "कोड रन ए.आई.",
    Blockchain: "ब्लॉकचेन",
    Live: "लाइव",
    WIP: "काम प्रगति में",
    "Contract Internal Transactions": "अन्तर्निहित लेन-देन करना",
    "View Blocks": "ब्लॉक देखें",
    "Top Accounts": "शीर्ष खाते",
    "Top Mints": "शीर्ष मिन्ट",
    "Latest Trades": "नवीनतम व्यापार",
    "Latest Transfers": "नवीनतम स्थानांतरण",
    "Latest Mints": "नवीनतम मिन्ट",
    "Charts And Stats": "चार्ट और सांख्यिकी",
    "Top Statistics": "शीर्ष सांख्यिकी",
    Leaderboard: "लीडरबोर्ड",
    Directory: "निर्देशिका",
    Developers: "डेवलपर्स",
    "Verify Contract": "अनुबंध सत्यापित करें",
    "Similar Contract Search": "समान अनुबंध खोज",
    "Smart Contract Search": "स्मार्ट अनुबंध खोज",
    "Contract Diff Checker": "अनुबंध अंतर जाँचक",
    "Vyper Online Compiler": "वायपर ऑनलाइन कंपाइलर",
    "Bytecode to Opcode": "बाइटकोड से ओपकोड तक",
    "Broadcast Transaction": "ट्रांज़ैक्शन प्रसारित करें",
    More: "अधिक",
    "Balance Checker": "संतुलन चेकर",
    "Gas Tracker": "गैस ट्रैकर",
    Light: "हल्का",
    Dim: "कम रोशनी",
    Dark: "अंधेरा",
    Gas: "गैस",
    "Gas tracker overview": "गैस ट्रैकर अवलोकन",
    "All Transactions": "सभी लेनदेन",
    "Custom Card": "कस्टम कार्ड",
    "Customize this card by selecting one of the options below":
      "नीचे दिए गए विकल्पों का चयन करके यह कार्ड कस्टम करें",
    "Advanced Filters": "उन्नत फिल्टर",
    Present: "हाल",
    "Saved Filters": "सुरक्षित फिल्टर",
    Close: "बंद करें",
    "Save Changes": "परिवर्तनों को सुरक्षित करें",
    "Top NFT": "शीर्ष NFT",
    "Top Contract": "शीर्ष अनुबंध",
    "Top Token": "शीर्ष टोकन",
    "Latest Transaction": "नवीनतम लेनदेन",
  },
};
