export const ua = {
  translations: {
    "ABI for the implementation contract at": "ABI для контракту виконання на",
    "ABI-encoded": "ABI-кодований",
    "API Documentation": "Документація API",
    "API Plans": "Плани API",
    "API Support": "Підтримка API",
    "About Us": "Про нас",
    "Account": "Обліковий запис",
    "Account Balance Checker": "Перевірка балансу рахунку",
    "Action": "Дія",
    "Add Name Tag/Label": "Додати мітку/позначку імені",
    "Add one more": "Додати ще один",
    "Additional Info": "Додаткова інформація",
    "Additional Notes": "Додаткові нотатки",
    "Address": "Адреса",
    "Address of this NFT contract.": "Адреса цього NFT-контракту.",
    "Address that deployed this NFT contract.": "Адреса, на яку розгорнуто цей NFT-контракт.",
    "Advertising": "Реклама",
    "Age": "Вік",
    "All Rights Reserved": "Всі права захищені",
    "All Transactions": "Всі транзакції",
    "Amount": "Сума",
    "Analytics": "Аналітика",
    "Annual Trips": "Щорічні поїздки",
    "Are you the contract creator": "Ви є творцем контракту",
    "Back to Top": "Назад до початку",
    "Balance": "Баланс",
    "Balance Checker": "Перевірка балансу",
    "Base": "Базовий",
    "Base Fee": "Базова комісія",
    "Base Fee Per Gas": "Базова комісія за газ",
    "Best Coffee in Town": "Найкраща кава у місті",
    "Block": "Блок",
    "Block Height": "Висота блоку",
    "Block Number": "Номер блоку",
    "Block Reward": "Винагорода за блок",
    "Block header withdrawal root hash.": "Хеш кореня виводу заголовка блоку.",
    "Block nonce is a value used during mining to demonstrate proof of work for a block.": "Block nonce - це значення, яке використовується під час видобутку для демонстрації доказу роботи для блоку.",
    "Block proposed on epoch": "Блок, запропонований на епоху",
    "Blocksscan is an independent": "Blocksscan є незалежним",
    "Blockchain": "Блокчейн",
    "Blocks": "Блоки",
    "Broadcast Transaction": "Трансляція транзакції",
    "Brand Assets": "Брендові активи",
    "Bridge": "Міст",
    "Burnt": "Випалений",
    "Burnt & Txn Savings Fees": "Випалений та комісія за збереження транзакції",
    "Byte Code": "Байт-код",
    "Bytecode to Opcode": "Байт-код на Опкод",
    "By using Blocksscan Explorer Services, you consent to our Privacy Policy.": "Використовуючи послуги Blocksscan Explorer, ви погоджуєтеся з нашою Політикою конфіденційності.",
    "CSV Export": "Експорт CSV",
    "Call": "Виклик",
    "Capacity": "Місткість",
    "Careers": "Кар'єра",
    "Careers at BlocksScan": "Кар'єра в BlocksScan",
    "Change Fiat Currency": "Змінити фіатну валюту",
    "Change": "Змінити",
    "Charts & stats": "Графіки та статистика",
    "Charts And Stats": "Графіки та статистика",
    "Choose Priority Service": "Виберіть пріоритетну службу",
    "Choose desired language": "Виберіть потрібну мову",
    "Choose the theme": "Виберіть тему",
    "Circulating Market Cap": "Обіговий ринковий капітал",
    "Circulating Supply": "Обігова пропозиція",
    "Circulating Supply Market Cap": "Обіговий ринковий капітал",
    "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.": "Класифікація NFT на основі того, як зображення зберігається: On-Chain відноситься до тих, дані яких зберігаються на блокчейні, тоді як Off-Chain відноситься до тих, дані яких зберігаються в іншому місці в Інтернеті.",
    "Classification": "Класифікація",
    "Click here to": "Натисніть тут, щоб",
    "Click to show less": "Клацніть, щоб показати менше",
    "Click to show more": "Клацніть, щоб показати більше",
    "Close": "Закрити",
    "Code": "Код",
    "Code Reader": "Читач коду",
    "CodeRunAI": "CodeRunAI",

    "Coin transfer": "Передача монет",
    "Collater­alization": "Забезпечення",
    "Collection": "Колекція",
    "Collection Name": "Назва колекції",
    "Commute Allowance": "Дозвіл на поїздку",
    "Community": "Спільнота",
    "Community Support": "Підтримка спільноти",
    "Company": "Компанія",
    "Compiler": "Компілятор",
    "Compiler Type": "Тип компілятора",
    "Compiler Version": "Версія компілятора",
    "COMPILER TYPE AND VERSION SELECTION": "Вибір типу та версії компілятора",
    "ConfirmationToken": "Підтвердження",
    "Confirmation": "Підтвердження",
    "Confirmed within": "Підтверджено протягом",
    "Confirming": "Підтвердження",
    "Contact Us": "Зв'яжіться з нами",
    "Contact Information": "Контактна інформація",
    "Contract": "Контракт",
    "Contract Address": "Адреса контракту",
    "Contract Code": "Код контракту",
    "Contract Diff Checker": "Перевірка відмінностей контракту",
    "Contract File Path": "Шлях до файлу контракту",
    "Contract Internal Transactions": "Внутрішні транзакції контракту",
    "Contract Licence": "Ліцензія контракту",
    "Contract Name": "Назва контракту",
    "Contract Source Code Verified": "Перевірений вихідний код контракту",
    "Contract Verification": "Перевірка контракту",
    "Contract call": "Виклик контракту",
    "Contract creation": "Створення контракту",
    "Contracts": "Контракти",
    "Contracts Internal": "Внутрішні контракти",
    "Cost per unit of gas spent for the transaction, in Ether and Gwei.": "Вартість одиниці газу, витраченого на транзакцію, у ефірі та гвей.",
    "Creator": "Творець",
    "Created": "Створено",
    "Current owner of this NFT.": "Поточний власник цього NFT.",
    "Custom Card": "Спеціальна картка",
    "Customize": "Налаштувати",
    "Customize this card by selecting one of the options below": "Налаштуйте цю картку, вибравши один із варіантів нижче",
    "Daily Transactions": "Щоденні транзакції",
    "Dark": "Темний",
    "Dark Mode": "Темний режим",
    "Data": "Дані",
    "Data Security": "Безпека даних",
    "Date for the snapshot": "Дата знімку",
    "Decode input data": "Декодувати вхідні дані",
    "Details": "Деталі",
    "Development": "Розробка",
    "Dim": "Затемнити",
    "Directory": "Каталог",
    "Display All Chains": "Відображення всіх ланцюгів",
    "Displaying current Value; Click to show Value on Day of Txn": "Відображення поточної вартості; Натисніть, щоб показати вартість на день транзакції",
    "Donations": "Пожертви",
    "Drag & drop files here, or click to select files": "Перетягніть файли сюди або натисніть, щоб вибрати файли",
    "Download": "Завантажити",
    "Downloaded": "Завантажено",
    "EVM Version": "Версія EVM",
    "Encode": "Кодувати",
    "Encoded": "Закодовано",
    "Encoding": "Кодування",
    "Enter Source Code...": "Введіть вихідний код...",
    "Enter name here...": "Введіть ім'я тут...",
    "Ensure that token information is submitted. Check our token update guidelines": "Переконайтеся, що інформація про токен надіслана. Перевірте наші вказівки щодо оновлення токенів",
    "Event Logs": "Журнали подій",
    "Events": "Події",
    "Ex. John Doe": "Наприклад, Джон Доу",
    "Example questions": "Приклади питань",
    "Explorer-as-a-Service": "Експлорер-як-сервіс",
    "Export": "Експорт",
    "Extra Data": "Додаткові дані",
    "Extra lunch money provided along with the occasional team lunch and dinners.": "Додаткові кошти на обід разом із випадковими обідами та вечерями для команди.",
    "FAQ": "Часті запитання",
    "Failed": "Не вдалося",
    "Fast": "Швидко",
    "Fee": "Комісія",
    "Fee Recipient": "Отримувач комісії",
    "Feedback Form": "Форма зворотного зв'язку",
    "File": "Файл",
    "Files": "Файли",
    "Filter": "Фільтр",
    "Filters": "Фільтри",
    "Filtered": "Відфільтровано",
    "Finalized": "Фіналізовано",
    "Flex Benefits": "Гнучкі переваги",
    "Flexible working hours": "Гнучкий робочий графік",
    "Food Allowance": "Допомога на їжу",
    "For": "Для",
    "For almost anything that improves your life - training courses, fitness plans... even beauty facials!": "Для майже будь-чого, що поліпшує ваше життя - навчальні курси, фітнес-плани ... навіть косметичні процедури!",
    "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.": "Для кращих практик всі власники вихідного коду контракту, видавці та автори також закликаються вказати супровідну ліцензію для наданого їх перевіреного вихідного коду контракту.",
    "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.": "Для кожного блоку виробник блоку винагороджується скінченною кількістю етеру плюс плати за всі транзакції у блоку.",
    "Forums": "Форуми",
    "Free Flow Drinks & Snacks": "Безкоштовні напої та закуски",
    "From": "Від",
    "Fully Diluted Market Cap": "Повна ринкова капіталізація",
    "Functionality": "Функціональність",
    "Functions": "Функції",
    "Gas": "Газ",
    "Gas Fees": "Плата за газ",
    "Gas Limit": "Обмеження газу",
    "Gas Limit & Usage by Txn": "Обмеження газу та використання за Txn",
    "Gas Price": "Ціна на газ",
    "Gas Target": "Цільовий газ",
    "Gas Tracker": "Трекер газу",
    "Gas Used": "Використаний газ",
    "Gas price history": "Історія ціни на газ",
    "Gas tracker": "Трекер газу",
    "Gas tracker overview": "Огляд трекера газу",
    "General Inquiry": "Загальний запит",
    "Github": "Github",
    "Get Started": "Початок роботи",
    "Help & Support": "Допомога та підтримка",
    "Helpdesk Ticket Number": "Номер квитка служби підтримки",
    "Hex": "Шістнадцяткове",
    "Hide All Chains": "Приховати всі ланцюги",
    "Hide Chains": "Приховати ланцюги",
    "Highlighted events of the transaction.": "Виділені події транзакції.",
    "Historical Balance at a specific Block No or Date.": "Історичний баланс на певному номері блоку або даті.",
    "Home": "Домашня",
    "How We Use Your Information": "Як ми використовуємо вашу інформацію",
    "How to Start": "Як почати",
    "I can help you in the following scenarios": "Я можу допомогти вам у наступних сценаріях",
    "I don't have an API Key": "У мене немає ключа API",
    "I have an API Key": "У мене є ключ API",
    "ID": "Ідентифікатор",
    "If using the Solidity compiler, run solc —version to check.": "Якщо використовуєте компілятор Solidity, запустіть solc —version, щоб перевірити.",
    "If you have any additional notes, please leave them here": "Якщо у вас є які-небудь додаткові зауваження, залиште їх тут",
    "Implementation": "Впровадження",
    "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.": "Крім прискорення оновлень токенів, ми також надаємо пріоритетну підтримку для інших невідкладних або технічних питань, таких як перевірка контрактів.",
    "Indexed": "Індексований",
    "Information We Collect": "Інформація, яку ми збираємо",
    "Input Data": "Вхідні дані",
    "Instagram": "Instagram",
    "Internal Transactions": "Внутрішні транзакції",
    "Internal transaction is transactions as a result of contract execution that involves Ether value.": "Внутрішня транзакція - це транзакції внаслідок виконання контракту, що містить значення етеру.",
    "Is Yul Contract": "Чи є контракт Yul",
    "Item": "Пункт",
  "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.": "Нашою політикою є розміщення будь-яких змін, які ми робимо у нашій політиці конфіденційності на цій сторінці. Дата останнього перегляду політики конфіденційності вказана у верхній частині сторінки. Ви несете відповідальність за те, щоб ми мали актуальну, активну та доставної електронної адреси для вас і періодично відвідувати наш веб-сайт і цю політику конфіденційності, щоб перевірити наявність змін.",
  "Join BlocksScan and enhance the world's leading blockchain explorer and search, API & analytics platform.": "Приєднуйтесь до BlocksScan та покращуйте провідний у світі блокчейн-експлорер та пошук, API та аналітичну платформу.",
  "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.": "Стежте за останніми досягненнями та кращими практиками у галузі разом з досвідченими та досвідченими членами команди.",
  "Kindly follow the simple steps below to get your support prioritized.": "Люб'язно слідуйте простим крокам нижче, щоб отримати пріоритетну підтримку.",
  "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.": "Люб'язно зверніться до постачальника послуг гаманця, обмінів або власника проекту / контракту за додатковою підтримкою, оскільки вони знаходяться в кращому положенні, щоб допомогти вам з питань, пов'язаних з їхніми платформами.",
  "Knowledge Base": "База знань",
  "Last Sale": "Остання продаж",
  "Last Traded": "Останній торговий",
  "Last Updated": "Останнє оновлення",
  "Last updated": "Останнє оновлення",
  "Latest Block": "Останній блок",
  "Latest Blocks": "Останні блоки",
  "Latest Mints": "Останні монети",
  "Latest Signed Block": "Останній підписаний блок",
  "Latest Transaction": "Остання транзакція",
  "Latest Transactions": "Останні транзакції",
  "Latest Trades": "Останні угоди",
  "Latest Transfers": "Останні перекази",
  "Learn more about": "Дізнатися більше про",
  "Learning Sessions": "Сесії навчання",
  "Legacy": "Спадщина",
  "License": "Ліцензія",
  "Light": "Світло",
  "List of": "Список",
  "List of {{_tokenType}}20 tokens transferred in the transaction.": "Список токенів {{_tokenType}}20, переданих у транзакції.",
  "List of tokens burnt transferred in the transaction.": "Список токенів, які спалено у транзакції.",
  "List of tokens mint transferred in the transaction.": "Список токенів, які монетизовано у транзакції.",
  "Load": "Завантажити",
  "Loading": "Завантаження",
  "Loading...": "Завантаження...",
  "Logdata to clipboard": "Дані журналу в буфер обміну",
  "Logs": "Журнали",
  "Look up": "Пошук",
  "Lookup": "Пошук",
  "Low load": "Низьке навантаження",
  "MED GAS PRICE": "Середня вартість газу",
  "Main Interfaces (.vy or .json)": "Основні інтерфейси (.vy або .json)",
  "Market": "Ринок",
  "Market Cap": "Капіталізація ринку",
  "Market Capitalization": "Капіталізація ринку",
  "Marketplaces": "Ринки",
  "Marketplaces trading this NFT.": "Ринки, на яких торгують цим NFT.",
  "Max": "Макс",
  "Max Priority": "Максимальний пріоритет",
  "Max Price": "Максимальна ціна",
  "Max Total Supply": "Максимальний загальний обсяг",
  "Media & Press": "Медіа та преса",
  "Medical Benefits": "Медичні переваги",
  "Method ID": "Ідентифікатор методу",
  "Method Name": "Ім'я методу",
    "Method": "Метод",
    "Method id": "Ідентифікатор методу",
    "Metadata": "Метадані",
    "Min Price": "Мінімальна ціна",
    "Missing": "Відсутній",
    "Mode": "Режим",
    "More Details": "Більше деталей",
    "More Info": "Додаткова інформація",
    "Multichain Info": "Інформація про багатоланцюговість",
    "Multichain Portfolio": "Портфель багатоланцюговості",
    "Name": "Назва",
    "Name Tag": "Тег імені",
    "NEVER": "Ніколи",
    "NEVER send us these.": "Ніколи не надсилайте нам це.",
    "NEW": "Новий",
    "NFT Address": "Адреса NFT",
    "NFT Latest Mints": "Останні відтискання NFT",
    "NFT Top Mints": "Топ відтискання NFT",
    "NFT Transfer": "Передача NFT",
    "NFT Transfers": "Передачі NFT",
    "NFT's unique token ID.": "Унікальний ідентифікатор токена NFT.",
    "NFT": "NFT",
    "Node": "Вузол",
    "Nonce": "Нонс",
    "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.": "Неособиста інформація: дані, які не ідентифікують вас особисто, але пов'язані з вашим використанням наших послуг, такі як IP-адреси, типи браузерів та час доступу.",
    "Normal": "Нормальний",
    "Note: Filtered by  totalSupply() , method returned a zero value.": "Примітка: відфільтровано за  totalSupply() , метод повернув нульове значення.",
    "Note": "Примітка",
    "NOT": "Не",
    "No Record Found": "Записів не знайдено",
    "No transactions found.": "Транзакцій не знайдено.",
    "Number": "Число",
    "Number of beacon withdrawals in this block.": "Кількість виведень маяка в цьому блоку.",
    "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.": "Номер блоку, в якому зареєстрована транзакція. Підтвердження блоків показують, скільки блоків було додано з моменту створення транзакції.",
    "OAuth": "OAuth",
    "Office Entertainment": "Офісний розваги",
    "Onchain Market Cap": "Капіталізація ринку на ланцюжку",
    "OpenAI API key": "Ключ API OpenAI",
    "Optimization": "Оптимізація",
    "Optimization Enabled": "Оптимізація увімкнена",
    "Optimization Runs": "Запуски оптимізації",
    "Order": "Порядок",
    "Others": "Інші",
    "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.": "Наше бачення - це не лише велика мета. Ми підтримуємо його компанією та культурою, створеною для його реалізації.",
    "Our team will review your request and contact you using an official": "Наша команда розгляне ваш запит та зв'яжеться з вами за допомогою офіційного",
    "Overview": "Огляд",
    "Owner": "Власник",
    "Owners": "Власники",
    "Page": "Сторінка",
    "Parent Hash": "Батьківський хеш",
    "Pending Transaction": "Очікується транзакція",
    "Pending Transactions": "Очікуються транзакції",
    "Pending...": "Очікується...",
    "Percentage": "Відсоток",
    "Perks and Benefits": "Бонуси та переваги",
    "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.": "Особиста інформація: дані, які особисто вас ідентифікують, такі як ваше ім'я, адреса електронної пошти та реквізити платежу, надані під час реєстрації або під час використання наших послуг.",
    "Phone": "Телефон",
    "Platform": "Платформа",
    "Please Select Your Message Subject": "Будь ласка, виберіть тему вашого повідомлення",
    "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that": "Будь ласка, зрозумійте, що ми можемо надавати допомогу лише з питань, що безпосередньо стосуються послуг дослідження блоків. Перш ніж писати, будь ласка, будьте інформовані про те, що",
    "Please use this form to get in touch for priority support or featured listing with our team.": "Будь ласка, використовуйте цю форму, щоб зв'язатися для отримання пріоритетної підтримки або відображення у вибраному списку з нашою командою.",
    "Position": "Позиція",
    "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.": "Після оновлення London, це представляє мінімальний множник використаного газу, необхідний для включення транзакції в блок.",
    "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.": "Після оновлення London, це представляє частку комісії за транзакцію, яка спалюється: baseFeePerGas * gasUsed.",
    "Power": "Потужність",
    "Powered by BlocksScan": `Працює на ${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"}`,
    "BlocksScan is a Block Explorer and Analytics Platform for": `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} - це блок-дослідник та аналітична платформа для`,
    "Present": "Наявний",
    "Preserve": "Зберегти",
    "Press": "Натискати",
    "Price": "Ціна",
    "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below": "Основний скомпільований контракт Vyper. Тут додавайте лише основний контракт, код байткоду якого був розгорнутий, всі інші файли можна завантажити в інтерфейсний блок нижче.",
    "Privacy": "Конфіденційність",
    "Privacy Policy": "Політика конфіденційності",
    "Private Name Tags": "Приватні ім'яні теги",
    "Processing": "Обробка",
    "Products": "Продукти",
    "Profile": "Профіль",
    "Programs": "Програми",
    "Project": "Проект",
    "Properties": "Властивості",
    "Proposed On": "Запропоновано",
    "Proxy": "Проксі",
    "Publish": "Опублікувати",
    "Published": "Опубліковано",
    "Quantity": "Кількість",
    "Rank": "Ранг",
    "Read": "Читати",
    "Read Contract": "Читати контракт",
    "Read Proxy": "Читати проксі",
    "Real-time": "В реальному часі",
    "Receipt": "Квитанція",
    "Recent": "Останній",
    "Record": "Запис",
    "Recover": "Відновити",
    "Recovery": "Відновлення",
    "Recycle": "Переробляти",
    "Redo": "Повторити",
    "Refresh": "Оновити",
    "Register": "Зареєструвати",
    "Registration": "Реєстрація",
    "Related": "Пов'язано",
    "Release": "Випуск",
    "Reload": "Перезавантажити",
    "Removal": "Видалення",
    "Remove": "Видалити",
    "Removed": "Видалено",
    "Removing": "Видалення",
    "Renew": "Оновити",
    "Replace": "Замінити",
    "Request Removal of Name Tag": "Запит на видалення іменного тегу",
    "Request": "Запит",
    "Requester Email": "Email запитуючого",
    "Requester Name": "Ім'я запитуючого",
    "Requests": "Запити",
    "Resend": "Відправити ще раз",
    "Reset": "Скинути",
    "Resources": "Ресурси",
    "Response": "Відповідь",
    "Responses": "Відповіді",
    "Restore": "Відновити",
    "Restrict": "Обмежити",
    "Result": "Результат",
    "Results": "Результати",
    "Resume": "Продовжити",
    "Retain": "Зберегти",
    "Retrieve": "Витягти",
    "Return": "Повернення",
    "Returned": "Повернено",
    "Returning": "Повернення",
    "Review": "Огляд",
    "Rewards": "Винагороди",
    "Rewrite": "Переписати",
    "Rich": "Багатий",
    "Right": "Право",
    "Rights": "Права",
    "Role": "Роль",
    "Roles": "Ролі",
    "Rollback": "Відкат",
    "Root": "Корінь",
    "Route": "Маршрут",
    "Routing": "Маршрутизація",
    "Row": "Рядок",
    "Rules": "Правила",
    "Run": "Запуск",
    "Running": "Виконання",
    "Sales": "Продажі",
    "Save Changes": "Зберегти зміни",
    "Saved Filters": "Збережені фільтри",
    "Saving": "Збереження",
    "Scenario": "Сценарій",
    "Schemas": "Схеми",
    "Schedule": "Розклад",
    "Scheduled": "Заплановано",
    "Scheduling": "Планування",
    "Schema": "Схема",
    "Scope": "Обсяг",
    "Screen": "Екран",
    "Scripts": "Сценарії",
    "Search": "Пошук",
    "Ask AI | Search by Address | Txn Hash | Block | Token... ": "Пошук за адресою / хешем транзакції / блоком / токеном...",
    "Searches": "Пошуки",
    "Searching": "Пошук",
    "Second": "Секунда",
    "Seconds": "Секунди",
    "Sec": "Сек",
    "Secret": "Секрет",
    "Section": "Розділ",
    "Secure": "Безпечно",
    "Securing": "Забезпечення безпеки",
    "Security": "Безпека",
    "Security Audit": "Аудит безпеки",
    "See more details": "Дивитися більше деталей",
    "See preview of the transaction details.": "Переглянути попередній перегляд деталей транзакції.",
    "Select": "Вибрати",
    "Selected": "Вибрано",
    "Selection": "Вибір",
    "Send": "Надіслати",
    "Send Message": "Надіслати повідомлення",
    "Sender": "Відправник",
    "Sending": "Надсилання",
    "Send native": "Надіслати внутрішній",
    "Sequence": "Послідовність",
    "Server": "Сервер",
    "Service": "Сервіс",
    "Services": "Сервіси",
    "Session": "Сесія",
    "Sessions": "Сесії",
    "Set": "Встановити",
    "Setting": "Налаштування",
    "Settings": "Налаштування",
    "Setup": "Налаштування",
    "Share": "Поділитися",
    "Shared": "Поділений",
    "Sharing": "Поділ",
    "Show": "Показати",
    "Show More Chains": "Показати більше ланцюгів",
    "Show less": "Показати менше",
    "Show more": "Показати більше",
    "Showing": "Показ",
    "Signer": "Підписник",
    "Similar Contract Search": "Пошук схожого контракту",
    "Simple steps for getting Priority Support.": "Прості кроки для отримання пріоритетної підтримки.",
    "Size": "Розмір",
    "Slow": "Повільний",
    "Slot and epoch this block is proposed on.": "Слот і епоха, на яку запропоновано цей блок.",
    "Smart Contract": "Смарт-контракт",
    "Smart Contract Search": "Пошук смарт-контракту",
    "Smart Contracts": "Смарт-контракти",
    "Snapshot": "Знімок",
    "Snapshots": "Знімки",
    "Social": "Соціальний",
    "Software": "Програмне забезпечення",
    "Solidity": "Solidity",
    "Solution": "Рішення",
    "Solutions": "Рішення",
    "Source Code": "Вихідний код",
    "Sources *.sol or *.yul files": "Файли джерел *.sol або *.yul",
    "Sources and Metadata JSON": "Джерела та метадані JSON",
    "Span": "Проміжок",
    "Special": "Спеціальний",
    "Specific": "Конкретний",
    "Specification": "Специфікація",
    "Specifications": "Специфікації",
    "Specified": "Визначено",
    "Specify": "Визначити",
    "Specs": "Специфікації",
    "Sponsor": "Спонсор",
    "Sponsored": "Спонсоровано",
    "Sponsoring": "Спонсорство",
    "Sponsors": "Спонсори",
    "Spotlight": "Прожектор",
    "Stable": "Стабільний",
    "Staging": "Етап розробки",
    "Standard JSON Input": "Стандартний вхід JSON",
    "Start": "Початок",
    "Starting": "Початок",
    "State": "Стан",
    "State Diffrence": "Відмінність стану",
    "State Root": "Корінь стану",
    "StateRoot": "Корінь стану",
    "State of the art coffee machine and experienced baristas in the house for the coffee lovers to thrive.": "Сучасна кавоварка та досвідчені бариста в будинку для поціновувачів кави.",
    "Statistics": "Статистика",
    "Status": "Статус",
    "Step 1": "Крок 1",
    "Step 2": "Крок 2",
    "Step 3": "Крок 3",
    "Steps": "Кроки",
    "Storage Of Assets": "Зберігання активів",
    "Store": "Зберегти",
    "Stored": "Збережено",
    "Storing": "Збереження",
    "Stream": "Потік",
    "Streaming": "Стрімінг",
    "Streams": "Потоки",
    "Strength": "Сила",
    "String": "Рядок",
    "Strings": "Рядки",
    "Structure": "Структура",
    "Structures": "Структури",
    "Style": "Стиль",
    "Styles": "Стилі",
    "Submit": "Подати",
    "Submitted": "Подано",
    "Submitting": "Подача",
    "Subscription": "Підписка",
    "Subscriptions": "Підписки",
    "Subsequent": "Пізніший",
    "Success": "Успіх",
    "Successful": "Успішно",
    "Successfully": "Успішно",
    "Suggest Transaction Action": "Запропонувати дію з транзакцією",
    "Suggestions": "Пропозиції",
    "Summary": "Короткий опис",
    "Support": "Підтримка",
    "Supported": "Підтримується",
    "Supporting": "Підтримка",
    "Supports": "Підтримує",
    "Supply": "Постачання",
    "Surface": "Поверхня",
    "Survey": "Опитування",
    "Surveys": "Опитування",
    "Suspend": "Призупинити",
    "Suspended": "Призупинено",
    "Suspending": "Призупинення",
    "Swap": "Обмін",
    "Swapping": "Обмін",
    "Switch": "Перемикання",
    "Switching": "Перемикання",
    "Symbol": "Символ",
    "Symbols": "Символи",
    "Sync": "Синхронізація",
    "Synchronize": "Синхронізувати",
    "Synchronized": "Синхронізовано",
    "Synchronizing": "Синхронізація",
    "System": "Система",
    "Systems": "Системи",
    "TV": "Телевізор",
    "Table": "Таблиця",
    "Tables": "Таблиці",
    "Tag": "Тег",
    "Tags": "Теги",
    "Take": "Взяти",
    "Taken": "Взято",
    "Taking": "Взяття",
    "Target": "Ціль",
    "Targets": "Цілі",
    "Task": "Завдання",
    "Tasks": "Завдання",
    "Team": "Команда",
    "Teams": "Команди",
    "Technical": "Технічний",
    "Technique": "Техніка",
    "Techniques": "Техніки",
    "Technology": "Технологія",
    "Template": "Шаблон",
    "Templates": "Шаблони",
    "Temporary": "Тимчасовий",
    "Terminate": "Завершити",
    "Terminated": "Завершено",
    "Terminating": "Завершення",
    "Terms": "Умови",
    "Test": "Тест",
    "Tested": "Протестовано",
    "Testing": "Тестування",
    "Tests": "Тести",
    "Text": "Текст",
    "Theme": "Тема",
    "Themes": "Теми",
    "Therapy": "Терапія",
    "This block is finalized.": "Цей блок є остаточним.",
    "This block is finilized.": "Цей блок є остаточним.",
    "This page allows you to customize your browsing experience on Blocksscan.": "Ця сторінка дозволяє налаштувати ваш досвід перегляду на Blocksscan.",
    "This NFT's unique token ID.": "Унікальний ідентифікатор токена цього NFT.",
    "Thread": "Потік",
    "Threads": "Потоки",
    "Threshold": "Поріг",
    "Thresholds": "Пороги",
    "Ticket": "Квиток",
    "Tickets": "Квитки",
    "Time": "Час",
    "Timestamp": "Відмітка часу",
    "Title": "Заголовок",
    "Titles": "Заголовки",
    "To": "До",
    "Token Contract": "Контракт токена",
    "Token Contract Address": "Адреса контракту токена",
    "Token Creation": "Створення токена",
    "Token Holding": "Володіння токеном",
    "Token ID": "Ідентифікатор токена",
    "Token Inventory": "Інвентар токенів",
    "Token Name": "Назва токена",
    "Token Standard": "Стандарт токена",
    "Token Transactions": "Транзакції токена",
    "Token Transfers": "Передачі токена",
    "Token Update": "Оновлення токена",
    "Token creation": "Створення токена",
    "Token transfer": "Передача токена",
    "Tokens": "Токени",
    "Tokens Transferred": "Передані токени",
    "Tokens burnt": "Спалені токени",
    "Tokens minted": "Токени створено",
    "Tokens transferred in the transaction.": "Токени передані у транзакції.",
    "ToDo": "Зробити",
    "To clipboard": "В буфер обміну",
    "Toggle": "Перемкнути",
    "Toggled": "Перемкнено",
    "Toggling": "Перемикання",
    "Tool": "Інструмент",
    "Tools": "Інструменти",
    "Top Accounts": "Топ облікових записів",
    "Top Contract": "Топ контракту",
    "Top Contracts": "Топ контрактів",
    "Top Mints": "Топ випусків",
    "Top NFT": "Топ NFT",
    "Top NFTs": "Топ NFT",
    "Top Statistics": "Топ статистики",
    "Top Token": "Топ токену",
    "Top Tokens": "Топ токенів",
    "Topic": "Тема",
    "Topics": "Теми",
    "Total": "Всього",
    "Total Accounts": "Всього облікових записів",
    "Total Assets": "Всього активів",
    "Total Difficulty": "Загальна складність ланцюжка до цього блоку.",
    "Total Holders": "Всього власників",
    "Total Transfers": "Всього переказів",
    "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.": "Загальна сума ETH, спалена у цій tx та загальні комісійні, збережені зі суми, яку користувач був готовий заплатити за цю tx.",
    "Total gas limit provided by all transactions in the block.": "Загальний ліміт газу, наданий всіма транзакціями у блоку.",
    "Total gas used in the block and its percentage of gas filled in the block.": "Загальний газ, використаний у блоку та його відсоток заповненого газу у блоку.",
    "Total transactions": "Всього транзакцій",
    "Trace": "Відстеження",
    "Traces": "Відстеження",
    "Tracking": "Відстеження",
    "Trade": "Торгівля",
    "Trades": "Торгівлі",
    "Trading": "Торгівля",
    "Transaction": "Транзакція",
    "Transaction Action": "Дія транзакції",
    "Transaction Details": "Деталі транзакції",
    "Transaction Fee": "Комісія за транзакцію",
    "Transaction Hash": "Хеш транзакції",
    "Transaction Type": "Тип транзакції",
    "Transactions": "Транзакції",
    "Transactions Internal": "Внутрішні транзакції",
    "Transcript": "Транскрипція",
    "Transcripts": "Транскрипції",
    "Transfer": "Переказ",
    "Transferred": "Переказано",
    "Transferring": "Переказ",
    "Transfers": "Перекази",
    "Translate": "Перекласти",
    "Translated": "Перекладено",
    "Translating": "Переклад",
    "Translation": "Переклад",
    "Translations": "Переклади",
    "Trending": "Тренди",
    "Trends": "Тренди",
    "Trigger": "Спрацьовувати",
    "Triggered": "Спрацьовано",
    "Triggering": "Спрацьовування",
    "Triggers": "Спрацьовувачі",
    "Troubleshoot": "Вирішення проблем",
    "Troubleshooting": "Вирішення проблем",
    "Trust": "Довіра",
    "Trusted": "Довірено",
    "Trusting": "Довіра",
    "Try": "Спробувати",
    "Trying": "Спроба",
    "Tx Fee": "Комісія за транзакцію",
    "Tx Hash": "Хеш транзакції",
    "Tx Hash|Block|Age": "Хеш транзакції|Блок|Старість",
    "Tx row trace to clipboard": "Відстеження рядка транзакції в буфер обміну",
    "Tx": "Транзакція",
    "Txn": "Txn",
    "Txn Savings": "Економія Txn",
    "Txn Type": "Тип Txn",
    "Txns": "Txns",
    "Type": "Тип",
    "Type here ...": "Введіть тут ...",
    "Type of Backing": "Тип підтримки",
    "Type of Peg": "Тип прикріплення",
    "Types": "Типи",
    "UI": "Інтерфейс",
    "URL": "URL",
    "URLs": "URLs",
    "Undo": "Скасувати",
    "Undone": "Скасовано",
    "Unfinished": "Не закінчено",
    "Unit": "Одиниця",
    "Units": "Одиниці",
    "Universe": "Універсум",
    "Unknown": "Невідомий",
    "Unlink": "Відкріпити",
    "Unlinked": "Відкріплено",
    "Unlinking": "Відкріплення",
    "Unlock": "Розблокувати",
    "Unlocked": "Розблоковано",
    "Unlocking": "Розблокування",
    "Unread": "Непрочитане",
    "Unresolved": "Невирішене",
    "Unseen": "Невидиме",
    "Unsubscribe": "Відписатися",
    "Unsubscribed": "Відписано",
    "Unsubscribing": "Відписування",
    "Untagged": "Без тегу",
    "Untagging": "Видалення тегу",
    "Update": "Оновлення",
    "Update Proxy Contract's Implementation Address": "Оновити адресу реалізації проксі-контракту",
    "Update Token Info": "Оновити інформацію про токен",
    "Updated": "Оновлено",
    "Updating": "Оновлення",
    "Upgrade": "Оновлення",
    "Upgraded": "Оновлено",
    "Upgrading": "Оновлення",
    "Upload": "Завантажити",
    "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.": "Завантажити всі файли джерела контракту Solidity та файли метаданих JSON, створені під час компіляції контракту.",
    "Upload all Solidity or Yul contract source files": "Завантажити всі файли джерела контракту Solidity або Yul",
    "Upload the standard input JSON file created during contract compilation": "Завантажити стандартний вхідний JSON-файл, створений під час компіляції контракту",
    "Uploaded": "Завантажено",
    "Uploading": "Завантаження",
    "Uptime": "Час роботи",
    "Us": "Нас",
    "Usage": "Використання",
    "Use": "Використовуйте",
    "Used": "Використовується",
    "User": "Користувач",
    "Users": "Користувачі",
    "Using": "Використання",
    "Utilities": "Утиліти",
    "Utility": "Утиліта",
    "Validate": "Перевірка",
    "Validated": "Перевірено",
    "Validating": "Перевірка",
    "Validation": "Перевірка",
    "Value": "Значення",
    "Values": "Значення",
    "Variable": "Змінна",
    "Variables": "Змінні",
    "Variant": "Варіант",
    "Variants": "Варіанти",
    "Varying": "Варіюючий",
    "Vault": "Сховище",
    "Vaults": "Сховища",
    "Vector": "Вектор",
    "Vectors": "Вектори",
    "Verification": "Верифікація",
    "Verification Method": "Метод верифікації",
    "Verified": "Перевірено",
    "Verified At": "Перевірено на",
    "Verified Contracts": "Перевірені контракти",
    "Verified Smart Contract": "Перевірений смарт-контракт",
    "Verifying": "Перевірка",
    "Version": "Версія",
    "Versions": "Версії",
    "Verify & Publish": "Перевірка та публікація",
    "Verify And Publish": "Перевірка та публікація",
    "Verify Contract": "Перевірка контракту",
    "View": "Перегляд",
    "View Blocks": "Перегляд блоків",
    "Views": "Перегляди",
    "Viewing": "Перегляд",
    "Visible": "Видимий",
    "Visit": "Відвідати",
    "Visiting": "Відвідування",
    "Visitor": "Відвідувач",
    "Visitors": "Відвідувачі",
    "Visual": "Візуальний",
    "Visuals": "Візуальні елементи",
    "Volume": "Об'єм",
    "Volume 24h": "Об'єм за 24 години",
    "Volumes": "Обсяги",
    "Vote": "Голосувати",
    "Voted": "Проголосувано",
    "Voter": "Голосувальник",
    "Voters": "Голосувальники",
    "Voting": "Голосування",
    "Votes": "Голоси",
    "Vyper Online Compiler": "Онлайн компілятор Vyper",
    "WAITING": "ОЧІКУВАННЯ",
    "Wallet": "Гаманець",
    "Wallet / Exchange / Project related issues": "Проблеми з гаманцем / обмінником / проектом",
    "Wallets": "Гаманці",
    "Warning": "Попередження",
    "Warnings": "Попередження",
    "Watch": "Спостерігати",
    "Watching": "Спостереження",
    "Web": "Веб",
    "Webpage": "Веб-сторінка",
    "Webpages": "Веб-сторінки",
    "Website": "Веб-сайт",
    "Websites": "Веб-сайти",
    "Welcome": "Ласкаво просимо",
    "Welcome to Blocksscan Explorer Services": "Ласкаво просимо до сервісів Blocksscan Explorer",
    "Wellness": "Здоров'я",
    "We're Hiring": "Ми шукаємо співробітників",
    "What are the functions or methods available in this contract?": "Які функції або методи доступні в цьому контракті?",
    "What does this contract do?": "Що робить цей контракт?",
    "What is the logical structure of this contract's code?": "Яка логічна структура коду цього контракту?",
    "Where to find my API Key?": "Де знайти мій ключ API?",
    "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not": "Хоча ми намагаємося відповідати вчасно, у нас часто є великий обсяг запитів. Будь ласка, не",
    "White": "Білий",
    "Who": "Хто",
    "Whole": "Цілий",
    "Wholesale": "Оптовий",
    "Widget": "Віджет",
    "Widgets": "Віджети",
    "Width": "Ширина",
    "Widths": "Ширини",
    "Wiki": "Вікі",
    "Wikis": "Вікі",
    "Willing": "Бажаний",
    "Win": "Перемога",
    "Window": "Вікно",
    "Windows": "Вікна",
    "Winning": "Перемога",
    "Wins": "Перемоги",
    "Wire": "Провід",
    "Wires": "Проводи",
    "Wiring": "Проводка",
    "Wisdom": "Мудрість",
    "Wish": "Бажання",
    "Wished": "Побажали",
    "Wishes": "Побажання",
    "Wishing": "Бажаєте",
    "Withdraw": "Вивести",
    "Withdrawal": "Виведення",
    "Withdrawals": "Виведення",
    "WithdrawalsRoot": "Корені виведень",
    "Withdrawing": "Виведення",
    "Withdrawn": "Виведено",
    "With": "З",
    "Within": "У межах",
    "Without": "Без",
    "Witness": "Свідок",
    "Witnessed": "Свідчено",
    "Witnesses": "Свідки",
    "Witnessing": "Свідчення",
    "Work": "Робота",
    "Worked": "Працював",
    "Working": "Працює",
    "Works": "Роботи",
    "Workspace": "Робоче простір",
    "Workspaces": "Робочі простори",
    "World": "Світ",
    "Worldwide": "По всьому світу",
    "Worry": "Турбота",
    "Worried": "Турботливий",
    "Worries": "Турботи",
    "Worrying": "Турбує",
    "Worth": "Вартість",
    "Write": "Запис",
    "Write Contract": "Запис контракту",
    "Write Proxy": "Запис проксі",
    "Write me a smart contract of voting.": "Напишіть мені розумний контракт для голосування.",
    "Writes": "Записи",
    "Writing": "Запис",
    "Written": "Написаний",
    "Wrong": "Неправильно",
    "Wrote": "Написав",
    "X (Twitter)": "X (Twitter)",
    "XML": "XML",
    "XRay": "Рентген",
    "Xrays": "Рентген",
    "Year": "Рік",
    "Years": "Роки",
    "Yellow": "Жовтий",
    "Yes": "Так",
    "Yesterday": "Вчора",
    "Yield": "Дохід",
    "Yielding": "Дає",
    "Yields": "Доходи",
    "You can Lookup the Account": "Ви можете переглянути обліковий запис",
    "Your browser does not support the video tag.": "Ваш браузер не підтримує тег відео.",
    "Your private keys with anyone.": "Ваші приватні ключі з ким завгодно.",
    "Your Rights": "Ваші права",
    "Youth": "Молодь",
    "Zone": "Зона",
    "Zones": "Зони",
    "Zoning": "Зонування",
    "Zoom": "Збільшення",
    "Zoomed": "Збільшено",
    "Zooming": "Збільшення",
    "Zooms": "Збільшення",
    "rsync-builder in 12 secs": "rsync-builder за 12 секунд",
    "rsyncbuilder": "rsync-builder",
    "sec": "Сек",
    "tokens with a value of": "Токени зі значенням",
    "to clipboard": "У буфер обміну",
    "tokens transferred in the transaction.": "Токени, перенесені в операції.",
    "withdrawals in this block": "Виведення в цьому блоку",
    "we": "Ми",
    "us": "Нам",
    "our": "Наш"
}}