import React, {FC} from 'react';

interface BEmptyProps {
    text?: string;
    style?: any;
}

const BEmpty: FC<BEmptyProps> = ({text, style}) => {

    return (
        <div className="bs-empty" style={style}>
            <div className="bs-empty-box">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    width={48}
                    height={48}
                    viewBox="0 0 256 256"
                >
                    <g
                        style={{
                            stroke: "none",
                            strokeWidth: 0,
                            strokeDasharray: "none",
                            strokeLinecap: "butt",
                            strokeLinejoin: "miter",
                            strokeMiterlimit: 10,
                            fill: "none",
                            fillRule: "nonzero",
                            opacity: 1,
                        }}
                    >
                        <path
                            d="M77.678 19.937H62.55a3.814 3.814 0 0 1-3.81-3.809V1h2v15.128c0 .998.812 1.809 1.81 1.809h15.128v2z"
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 10,
                                fill: "#000",
                                fillRule: "nonzero",
                                opacity: 1,
                            }}
                            transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                        />
                        <path
                            d="M74.595 90h-59.19a4.088 4.088 0 0 1-4.083-4.083V4.083A4.088 4.088 0 0 1 15.405 0h43.059c1.091 0 2.115.425 2.887 1.196l16.131 16.131a4.056 4.056 0 0 1 1.196 2.887v65.703A4.088 4.088 0 0 1 74.595 90zM15.405 2a2.086 2.086 0 0 0-2.083 2.083v81.834c0 1.148.935 2.083 2.083 2.083h59.189a2.086 2.086 0 0 0 2.083-2.083V20.214c0-.548-.223-1.084-.61-1.473L59.937 2.61A2.071 2.071 0 0 0 58.464 2H15.405z"
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 10,
                                fill: "#000",
                                fillRule: "nonzero",
                                opacity: 1,
                            }}
                            transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                        />
                        <path
                            d="M45 60.318a5.253 5.253 0 0 1-5.247-5.247V27.929A5.253 5.253 0 0 1 45 22.682a5.253 5.253 0 0 1 5.247 5.247v27.143A5.252 5.252 0 0 1 45 60.318zm0-35.636a3.25 3.25 0 0 0-3.247 3.247v27.143A3.251 3.251 0 0 0 45 58.319a3.251 3.251 0 0 0 3.247-3.247V27.929A3.251 3.251 0 0 0 45 24.682zM45 75.318c-2.893 0-5.247-2.354-5.247-5.247s2.354-5.247 5.247-5.247c2.894 0 5.247 2.354 5.247 5.247S47.894 75.318 45 75.318zm0-8.494c-1.791 0-3.247 1.457-3.247 3.247S43.21 73.318 45 73.318c1.79 0 3.247-1.457 3.247-3.247S46.79 66.824 45 66.824z"
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 10,
                                fill: "#000",
                                fillRule: "nonzero",
                                opacity: 1,
                            }}
                            transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                        />
                    </g>
                </svg>
                <h4>{text?.length ? text : `There are no match, Please try again later`}</h4>
            </div>
        </div>
    );
};

export default BEmpty;
