import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import DexTransactionAccountList from "../common/dex-transaction-list";
import {useTranslation} from "react-i18next";

const AI_TYPE = [
    {name: "CodeRun AI", value: "coderun"},
    {name: "Open AI", value: "openai"},
    {name: "Devin AI", value: "devinai"},
    {name: "Claude AI", value: "claudeai"},
];

const DEXTracker = () => {
    const location = useLocation();
    const {t, i18n} = useTranslation();
    const hashSplit = location.hash.split("#");
    const [tab, setTab] = useState(hashSplit?.[1] ?? "");
    const [latestTxn, setLatestTxn] = useState<any>(null);
    const [selectedAI, setSelectedAI] = useState(AI_TYPE[0]);

    const handleTab = (e: string) => {
        setTab(e);
        if (!!e?.length) window.location.hash = `#${e}`;
        else {
            window.location.hash = "";
            // @ts-ignore
            window.history.replaceState(null, null, " ");
        }
    };

    return (
        <div className="py-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="bs-page-title">{t("DEX Tracker")}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="bs-tab">
                            <button
                                className={`bs-tab-item ${[""].includes(tab) ? "active" : ""}`}
                                onClick={() => handleTab("")}
                            >
                                {t("Transactions")}
                            </button>
                            <button
                                className={`bs-tab-item ${
                                    ["tradingpair"].includes(tab) ? "active" : ""
                                }`}
                                onClick={() => handleTab("tradingpair")}
                            >
                                {t("Trading Pairs")}
                                <span className="position-absolute top-0 start-100 translate-middle rounded-pill badge border bg-light text-dark ms-1 bs_verified-text">
                                    Beta
                                </span>
                            </button>
                        </div>
                        <div className="bs-tab-content">
                            {[""].includes(tab) && (
                                <div className="dex-transaction">
                                    <div className="d-flex align-items-center justify-content-between my-2">
                                        <div>
                                            <button
                                                className="btn btn-outline-secondary dropdown-toggle theme-dropdown me-2 p-1"
                                                type="button"
                                                id="defaultDropdown"
                                                data-bs-toggle="dropdown"
                                                data-bs-auto-close="true"
                                                aria-expanded="false"
                                            >
                                                <span>{selectedAI.name}</span>
                                            </button>
                                            <ul
                                                className="dropdown-menu theme-dropdown-menu"
                                                aria-labelledby="defaultDropdown"
                                            >
                                                {AI_TYPE.map((ele, index) => (
                                                    <li key={index}>
                                                        <a
                                                            className="dropdown-item"
                                                            href="javascript:void(0)"
                                                            onClick={() => {
                                                                setSelectedAI(ele);
                                                            }}
                                                        >
                                                            <div>
                                                                <span>
                                                                    {" "}
                                                                    {ele?.name}
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                            <Link to={`/charts/dex`}>
                                                <button className="btn btn-outline-secondary p-1">
                                                    <i className="fa fa-chart-pie"></i> DEX Pie chart
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="input-group w-auto mt-sm-2">
                                            <input
                                                type="text"
                                                className="form-control border search-field"
                                                placeholder="Search by Token Address"
                                                required
                                            />
                                            <button
                                                className="btn btn-outline-secondary border"
                                                type="button"
                                                // onClick={togglePasswordVisibility}
                                            >
                                                <i className={`fa fa-search`}></i>
                                            </button>
                                        </div>
                                    </div>
                                    <DexTransactionAccountList
                                        // count={5}
                                        // transactionData={(data: any) => setLatestTxn(data)}
                                    />
                                </div>
                            )}
                            {["tradingpair"].includes(tab) && (
                                <DexTransactionAccountList
                                    count={5}
                                    transactionData={(data: any) => setLatestTxn(data)}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="py-2">
                    <span>
                        {t("Download")}{" "}
                        {/* <Link to={`/exportData?address=${getXDC(address)}`}> */}
                        {t("CSV Export")}
                        {/* </Link> */}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default DEXTracker;
