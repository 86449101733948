import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";
import { Web3Auth } from "@web3auth/modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { Chain } from "wagmi";

const baseUnits = process.env.REACT_APP_BASE_UNIT;
const name = "Social Login";
const iconUrl = `${process.env.REACT_APP_BASE_URL}/tokens/img/${baseUnits?.toLowerCase()}.png`;

interface RainbowWeb3AuthConnectorOptions {
  chains: Chain[];
}

export const rainbowWeb3AuthConnector = ({
  chains,
}: RainbowWeb3AuthConnectorOptions) => {
  if (chains.length === 0) {
    throw new Error("No chains provided to rainbowWeb3AuthConnector.");
  }

  // Create Web3Auth Instance
  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x" + chains[0].id.toString(16),
    rpcTarget: chains[0].rpcUrls.default.http[0],
    displayName: chains[0].name,
    tickerName: chains[0].nativeCurrency?.name || "Currency",
    ticker: chains[0].nativeCurrency?.symbol || "SYM",
    blockExplorer: chains[0].blockExplorers?.default.url[0] || "",
  };

  const web3AuthInstance = new Web3Auth({
    clientId:
      "BK6FLrlkBc8b6PCh7u72Ei_o3jKhXx8HuqwyOSOmoDc3gnz2kLXumgsKuNxTRB6-vyGCIfOetv1DGvsw5VjYpkQ", // Replace with your actual client ID
    chainConfig,
    uiConfig: {
      theme: "light",
      loginMethodsOrder: ["twitter", "google"],
      defaultLanguage: "en",
      appLogo: iconUrl,
      modalZIndex: "2147483647",
      appName: name,
    },
    web3AuthNetwork: "cyan",
    enableLogging: true,
  });

  const privateKeyProvider = new EthereumPrivateKeyProvider({
    config: { chainConfig },
  });
  const openloginAdapterInstance = new OpenloginAdapter({
    privateKeyProvider,
    adapterSettings: {
      network: "cyan",
      uxMode: "popup",
      whiteLabel: {
        name: "Social Login",
        logoLight: iconUrl,
        logoDark: iconUrl,
        defaultLanguage: "en",
        dark: true, 
      },
    },
  });

  web3AuthInstance.configureAdapter(openloginAdapterInstance);

  const isLoggedIn = async () => {
    try {
      const userInfo = await web3AuthInstance.getUserInfo();
      return !!userInfo; 
    } catch (error) {
      return false; 
    }
  };

  const setLoginState = (state: boolean) => {
    localStorage.setItem("isLoggedIn", state.toString());
  };

  web3AuthInstance.on("connected", () => {
    setLoginState(true);
  });

  web3AuthInstance.on("disconnected", () => {
    setLoginState(false); 
  });

  return {
    id: "web3auth",
    name,
    iconUrl,
    iconBackground: "#fff",
    createConnector: () => {
      const connector = new Web3AuthConnector({
        chains: chains,
        options: {
          web3AuthInstance,
          modalConfig: {
            [WALLET_ADAPTERS.OPENLOGIN]: {
              label: "OpenLogin",
              loginMethods: {
                google: {
                  name: "Google Login",
                  logoDark: "url-to-your-custom-google-logo-dark-mode",
                },
                facebook: {
                  name: "Facebook Login",
                  showOnModal: false,
                },
              },
            },
            [WALLET_ADAPTERS.WALLET_CONNECT_V1]: {
              label: "WalletConnect V1",
              showOnModal: false,
            },
            [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
              label: "WalletConnect V2",
              showOnModal: false,
            },
            [WALLET_ADAPTERS.TORUS_EVM]: {
              label: "Torus EVM",
              showOnModal: false,
            },
            [WALLET_ADAPTERS.METAMASK]: {
              label: "MetaMask",
              showOnModal: false,
            },
            [WALLET_ADAPTERS.COINBASE]: {
              label: "Coinbase Wallet",
              showOnModal: false,
            },
          } as Record<string, any>,
        },
      });

      return {
        connector,
      };
    },
  };
};
