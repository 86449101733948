
export const pt = {
   translations: {
      "ABI for the implementation contract at": "ABI para o contrato de implementação em",
      "ABI-encoded": "Codificado em ABI",
      "API Documentation": "Documentação da API",
      "API Plans": "Planos da API",
      "API Support": "Suporte da API",
      "About Us": "Sobre Nós",
      "Account": "Conta",
      "Account Balance Checker": "Verificador de Saldo de Conta",
      "Action": "Ação",
      "Add Name Tag/Label": "Adicionar Etiqueta de Nome",
      "Add one more": "Adicionar mais um",
      "Additional Info": "Informações Adicionais",
      "Additional Notes": "Notas Adicionais",
      "Address": "Endereço",
      "Address of this NFT contract.": "Endereço deste contrato NFT.",
      "Address that deployed this NFT contract.": "Endereço que implantou este contrato NFT.",
      "Advertising": "Publicidade",
      "Age": "Idade",
      "All Rights Reserved": "Todos os Direitos Reservados",
      "All Transactions": "Todas as Transações",
      "Amount": "Quantidade",
      "Analytics": "Análises",
      "Annual Trips": "Viagens Anuais",
      "Are you the contract creator": "Você é o criador do contrato",
      "Back to Top": "Voltar ao Topo",
      "Balance": "Saldo",
      "Balance Checker": "Verificador de Saldo",
      "Base": "Base",
      "Base Fee": "Taxa Base",
      "Base Fee Per Gas": "Taxa Base por Gás",
      "Best Coffee in Town": "Melhor Café da Cidade",
      "Block": "Bloco",
      "Block Height": "Altura do Bloco",
      "Block Number": "Número do Bloco",
      "Block Reward": "Recompensa do Bloco",
      "Block header withdrawal root hash.": "Hash raiz de retirada do cabeçalho do bloco.",
      "Block nonce is a value used during mining to demonstrate proof of work for a block.": "Nonce do bloco é um valor usado durante a mineração para demonstrar a prova de trabalho para um bloco.",
      "Block proposed on epoch": "Bloco proposto na época",
      "Blocksscan is an independent": "Blocksscan é independente",
      "Blockchain": "Blockchain",
      "Blocks": "Blocos",
      "Broadcast Transaction": "Transmissão de Transação",
      "Brand Assets": "Ativos da Marca",
      "Bridge": "Ponte",
      "Burnt": "Queimado",
      "Burnt & Txn Savings Fees": "Taxas de Economia e Queima de Txn",
      "Byte Code": "Código Byte",
      "Bytecode to Opcode": "Bytecode para Opcode",
      "By using Blocksscan Explorer Services, you consent to our Privacy Policy.": "Ao usar os Serviços do Explorador Blocksscan, você concorda com nossa Política de Privacidade.",
      "CSV Export": "Exportação CSV",
      "Call": "Chamada",
      "Capacity": "Capacidade",
      "Careers": "Carreiras",
      "Careers at BlocksScan": "Carreiras na BlocksScan",
      "Change Fiat Currency": "Mudar Moeda Fiat",
      "Change": "Mudança",
      "Charts & stats": "Gráficos e estatísticas",
      "Charts And Stats": "Gráficos e Estatísticas",
      "Choose Priority Service": "Escolher Serviço Prioritário",
      "Choose desired language": "Escolher idioma desejado",
      "Choose the theme": "Escolher o tema",
      "Circulating Market Cap": "Cap. de Mercado Circulante",
      "Circulating Supply": "Suprimento Circulante",
      "Circulating Supply Market Cap": "Cap. de Mercado do Suprimento Circulante",
      "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.": "Classificação de NFT baseada em como a imagem é armazenada: On-Chain refere-se àqueles com dados armazenados na blockchain enquanto Off-Chain refere-se àqueles com dados armazenados em outro lugar na Internet.",
      "Classification": "Classificação",
      "Click here to": "Clique aqui para",
      "Click to show less": "Clique para mostrar menos",
      "Click to show more": "Clique para mostrar mais",
      "Close": "Fechar",
      "Code": "Código",
      "Code Reader": "Leitor de Código",
      "CodeRunAI": "CodeRunAI",
      "Coin transfer": "Transferência de moeda",
      "Collater­alization": "Colateralização",
      "Collection": "Coleção",
      "Collection Name": "Nome da Coleção",
      "Commute Allowance": "Auxílio Transporte",
      "Community": "Comunidade",
      "Community Support": "Suporte Comunitário",
      "Company": "Empresa",
      "Compiler": "Compilador",
      "Compiler Type": "Tipo de Compilador",
      "Compiler Version": "Versão do Compilador",
      "COMPILER TYPE AND VERSION SELECTION": "Seleção de Tipo e Versão do Compilador",
      "ConfirmationToken": "Token de Confirmação",
      "Confirmation": "Confirmação",
      "Confirmed within": "Confirmado dentro de",
      "Confirming": "Confirmando",
      "Contact Us": "Contate-Nos",
      "Contact Information": "Informações de Contato",
      "Contract": "Contrato",
      "Contract Address": "Endereço do Contrato",
      "Contract Code": "Código do Contrato",
      "Contract Diff Checker": "Verificador de Diferenças de Contrato",
      "Contract File Path": "Caminho do Arquivo do Contrato",
      "Contract Internal Transactions": "Transações Internas do Contrato",
      "Contract Licence": "Licença do Contrato",
      "Contract Name": "Nome do Contrato",
      "Contract Source Code Verified": "Código Fonte do Contrato Verificado",
      "Contract Verification": "Verificação de Contrato",
      "Contract call": "Chamada de Contrato",
      "Contract creation": "Criação de Contrato",
      "Contracts": "Contratos",
      "Contracts Internal": "Contratos Internos",
      "Cost per unit of gas spent for the transaction, in Ether and Gwei.": "Custo por unidade de gás gasto na transação, em Ether e Gwei.",
      "Creator": "Criador",
      "Created": "Criado",
      "Current owner of this NFT.": "Proprietário atual deste NFT.",
      "Custom Card": "Cartão Personalizado",
      "Customize": "Personalizar",
      "Customize this card by selecting one of the options below": "Personalize este cartão selecionando uma das opções abaixo",
      "Daily Transactions": "Transações Diárias",
      "Dark": "Escuro",
      "Dark Mode": "Modo Escuro",
      "Data": "Dados",
      "Data Security": "Segurança de Dados",
      "Date for the snapshot": "Data para o snapshot",
      "Decode input data": "Decodificar dados de entrada",
      "Details": "Detalhes",
      "Development": "Desenvolvimento",
      "Dim": "Diminuir",
      "Directory": "Diretório",
      "Display All Chains": "Mostrar Todas as Cadeias",
      "Displaying current Value; Click to show Value on Day of Txn": "Exibindo valor atual; Clique para mostrar o Valor no Dia da Transação",
      "Donations": "Doações",
      "Drag & drop files here, or click to select files": "Arraste e solte arquivos aqui, ou clique para selecionar arquivos",
      "Download": "Baixar",
      "Downloaded": "Baixado",
      "EVM Version": "Versão EVM",
      "Encode": "Codificar",
      "Encoded": "Codificado",
      "Encoding": "Codificando",
      "Enter Source Code...": "Inserir Código Fonte...",
      "Enter name here...": "Insira o nome aqui...",
      "Ensure that token information is submitted. Check our token update guidelines": "Certifique-se de que as informações do token sejam enviadas. Verifique nossas diretrizes de atualização de token",
      "Event Logs": "Registros de Eventos",
      "Events": "Eventos",
      "Ex. John Doe": "Ex. João Silva",
      "Example questions": "Perguntas de exemplo",
      "Explorer-as-a-Service": "Explorador como Serviço",
      "Export": "Exportar",
      "Extra Data": "Dados Extras",
      "Extra lunch money provided along with the occasional team lunch and dinners.": "Dinheiro extra para almoço fornecido junto com almoços e jantares de equipe ocasionais.",
      "FAQ": "FAQ",
      "Failed": "Falhou",
      "Fast": "Rápido",
      "Fee": "Taxa",
      "Fee Recipient": "Recebedor da Taxa",
      "Feedback Form": "Formulário de Feedback",
      "File": "Arquivo",
      "Files": "Arquivos",
      "Filter": "Filtro",
      "Filters": "Filtros",
      "Filtered": "Filtrado",
      "Finalized": "Finalizado",
      "Flex Benefits": "Benefícios Flexíveis",
      "Flexible working hours": "Horário de trabalho flexível",
      "Food Allowance": "Auxílio Alimentação",
      "For": "Para",
      "For almost anything that improves your life - training courses, fitness plans... even beauty facials!": "Para quase tudo que melhore sua vida - cursos de treinamento, planos de fitness... até tratamentos faciais!",
      "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.": "Para melhores práticas, todos os detentores, publicadores e autores de código fonte de contratos são encorajados a também especificar a licença acompanhante para seu código fonte de contrato verificado fornecido.",
      "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.": "Para cada bloco, o produtor do bloco é recompensado com uma quantidade finita de Ether além das taxas pagas por todas as transações no bloco.",
      "Forums": "Fóruns",
      "Free Flow Drinks & Snacks": "Bebidas e Lanches à Vontade",
      "From": "De",
      "Fully Diluted Market Cap": "Cap. de Mercado Totalmente Diluído",
      "Functionality": "Funcionalidade",
      "Functions": "Funções",
      "Gas": "Gás",
      "Gas Fees": "Taxas de Gás",
      "Gas Limit": "Limite de Gás",
      "Gas Limit & Usage by Txn": "Limite de Gás e Uso por Transação",
      "Gas Price": "Preço do Gás",
      "Gas Target": "Alvo de Gás",
      "Gas Tracker": "Rastreador de Gás",
      "Gas Used": "Gás Usado",
      "Gas price history": "Histórico de preços do gás",
      "Gas tracker": "Rastreador de gás",
      "Gas tracker overview": "Visão geral do rastreador de gás",
      "General Inquiry": "Consulta Geral",
      "Github": "Github",
      "Get Started": "Começar",
      "Help & Support": "Ajuda e Suporte",
      "Helpdesk Ticket Number": "Número do Ticket de Helpdesk",
      "Hex": "Hex",
      "Hide All Chains": "Ocultar Todas as Cadeias",
      "Hide Chains": "Ocultar Cadeias",
      "Highlighted events of the transaction.": "Eventos destacados da transação.",
      "Historical Balance at a specific Block No or Date.": "Saldo Histórico em um Número de Bloco ou Data específicos.",
      "Home": "Início",
      "How We Use Your Information": "Como Usamos Suas Informações",
      "How to Start": "Como Começar",
      "I can help you in the following scenarios": "Posso ajudar nos seguintes cenários",
      "I don't have an API Key": "Não tenho uma Chave API",
      "I have an API Key": "Eu tenho uma Chave API",
      "ID": "ID",
      "If using the Solidity compiler, run solc —version to check.": "Se estiver usando o compilador Solidity, execute solc —version para verificar.",
      "If you have any additional notes, please leave them here": "Se você tiver quaisquer notas adicionais, por favor, deixe-as aqui",
      "Implementation": "Implementação",
      "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.": "Além de agilizar atualizações de tokens, também fornecemos suporte prioritário para outras questões urgentes ou técnicas, como verificação de contratos.",
      "Indexed": "Indexado",
      "Information We Collect": "Informações que Coletamos",
      "Input Data": "Dados de Entrada",
      "Instagram": "Instagram",
      "Internal Transactions": "Transações Internas",
      "Internal transaction is transactions as a result of contract execution that involves Ether value.": "Transação interna é transações como resultado da execução do contrato que envolve valor em Ether.",
      "Is Yul Contract": "É Contrato Yul",
      "Item": "Item",
      "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.": "É nossa política postar quaisquer alterações que fazemos em nossa política de privacidade nesta página. A data em que a política de privacidade foi revisada pela última vez é identificada no topo da página. Você é responsável por garantir que temos um endereço de e-mail atualizado, ativo e entregável para você e por visitar periodicamente nosso site e esta política de privacidade para verificar quaisquer alterações.",
      "Join BlocksScan and enhance the world's leading blockchain explorer and search, API & analytics platform.": "Junte-se à BlocksScan e aprimore o principal explorador de blockchain do mundo e plataforma de pesquisa, API e análises.",
      "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.": "Mantenha-se atualizado com os últimos avanços e melhores práticas na indústria com colegas de equipe aprendidos e experientes.",
      "Kindly follow the simple steps below to get your support prioritized.": "Siga gentilmente os passos simples abaixo para priorizar seu suporte.",
      "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.": "Por favor, entre em contato com seu provedor de serviço de carteira, bolsas ou proprietário de projeto/contrato para suporte adicional, pois eles estão em melhor posição para ajudá-lo com as questões relacionadas e de suas plataformas.",
      "Knowledge Base": "Base de Conhecimento",
      "Last Sale": "Última Venda",
      "Last Traded": "Último Negociado",
      "Last Updated": "Última Atualização",
      "Last updated": "Última atualização",
      "Latest Block": "Último Bloco",
      "Latest Blocks": "Últimos Blocos",
      "Latest Mints": "Últimas Cunhagens",
      "Latest Signed Block": "Último Bloco Assinado",
      "Latest Transaction": "Última Transação",
      "Latest Transactions": "Últimas Transações",
      "Latest Trades": "Últimos Negócios",
      "Latest Transfers": "Últimas Transferências",
      "Learn more about": "Saiba mais sobre",
      "Learning Sessions": "Sessões de Aprendizado",
      "Legacy": "Legado",
      "License": "Licença",
      "Light": "Claro",
      "List of": "Lista de",
      "List of {{_tokenType}}20 tokens transferred in the transaction.": "Lista de tokens {{_tokenType}}20 transferidos na transação.",
      "List of tokens burnt transferred in the transaction.": "Lista de tokens queimados transferidos na transação.",
      "List of tokens mint transferred in the transaction.": "Lista de tokens cunhados transferidos na transação.",
      "Load": "Carregar",
      "Loading": "Carregando",
      "Loading...": "Carregando...",
      "Logdata to clipboard": "Dados de Log para a área de transferência",
      "Logs": "Logs",
      "Look up": "Procurar",
      "Lookup": "Pesquisar",
      "Low load": "Baixa carga",
      "MED GAS PRICE": "PREÇO MÉDIO DO GÁS",
      "Main Interfaces (.vy or .json)": "Interfaces Principais (.vy ou .json)",
      "Market": "Mercado",
      "Market Cap": "Cap. de Mercado",
      "Market Capitalization": "Capitalização de Mercado",
      "Marketplaces": "Mercados",
      "Marketplaces trading this NFT.": "Mercados negociando este NFT.",
      "Max": "Máx",
      "Max Priority": "Prioridade Máxima",
      "Max Price": "Preço Máximo",
      "Max Total Supply": "Suprimento Total Máximo",
      "Media & Press": "Mídia e Imprensa",
      "Medical Benefits": "Benefícios Médicos",
      "Method ID": "ID do Método",
      "Method Name": "Nome do Método",
      "Method": "Método",
      "Method id": "ID do Método",
      "Metadata": "Metadados",
      "Min Price": "Preço Mínimo",
      "Missing": "Faltando",
      "Mode": "Modo",
      "More Details": "Mais Detalhes",
      "More Info": "Mais Informações",
      "Multichain Info": "Informações Multicadeia",
      "Multichain Portfolio": "Portfólio Multicadeia",
      "Name": "Nome",
      "Name Tag": "Etiqueta de Nome",
      "NEVER": "NUNCA",
      "NEVER send us these.": "NUNCA nos envie isso.",
      "NEW": "NOVO",
      "NFT Address": "Endereço NFT",
      "NFT Latest Mints": "Últimas Cunhagens NFT",
      "NFT Top Mints": "Top Cunhagens NFT",
      "NFT Transfer": "Transferência NFT",
      "NFT Transfers": "Transferências NFT",
      "NFT's unique token ID.": "ID único do token NFT.",
      "NFT": "NFT",
      "Node": "Nó",
      "Nonce": "Nonce",
      "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.": "Informações Não Pessoais: Dados que não identificam pessoalmente você, mas estão ligados ao seu uso de nossos serviços, como endereços IP, tipos de navegador e horários de acesso.",
      "Normal": "Normal",
      "Note: Filtered by  totalSupply() , method returned a zero value.": "Nota: Filtrado por totalSupply(), o método retornou um valor zero.",
      "Note": "Nota",
      "NOT": "NÃO",
      "No Record Found": "Nenhum Registro Encontrado",
      "No transactions found.": "Nenhuma transação encontrada.",
      "Number": "Número",
      "Number of beacon withdrawals in this block.": "Número de retiradas de farol neste bloco.",
      "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.": "Número do bloco no qual a transação é registrada. Confirmações de bloco indicam quantos blocos foram adicionados desde que a transação foi produzida.",
      "OAuth": "OAuth",
      "Office Entertainment": "Entretenimento no Escritório",
      "Onchain Market Cap": "Cap. de Mercado Onchain",
      "OpenAI API key": "Chave API OpenAI",
      "Optimization": "Otimização",
      "Optimization Enabled": "Otimização Ativada",
      "Optimization Runs": "Execuções de Otimização",
      "Order": "Ordem",
      "Others": "Outros",
      "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.": "A nossa não é apenas uma grande visão. Nós a apoiamos com a empresa e cultura adaptadas para realizá-la.",
      "Our team will review your request and contact you using an official": "Nossa equipe revisará seu pedido e entrará em contato com você usando um oficial",
      "Overview": "Visão Geral",
      "Owner": "Proprietário",
      "Owners": "Proprietários",
      "Page": "Página",
      "Parent Hash": "Hash Pai",
      "Pending Transaction": "Transação Pendente",
      "Pending Transactions": "Transações Pendentes",
      "Pending...": "Pendente...",
      "Percentage": "Porcentagem",
      "Perks and Benefits": "Vantagens e Benefícios",
      "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.": "Informações Pessoais: Dados que o identificam pessoalmente, como seu nome, endereço de e-mail e detalhes de pagamento, fornecidos no registro ou durante o uso de nossos serviços.",
      "Phone": "Telefone",
      "Platform": "Plataforma",
      "Please Select Your Message Subject": "Por favor, Selecione o Assunto da Sua Mensagem",


      "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that": "Por favor, entenda que só podemos ajudar com questões diretamente relacionadas aos serviços do explorador de blocos. Antes de escrever, por favor, esteja informado de que",
      "Please use this form to get in touch for priority support or featured listing with our team.": "Por favor, use este formulário para entrar em contato para suporte prioritário ou listagem em destaque com nossa equipe.",
      "Position": "Posição",
      "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.": "Pós-atualização de Londres, isso representa o multiplicador mínimo de gás usado necessário para que uma tx seja incluída em um bloco.",
      "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.": "Pós-atualização de Londres, isso representa a parte da taxa de tx que é queimada: baseFeePerGas * gasUsed.",
      "Power": "Poder",
      "Powered by BlocksScan": `Desenvolvido por ${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"}`,
      "BlocksScan is a Block Explorer and Analytics Platform for": `${process.env.REACT_APP_BASE_EXPLORER || "BlocksScan"} is a Block Explorer and Analytics Platform for`,
      "Present": "Presente",
      "Preserve": "Preservar",
      "Press": "Imprensa",
      "Price": "Preço",
      "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below": "Contrato Vyper compilado primário. Adicione apenas o contrato principal aqui cujo bytecode foi implantado, todos os outros arquivos podem ser carregados na caixa de interfaces abaixo",
      "Privacy": "Privacidade",
      "Privacy Policy": "Política de Privacidade",
      "Private Name Tags": "Etiquetas de Nome Privado",
      "Processing": "Processando",
      "Products": "Produtos",
      "Profile": "Perfil",
      "Programs": "Programas",
      "Project": "Projeto",
      "Properties": "Propriedades",
      "Proposed On": "Proposto Em",
      "Proxy": "Proxy",
      "Publish": "Publicar",
      "Published": "Publicado",
      "Quantity": "Quantidade",
      "Rank": "Rank",
      "Read": "Ler",
      "Read Contract": "Ler Contrato",
      "Read Proxy": "Ler Proxy",
      "Real-time": "Tempo Real",
      "Receipt": "Recibo",
      "Recent": "Recente",
      "Record": "Registro",
      "Recover": "Recuperar",
      "Recovery": "Recuperação",
      "Recycle": "Reciclar",
      "Redo": "Refazer",
      "Refresh": "Atualizar",
      "Register": "Registrar",
      "Registration": "Registro",
      "Related": "Relacionado",
      "Release": "Lançamento",
      "Reload": "Recarregar",
      "Removal": "Remoção",
      "Remove": "Remover",
      "Removed": "Removido",
      "Removing": "Removendo",
      "Renew": "Renovar",
      "Replace": "Substituir",
      "Request Removal of Name Tag": "Solicitar Remoção de Etiqueta de Nome",
      "Request": "Solicitação",
      "Requester Email": "E-mail do Solicitante",
      "Requester Name": "Nome do Solicitante",
      "Requests": "Solicitações",
      "Resend": "Reenviar",
      "Reset": "Redefinir",
      "Resources": "Recursos",
      "Response": "Resposta",
      "Responses": "Respostas",
      "Restore": "Restaurar",
      "Restrict": "Restringir",
      "Result": "Resultado",
      "Results": "Resultados",
      "Resume": "Retomar",
      "Retain": "Reter",
      "Retrieve": "Recuperar",
      "Return": "Retornar",
      "Returned": "Devolvido",
      "Returning": "Retornando",
      "Review": "Revisão",
      "Rewards": "Recompensas",
      "Rewrite": "Reescrever",
      "Rich": "Rico",
      "Right": "Direito",
      "Rights": "Direitos",
      "Role": "Função",
      "Roles": "Funções",
      "Rollback": "Reverter",
      "Root": "Raiz",
      "Route": "Rota",
      "Routing": "Roteamento",
      "Row": "Linha",
      "Rules": "Regras",
      "Run": "Executar",
      "Running": "Correndo",
      "Sales": "Vendas",
      "Save Changes": "Salvar Alterações",
      "Saved Filters": "Filtros Salvos",
      "Saving": "Salvando",
      "Scenario": "Cenário",
      "Schemas": "Esquemas",
      "Schedule": "Agendar",
      "Scheduled": "Agendado",
      "Scheduling": "Agendamento",
      "Schema": "Esquema",
      "Scope": "Escopo",
      "Screen": "Tela",
      "Scripts": "Scripts",
      "Search": "Pesquisar",
      "Ask AI | Search by Address | Txn Hash | Block | Token... ": "Pesquisar por endereço / hash de tx / bloco / token...",
      "Searches": "Pesquisas",
      "Searching": "Pesquisando",
      "Second": "Segundo",
      "Seconds": "Segundos",
      "Sec": "Seg",
      "Secret": "Secreto",
      "Section": "Seção",
      "Secure": "Seguro",
      "Securing": "Protegendo",
      "Security": "Segurança",
      "Security Audit": "Auditoria de Segurança",
      "See more details": "Ver mais detalhes",
      "See preview of the transaction details.": "Ver prévia dos detalhes da transação.",
      "Select": "Selecionar",
      "Selected": "Selecionado",
      "Selection": "Seleção",
      "Send": "Enviar",
      "Send Message": "Enviar Mensagem",
      "Sender": "Remetente",
      "Sending": "Enviando",
      "Send native": "Enviar nativo",
      "Sequence": "Sequência",
      "Server": "Servidor",
      "Service": "Serviço",
      "Services": "Serviços",
      "Session": "Sessão",
      "Sessions": "Sessões",
      "Set": "Conjunto",
      "Setting": "Configuração",
      "Settings": "Configurações",
      "Setup": "Configurar",
      "Share": "Compartilhar",
      "Shared": "Compartilhado",
      "Sharing": "Compartilhando",
      "Show": "Mostrar",
      "Show More Chains": "Mostrar Mais Cadeias",
      "Show less": "Mostrar menos",
      "Show more": "Mostrar mais",
      "Showing": "Mostrando",
      "Signer": "Assinante",
      "Similar Contract Search": "Busca de Contrato Similar",
      "Simple steps for getting Priority Support.": "Passos simples para obter Suporte Prioritário.",
      "Size": "Tamanho",
      "Slow": "Lento",
      "Slot and epoch this block is proposed on.": "Slot e época em que este bloco é proposto.",
      "Smart Contract": "Contrato Inteligente",
      "Smart Contract Search": "Busca de Contrato Inteligente",
      "Smart Contracts": "Contratos Inteligentes",
      "Snapshot": "Snapshot",
      "Snapshots": "Snapshots",
      "Social": "Social",
      "Software": "Software",
      "Solidity": "Solidity",
      "Solution": "Solução",
      "Solutions": "Soluções",
      "Source Code": "Código Fonte",
      "Sources *.sol or *.yul files": "Fontes *.sol ou *.yul files",
      "Sources and Metadata JSON": "Fontes e JSON de Metadados",
      "Span": "Período",
      "Special": "Especial",
      "Specific": "Específico",
      "Specification": "Especificação",
      "Specifications": "Especificações",
      "Specified": "Especificado",
      "Specify": "Especificar",
      "Specs": "Especificações",
      "Sponsor": "Patrocinador",
      "Sponsored": "Patrocinado",
      "Sponsoring": "Patrocinando",
      "Sponsors": "Patrocinadores",
      "Spotlight": "Holofote",
      "Stable": "Estável",
      "Staging": "Preparação",
      "Standard JSON Input": "Entrada JSON Padrão",
      "Start": "Iniciar",
      "Starting": "Iniciando",
      "State": "Estado",
      "State Diffrence": "Diferença de Estado",
      "State Root": "Raiz do Estado",
      "StateRoot": "Raiz do Estado",
      "State of the art coffee machine and experienced baristas in the house for the coffee lovers to thrive.": "Máquina de café de última geração e baristas experientes na casa para os amantes de café prosperarem.",
      "Statistics": "Estatísticas",
      "Status": "Status",
      "Step 1": "Passo 1",
      "Step 2": "Passo 2",
      "Step 3": "Passo 3",
      "Steps": "Passos",
      "Storage Of Assets": "Armazenamento de Ativos",
      "Store": "Loja",
      "Stored": "Armazenado",
      "Storing": "Armazenando",
      "Stream": "Transmitir",
      "Streaming": "Streaming",
      "Streams": "Streams",
      "Strength": "Força",
      "String": "String",
      "Strings": "Strings",
      "Structure": "Estrutura",
      "Structures": "Estruturas",
      "Style": "Estilo",
      "Styles": "Estilos",
      "Submit": "Enviar",
      "Submitted": "Enviado",
      "Submitting": "Enviando",
      "Subscription": "Assinatura",
      "Subscriptions": "Assinaturas",
      "Subsequent": "Subsequente",
      "Success": "Sucesso",
      "Successful": "Bem-sucedido",
      "Successfully": "Com sucesso",
      "Suggest Transaction Action": "Sugerir Ação de Transação",
      "Suggestions": "Sugestões",
      "Summary": "Resumo",
      "Support": "Suporte",
      "Supported": "Apoiado",
      "Supporting": "Apoiando",
      "Supports": "Apoios",
      "Supply": "Suprimento",
      "Surface": "Superfície",
      "Survey": "Pesquisa",
      "Surveys": "Pesquisas",
      "Suspend": "Suspender",
      "Suspended": "Suspenso",
      "Suspending": "Suspendendo",
      "Swap": "Trocar",
      "Swapping": "Trocando",
      "Switch": "Alternar",
      "Switching": "Alternando",
      "Symbol": "Símbolo",
      "Symbols": "Símbolos",
      "Sync": "Sincronizar",
      "Synchronize": "Sincronizar",
      "Synchronized": "Sincronizado",
      "Synchronizing": "Sincronizando",
      "System": "Sistema",
      "Systems": "Sistemas",
      "TV": "TV",
      "Table": "Mesa",
      "Tables": "Mesas",
      "Tag": "Etiqueta",
      "Tags": "Etiquetas",
      "Take": "Pegar",
      "Taken": "Tomado",
      "Taking": "Tomando",
      "Target": "Alvo",
      "Targets": "Alvos",
      "Task": "Tarefa",
      "Tasks": "Tarefas",
      "Team": "Equipe",
      "Teams": "Equipes",
      "Technical": "Técnico",
      "Technique": "Técnica",
      "Techniques": "Técnicas",
      "Technology": "Tecnologia",
      "Template": "Modelo",
      "Templates": "Modelos",
      "Temporary": "Temporário",
      "Terminate": "Terminar",
      "Terminated": "Terminado",
      "Terminating": "Terminando",
      "Terms": "Termos",
      "Test": "Teste",
      "Tested": "Testado",
      "Testing": "Testando",
      "Tests": "Testes",
      "Text": "Texto",
      "Theme": "Tema",
      "Themes": "Temas",
      "Therapy": "Terapia",
      "This block is finalized.": "Este bloco está finalizado.",
      "This block is finilized.": "Este bloco está finalizado.",
      "This page allows you to customize your browsing experience on Blocksscan.": "Esta página permite que você personalize sua experiência de navegação no Blocksscan.",
      "This NFT's unique token ID.": "ID único do token deste NFT.",
      "Thread": "Tópico",
      "Threads": "Tópicos",
      "Threshold": "Limiar",
      "Thresholds": "Limiares",
      "Ticket": "Bilhete",
      "Tickets": "Bilhetes",
      "Time": "Tempo",
      "Timestamp": "Carimbo de Data/Hora",
      "Title":

         "Título",
      "Titles": "Títulos",
      "To": "Para",
      "Token Contract": "Contrato de Token",
      "Token Contract Address": "Endereço do Contrato de Token",
      "Token Creation": "Criação de Token",
      "Token Holding": "Posse de Token",
      "Token ID": "ID do Token",
      "Token Inventory": "Inventário de Token",
      "Token Name": "Nome do Token",
      "Token Standard": "Padrão de Token",
      "Token Transactions": "Transações de Token",
      "Token Transfers": "Transferências de Token",
      "Token Update": "Atualização de Token",
      "Token creation": "Criação de token",
      "Token transfer": "Transferência de token",
      "Tokens": "Tokens",
      "Tokens Transferred": "Tokens Transferidos",
      "Tokens burnt": "Tokens queimados",
      "Tokens minted": "Tokens cunhados",
      "Tokens transferred in the transaction.": "Tokens transferidos na transação.",
      "ToDo": "A Fazer",
      "To clipboard": "Para a área de transferência",
      "Toggle": "Alternar",
      "Toggled": "Alternado",
      "Toggling": "Alternando",
      "Tool": "Ferramenta",
      "Tools": "Ferramentas",
      "Top Accounts": "Principais Contas",
      "Top Contract": "Contrato Principal",
      "Top Contracts": "Principais Contratos",
      "Top Mints": "Principais Cunhagens",
      "Top NFT": "NFT Principal",
      "Top NFTs": "Principais NFTs",
      "Top Statistics": "Principais Estatísticas",
      "Top Token": "Token Principal",
      "Top Tokens": "Principais Tokens",
      "Topic": "Tópico",
      "Topics": "Tópicos",
      "Total": "Total",
      "Total Accounts": "Total de Contas",
      "Total Assets": "Ativos Totais",
      "Total Difficulty": "Dificuldade Total",
      "Total Holders": "Total de Titulares",
      "Total Transfers": "Total de Transferências",
      "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.": "Quantidade total de ETH queimado desta transação e taxas totais economizadas do valor que o usuário estava disposto a pagar por esta transação.",
      "Total gas limit provided by all transactions in the block.": "Limite total de gás fornecido por todas as transações no bloco.",
      "Total gas used in the block and its percentage of gas filled in the block.": "Gás total usado no bloco e sua porcentagem de gás preenchido no bloco.",
      "Total transactions": "Transações totais",
      "Trace": "Rastrear",
      "Traces": "Rastros",
      "Tracking": "Rastreamento",
      "Trade": "Comércio",
      "Trades": "Negócios",
      "Trading": "Negociação",
      "Transaction": "Transação",
      "Transaction Action": "Ação de Transação",
      "Transaction Details": "Detalhes da Transação",
      "Transaction Fee": "Taxa de Transação",
      "Transaction Hash": "Hash da Transação",
      "Transaction Type": "Tipo de Transação",
      "Transactions": "Transações",
      "Transactions Internal": "Transações Internas",
      "Transcript": "Transcrição",
      "Transcripts": "Transcrições",
      "Transfer": "Transferir",
      "Transferred": "Transferido",
      "Transferring": "Transferindo",
      "Transfers": "Transferências",
      "Translate": "Traduzir",
      "Translated": "Traduzido",
      "Translating": "Traduzindo",
      "Translation": "Tradução",
      "Translations": "Traduções",
      "Trending": "Tendências",
      "Trends": "Tendências",
      "Trigger": "Gatilho",
      "Triggered": "Acionado",
      "Triggering": "Acionando",
      "Triggers": "Gatilhos",
      "Troubleshoot": "Solucionar Problemas",
      "Troubleshooting": "Resolução de Problemas",
      "Trust": "Confiança",
      "Trusted": "Confiável",
      "Trusting": "Confiando",
      "Try": "Tentar",
      "Trying": "Tentando",
      "Tx Fee": "Taxa Tx",
      "Tx Hash": "Hash Tx",
      "Tx Hash|Block|Age": "Hash Tx|Bloco|Idade",
      "Tx row trace to clipboard": "Rastreamento de linha Tx para área de transferência",
      "Tx": "Tx",
      "Txn": "Txn",
      "Txn Savings": "Economias Txn",
      "Txn Type": "Tipo Txn",
      "Txns": "Txns",
      "Type": "Tipo",
      "Type here ...": "Digite aqui ...",
      "Type of Backing": "Tipo de Apoio",
      "Type of Peg": "Tipo de Fixação",
      "Types": "Tipos",
      "UI": "UI",
      "URL": "URL",
      "URLs": "URLs",
      "Undo": "Desfazer",
      "Undone": "Desfeito",
      "Unfinished": "Inacabado",
      "Unit": "Unidade",
      "Units": "Unidades",
      "Universe": "Universo",
      "Unknown": "Desconhecido",
      "Unlink": "Desvincular",
      "Unlinked": "Desvinculado",
      "Unlinking": "Desvinculando",
      "Unlock": "Desbloquear",
      "Unlocked": "Desbloqueado",
      "Unlocking": "Desbloqueando",
      "Unread": "Não lido",
      "Unresolved": "Não resolvido",
      "Unseen": "Não visto",
      "Unsubscribe": "Cancelar inscrição",
      "Unsubscribed": "Inscrição cancelada",
      "Unsubscribing": "Cancelando inscrição",
      "Untagged": "Sem etiqueta",
      "Untagging": "Removendo etiqueta",
      "Update": "Atualizar",
      "Update Proxy Contract's Implementation Address": "Atualizar Endereço de Implementação do Contrato Proxy",
      "Update Token Info": "Atualizar Informações do Token",
      "Updated": "Atualizado",
      "Updating": "Atualizando",
      "Upgrade": "Atualizar",
      "Upgraded": "Atualizado",
      "Upgrading": "Atualizando",
      "Upload": "Carregar",
      "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.": "Carregar todos os arquivos fonte de contrato Solidity e arquivo(s) de metadados JSON criados durante a compilação do contrato.",
      "Upload all Solidity or Yul contract source files": "Carregar todos os arquivos fonte de contrato Solidity ou Yul",
      "Upload the standard input JSON file created during contract compilation": "Carregar o arquivo JSON de entrada padrão criado durante a compilação do contrato",
      "Uploaded": "Carregado",
      "Uploading": "Carregando",
      "Uptime": "Tempo de atividade",
      "Us": "Nós",
      "Usage": "Uso",
      "Use": "Usar",
      "Used": "Usado",
      "User": "Usuário",
      "Users": "Usuários",
      "Using": "Usando",
      "Utilities": "Utilidades",
      "Utility": "Utilitário",
      "Validate": "Validar",
      "Validated": "Validado",
      "Validating": "Validando",
      "Validation": "Validação",
      "Value": "Valor",
      "Values": "Valores",
      "Variable": "Variável",
      "Variables": "Variáveis",
      "Variant": "Variante",
      "Variants": "Variantes",
      "Varying": "Variando",
      "Vault": "Cofre",
      "Vaults": "Cofres",
      "Vector": "Vetor",
      "Vectors": "Vetores",
      "Verification": "Verificação",
      "Verification Method": "Método de Verificação",
      "Verified": "Verificado",
      "Verified At": "Verificado Em",
      "Verified Contracts": "Contratos Verificados",
      "Verified Smart Contract": "Contrato Inteligente Verificado",
      "Verifying": "Verificando",
      "Version": "Versão",
      "Versions": "Versões",
      "Verify & Publish": "Verificar e Publicar",
      "Verify And Publish": "Verificar e Publicar",
      "Verify Contract": "Verificar Contrato",
      "View": "Visualizar",
      "View Blocks": "Visualizar Blocos",
      "Views": "Visualizações",
      "Viewing": "Visualizando",
      "Visible": "Visível",
      "Visit": "Visitar",


      "Visiting": "Visitando",
      "Visitor": "Visitante",
      "Visitors": "Visitantes",
      "Visual": "Visual",
      "Visuals": "Visuais",
      "Volume": "Volume",
      "Volume 24h": "Volume 24h",
      "Volumes": "Volumes",
      "Vote": "Votar",
      "Voted": "Votou",
      "Voter": "Eleitor",
      "Voters": "Eleitores",
      "Voting": "Votação",
      "Votes": "Votos",
      "Vyper Online Compiler": "Compilador Online Vyper",
      "WAITING": "ESPERANDO",
      "Wallet": "Carteira",
      "Wallet / Exchange / Project related issues": "Problemas Relacionados a Carteira / Troca / Projeto",
      "Wallets": "Carteiras",
      "Warning": "Aviso",
      "Warnings": "Avisos",
      "Watch": "Assistir",
      "Watching": "Assistindo",
      "Web": "Web",
      "Webpage": "Página da Web",
      "Webpages": "Páginas da Web",
      "Website": "Site",
      "Websites": "Sites",
      "Welcome": "Bem-vindo",
      "Welcome to Blocksscan Explorer Services": "Bem-vindo aos Serviços do Explorador Blocksscan",
      "Wellness": "Bem-estar",
      "We're Hiring": "Estamos Contratando",
      "What are the functions or methods available in this contract?": "Quais são as funções ou métodos disponíveis neste contrato?",
      "What does this contract do?": "O que este contrato faz?",
      "What is the logical structure of this contract's code?": "Qual é a estrutura lógica do código deste contrato?",
      "Where to find my API Key?": "Onde encontrar minha Chave API?",
      "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not": "Embora tentemos responder de forma oportuna, muitas vezes temos um acúmulo de consultas. Por favor, não",
      "White": "Branco",
      "Who": "Quem",
      "Whole": "Todo",
      "Wholesale": "Atacado",
      "Widget": "Widget",
      "Widgets": "Widgets",
      "Width": "Largura",
      "Widths": "Larguras",
      "Wiki": "Wiki",
      "Wikis": "Wikis",
      "Willing": "Disposto",
      "Win": "Ganhar",
      "Window": "Janela",
      "Windows": "Janelas",
      "Winning": "Ganhando",
      "Wins": "Vitórias",
      "Wire": "Fio",
      "Wires": "Fios",
      "Wiring": "Fiação",
      "Wisdom": "Sabedoria",
      "Wish": "Desejo",
      "Wished": "Desejado",
      "Wishes": "Desejos",
      "Wishing": "Desejando",
      "Withdraw": "Retirar",
      "Withdrawal": "Retirada",
      "Withdrawals": "Retiradas",
      "WithdrawalsRoot": "Raiz das Retiradas",
      "Withdrawing": "Retirando",
      "Withdrawn": "Retirado",
      "With": "Com",
      "Within": "Dentro",
      "Without": "Sem",
      "Witness": "Testemunha",
      "Witnessed": "Testemunhado",
      "Witnesses": "Testemunhas",
      "Witnessing": "Testemunhando",
      "Work": "Trabalho",
      "Worked": "Trabalhou",
      "Working": "Trabalhando",
      "Works": "Obras",
      "Workspace": "Espaço de Trabalho",
      "Workspaces": "Espaços de Trabalho",
      "World": "Mundo",
      "Worldwide": "Mundial",
      "Worry": "Preocupação",
      "Worried": "Preocupado",
      "Worries": "Preocupações",
      "Worrying": "Preocupante",
      "Worth": "Vale a pena",
      "Write": "Escrever",
      "Write Contract": "Escrever Contrato",
      "Write Proxy": "Escrever Proxy",
      "Write me a smart contract of voting.": "Escreva-me um contrato inteligente de votação.",
      "Writes": "Escreve",
      "Writing": "Escrevendo",
      "Written": "Escrito",
      "Wrong": "Errado",
      "Wrote": "Escreveu",
      "X (Twitter)": "X (Twitter)",
      "XML": "XML",
      "XRay": "Raio X",
      "Xrays": "Raios X",
      "Year": "Ano",
      "Years": "Anos",
      "Yellow": "Amarelo",
      "Yes": "Sim",
      "Yesterday": "Ontem",
      "Yield": "Rendimento",
      "Yielding": "Produzindo",
      "Yields": "Rendimentos",
      "You can Lookup the Account": "Você pode procurar a conta",
      "Your browser does not support the video tag.": "Seu navegador não suporta a tag de vídeo.",
      "Your private keys with anyone.": "Suas chaves privadas com qualquer pessoa.",
      "Your Rights": "Seus direitos",
      "Youth": "Juventude",
      "Zone": "Zona",
      "Zones": "Zonas",
      "Zoning": "Zoneamento",
      "Zoom": "Zoom",
      "Zoomed": "Zoom",
      "Zooming": "Zoom",
      "Zooms": "Zoom",
      "rsync-builder in 12 secs": "rsync-builder em 12 segundos",
      "rsyncbuilder": "rsync-builder",
      "sec": "Seg",
      "Tokens with a value of": "Tokens com um valor de",
      "Withdrawals in this block": "Retiradas neste bloco",
      "We": "Nós",
      "Our": "Nosso"
   }
};
