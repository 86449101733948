
export const fr = {
  translations: {
    "welcome": "Bienvenue",
    "Settings": "Paramètres",
    "This page allows you to customize your browsing experience on Blocksscan.": "Cette page vous permet de personnaliser votre expérience de navigation sur Blocksscan.",
    "Address": "Adresse",
    "Overview": "Aperçu",
    "Balance": "Solde",
    "Value": "Valeur",
    "More Info": "Plus d'infos",
    "Private Name Tags": "Étiquettes de nom privées",
    "Add": "Ajouter",
    "LAST TXN SENT": "Dernière TXN envoyée",
    "from": "de",
    "FIRST TXN SENT": "Première TXN envoyée",
    "Multichain Info": "Info Multichaîne",
    "day": "jour",
    "days": "jours",
    "hour": "heure",
    "hours": "heures",
    "minute": "minute",
    "minutes": "minutes",
    "second": "seconde",
    "seconds": "secondes",
    "ago": "il y a",
    "Multichain Portfolio": "Portefeuille Multichaîne",
    "Transactions": "Transactions",
    "Internal Transactions": "Transactions internes",
    "Transfers": "Transferts",
    "NFT Transfers": "Transferts de NFT",
    "Token Holding": "Détention de jetons",
    "Token Inventory": "Inventaire de jetons",
    "Events": "Événements",
    "Contract": "Contrat",
    "Rewards": "Récompenses",
    "Analytics": "Analytique",
    "Download": "Télécharger",
    "CSV Export": "Export CSV",
    "Tokens": "Jetons",
    "Chains": "Chaînes",
    "Hide Chains": "Masquer les chaînes",
    "Show More Chains": "Afficher plus de chaînes",
    "Hide All Chains": "Masquer toutes les chaînes",
    "Display All Chains": "Afficher toutes les chaînes",
    "Name Tag": "Étiquette de nom",
    "Percentage": "Pourcentage",
    "Capacity": "Capacité",
    "Owner": "Propriétaire",
    "Latest Signed Block": "Dernier bloc signé",
    "Status": "Statut",
    "Contract Name": "Nom du contrat",
    "Compiler": "Compilateur",
    "Version": "Version",
    "Txns": "Txns",
    "Verified": "Vérifié",
    "Audit": "Audit",
    "License": "Licence",
    "New Smart Contracts": "Nouveaux contrats intelligents",
    "New Verified Contracts": "Nouveaux contrats vérifiés",
    "Smart Contracts": "Contrats intelligents",
    "Verified Smart Contract": "Contrat intelligent vérifié",
    "Optimization": "Optimisation",
    "Constructor args": "Arguments du constructeur",
    "Verified Contracts": "Contrats vérifiés",
    "Solidity": "Solidité",
    "Accounts": "Comptes",
    "Block Height": "Hauteur du bloc",
    "ToDo": "À faire",
    "Finalized": "Finalisé",
    "Timestamp": "Horodatage",
    "Proposed On": "Proposé le",
    "Block proposed on epoch": "Bloc proposé sur l'époque",
    "Withdrawals": "Retraits",
    "withdrawals in this block": "retraits dans ce bloc",
    "Fee": "Frais",
    "Fee Recipient": "Bénéficiaire des frais",
    "rsync-builder in 12 secs": "rsync-builder en 12 secondes",
    "Block Reward": "Récompense de bloc",
    "Total Difficulty": "Difficulté totale",
    "Size": "Taille",
    "bytes": "octets",
    "Gas Used": "Gaz utilisé",
    "Gas Target": "Cible de gaz",
    "Gas Limit": "Limite de gaz",
    "Base Fee Per Gas": "Frais de base par gaz",
    "Burnt Fees": "Frais brûlés",
    "Extra Data": "Données supplémentaires",
    "rsyncbuilder": "rsyncbuilder",
    "Hash": "Hachage",
    "Parent Hash": "Hachage parent",


    "StateRoot": "Racine d'état",
    "WithdrawalsRoot": "Racine des retraits",
    "Nonce": "Nonce",
    "More Details": "Plus de détails",
    "Click to show less": "Cliquez pour afficher moins",
    "Click to show more": "Cliquez pour afficher plus",
    "Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block.":
      "Également connu sous le numéro de bloc. La hauteur du bloc, qui indique la longueur de la chaîne de blocs, augmente après l'ajout du nouveau bloc.",
    "The finality status of the block.": "Le statut de finalité du bloc.",
    "This block is finilized.": "Ce bloc est finalisé.",
    "The date and time at which a block is produced.":
      "La date et l'heure auxquelles un bloc est produit.",
    "Slot and epoch this block is proposed on.":
      "Emplacement et époque sur lesquels ce bloc est proposé.",
    "The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves Ether value.":
      "Le nombre de transactions dans le bloc. La transaction interne est les transactions résultant de l'exécution du contrat impliquant la valeur Ether.",
    "Number of beacon withdrawals in this block.":
      "Nombre de retraits de balise dans ce bloc.",
    "Address receiving fees from transactions in this block.":
      "Adresse recevant des frais des transactions dans ce bloc.",
    "For each block, the block producer is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.":
      "Pour chaque bloc, le producteur de bloc est récompensé par une quantité finie d'Ether en plus des frais payés pour toutes les transactions du bloc.",
    "Total difficulty of the chain until this block.":
      "Difficulté totale de la chaîne jusqu'à ce bloc.",
    "The block size is actually determined by the block's gas limit.":
      "La taille du bloc est en fait déterminée par la limite de gaz du bloc.",
    "The total gas used in the block and its percentage of gas filled in the block.":
      "Le gaz total utilisé dans le bloc et son pourcentage de gaz rempli dans le bloc.",
    "Total gas limit provided by all transactions in the block.":
      "Limite de gaz totale fournie par toutes les transactions dans le bloc.",
    "Post-London Upgrade, this represents the minimum gas Used multiplier required for a tx to be included in a block.":
      "Après la mise à niveau de Londres, cela représente le multiplicateur de gaz utilisé minimum requis pour qu'une tx soit incluse dans un bloc.",
    "Post-London Upgrade, this represents the part of the tx fee that is burnt: baseFeePerGas * gasUsed.":
      "Après la mise à niveau de Londres, cela représente la partie des frais de tx qui est brûlée : baseFeePerGas * gaz utilisé.",
    "Any data that can be included by the block producer in the block.":
      "Toute donnée pouvant être incluse par le producteur de blocs dans le bloc.",
    "The hash of the block header of the current block.":
      "Le hachage de l'en-tête du bloc du bloc actuel.",
    "The hash of the block from which this block was generated, also known as its parent block.":
      "Le hachage du bloc à partir duquel ce bloc a été généré, également connu sous le nom de bloc parent.",
    "The root of the state trie.": "La racine du trie d'état.",
    "Block header withdrawal root hash.":
      "Hachage de la racine de retrait de l'en-tête du bloc.",
    "Block nonce is a value used during mining to demonstrate proof of work for a block.":
      "Le nonce de bloc est une valeur utilisée pendant le minage pour démontrer la preuve de travail pour un bloc.",
    "Block": "Bloc",
    "Blocks": "Blocs",
    "Age": "Âge",
    "Node": "Nœud",
    "Base Fee": "Frais de base",
    "See preview of the transaction details.":
      "Voir l'aperçu des détails de la transaction.",
    "Additional Info": "Informations supplémentaires",
    "confirmation": "Confirmation",
    "Transaction Action": "Action de transaction",
    "Transfer": "Transférer",
    "To": "À",
    "Transaction Fee": "Frais de transaction",
    "Gas Info": "Info Gaz",
    "See more details": "Voir plus de détails",
    "Tx Hash": "Hachage Tx",
    "Type": "Type",
    "From": "De",
    "Tx Fee": "Frais Tx",
    "in the position": "dans la position",
    "A TxHash or transaction hash is a unique 66-character identifier that is generated whenever a transaction is executed.":
      "Un TxHash ou hachage de transaction est un identifiant unique de 66 caractères généré chaque fois qu'une transaction est exécutée.",
    "Transaction Hash": "Hachage de transaction",
    "TxHash to clipboard": "TxHash au presse-papiers",
    "The status of the transaction.": "Le statut de la transaction.",
    "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.":
      "Nombre du bloc dans lequel la transaction est enregistrée. Les confirmations de blocs indiquent combien de blocs ont été ajoutés depuis la production de la transaction.",
    "Confirmation": "Confirmation",
    "Pending...": "En attente...",
    "The date and time at which a transaction is produced.":
      "La date et l'heure auxquelles une transaction est produite.",
    "Confirmed within": "Confirmé dans",
    "sec": "sec",
    "Highlighted events of the transaction.":
      "Événements marquants de la transaction.",
    "The sending party of the transaction.": "La partie envoyant la transaction.",
    "The receiving party of the transaction (could be a contract address).":
      "La partie recevant la transaction (peut être une adresse de contrat).",
    "created": "Créé",
    "List of {{_tokenType}}20 tokens transferred in the transaction.":
      "Liste des jetons {{_tokenType}}20 transférés dans la transaction.",
    "Tokens Transferred": "Jetons transférés",
    "For": "Pour",
    " tokens transferred in the transaction.":
      "Jetons transférés dans la transaction.",
    "List of": "Liste de",
    "Token ID": "ID de jeton",
    "List of tokens mint transferred in the transaction.":
      "Liste des jetons frappés transférés dans la transaction.",
    "Tokens minted": "Jetons frappés",
    "List of tokens burnt transferred in the transaction.":
      "Liste des jetons brûlés transférés dans la transaction.",
    "Tokens burnt": "Jetons brûlés",
    "The value being transacted in Ether and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction.":
      "La valeur transigée en Ether et en valeur fiat. Remarque : Vous pouvez cliquer sur la valeur fiat (si disponible) pour voir la valeur historique au moment de la transaction.",
    "Amount paid to process the transaction in Ether and fiat value.":
      "Montant payé pour traiter la transaction en Ether et en valeur fiat.",
    "Actual Tx Cost/Fee": "Coût/frais Tx réel",
    "Cost per unit of gas spent for the transaction, in Ether and Gwei.":
      "Coût par unité de gaz dépensé pour la transaction, en Ether et Gwei.",
    "Gas Price": "Prix du gaz",
    "Maximum amount of gas allocated for the transaction & the amount eventually used. Normal ETH transfers involve 21,000 gas units while contracts involve higher values.":
      "Quantité maximale de gaz allouée pour la transaction et la quantité finalement utilisée. Les transferts ETH normaux impliquent 21 000 unités de gaz tandis que les contrats impliquent des valeurs plus élevées.",
    "Gas Limit & Usage by Txn": "Limite de gaz et utilisation par Txn",
    "Base Fee refers to the network Base Fee at the time of the block, while Max Fee & Max Priority Fee refer to the max amount a user is willing to pay for their tx & to give to the block producer respectively.":
      "Les frais de base se réfèrent aux frais de base du réseau au moment du bloc, tandis que les frais maximaux et les frais de priorité maximale se réfèrent au montant maximum qu'un utilisateur est prêt à payer pour sa tx et à donner respectivement au producteur de blocs.",
    "Gas Fees": "Frais de gaz",
    "Max": "Max",
    "Max Priority": "Priorité maximale",


    "Base": "Base",
    "Total amount of ETH burnt from this tx & total fees saved from the amount the user was willing to pay for this tx.":
      "Quantité totale d'ETH brûlée de cette tx et frais totaux économisés par rapport à la somme que l'utilisateur était prêt à payer pour cette tx.",
    "Burnt & Txn Savings Fees": "Frais économisés de Txn brûlés",
    "Burnt": "Brûlé",
    "Txn Savings": "Économies de Txn",
    "Other data related to this transaction.":
      "Autres données liées à cette transaction.",
    "Other Attributes": "Autres attributs",
    "Txn Type": "Type de Txn",
    "Position": "Position",
    "Legacy": "Héritage",
    "Input Data": "Données d'entrée",
    "Hex": "Hex",
    "to clipboard": "au presse-papiers",
    "Decode input data": "Décoder les données d'entrée",
    "Method id": "ID de méthode",
    "Call": "Appel",
    "Name": "Nom",
    "Data": "Données",
    "Logdata to clipboard": "Données de journal au presse-papiers",
    "Address to clipboard": "Adresse au presse-papiers",
    "Indexed": "Indexé",
    "Topics": "Sujets",
    "Text": "Texte",
    "Number": "Nombre",
    "TO": "À",
    "number": "nombre",
    "Tx row trace to clipboard": "Trace de ligne Tx au presse-papiers",
    "No trace entries found.": "Aucune entrée de trace trouvée.",
    "Before": "Avant",
    "After": "Après",
    "State Diffrence": "Différence d'état",
    "Token": "Jeton",
    "Transaction Details": "Détails de la transaction",
    "Token transfers": "Transferts de jetons",
    "Logs": "Journaux",
    "state": "état",
    "Raw trace": "Trace brute",
    "Method": "Méthode",
    "Careers at BlocksScan": "Carrières chez BlocksScan",
    "Join BlocksScan and enhance the world's leading blockchain explorer and search, API & analytics platform.":
      "Rejoignez BlocksScan et améliorez l'explorateur de blockchain leader mondial et la plateforme de recherche, d'API et d'analyse.",
    "Perks and Benefits": "Avantages et bénéfices",
    "Ours isn’t just a grand vision. We back it up with the company and culture tailored for realizing it.":
      "Le nôtre n'est pas seulement une grande vision. Nous le soutenons avec l'entreprise et la culture adaptées pour le réaliser.",
    "Free Flow Drinks & Snacks": "Boissons et snacks en libre-service",
    "Never worry about a lack of refreshments or tasty morsels to munch on while adding value to the team.":
      "Ne vous inquiétez jamais du manque de rafraîchissements ou de bouchées savoureuses à grignoter tout en apportant de la valeur à l'équipe.",
    "Best Coffee in Town": "Meilleur café en ville",
    "State-of-the-art coffee machine and experienced baristas in the house for the coffee lovers to thrive.":
      "Machine à café de pointe et baristas expérimentés dans la maison pour que les amateurs de café s'épanouissent.",
    "Annual Trips": "Voyages annuels",
    "Enjoy a break from the daily grind with sponsored trips to cool new locations and experiences.":
      "Profitez d'une pause dans la routine quotidienne avec des voyages sponsorisés vers de nouveaux endroits et expériences cool.",
    "Office Entertainment": "Divertissement de bureau",
    "Games and gadgets to distract yourself with while thinking up novel solutions to hard problems.":
      "Jeux et gadgets pour vous distraire tout en réfléchissant à des solutions novatrices aux problèmes difficiles.",
    "Food Allowance": "Indemnité alimentaire",
    "Extra lunch money provided along with the occasional team lunch and dinners.":
      "De l'argent supplémentaire pour le déjeuner fourni avec les déjeuners et dîners d'équipe occasionnels.",
    "Commute Allowance": "Indemnité de transport",
    "Concerns with transportation and parking eased so that team members can focus on delivering value.": "Les préoccupations liées au transport et au stationnement sont atténuées afin que les membres de l'équipe puissent se concentrer sur la création de valeur.",
    "Flex Benefits": "Avantages flexibles",
    "For almost anything that improves your life - training courses, fitness plans... even beauty facials!":
      "Pour presque tout ce qui améliore votre vie - cours de formation, plans de fitness... même des soins du visage !",
    "Medical Benefits": "Avantages médicaux",
    "Coverage of outpatient, dental and optical services from a registered clinic of your choice.":
      "Couverture des services ambulatoires, dentaires et optiques d'une clinique enregistrée de votre choix.",
    "Flexible working hours": "Horaires de travail flexibles",
    "Contribute to the team effort without worrying about what time to clock in.":
      "Contribuez à l'effort d'équipe sans vous soucier de l'heure à laquelle vous pointer.",
    "Learning Sessions": "Séances d'apprentissage",
    "Keep up with the latest breakthroughs and best practices in the industry with learned and experienced teammates.":
      "Restez à jour avec les dernières avancées et les meilleures pratiques de l'industrie avec des coéquipiers instruits et expérimentés.",
    "Working at BlocksScan": "Travailler chez BlocksScan",
    "BlocksScan is a team pioneering at the forefront of the blockchain industry. Our platform provides the infrastructure for users and developers to access and build decentralized applications powering billions of dollars in value.":
      "BlocksScan est une équipe pionnière à l'avant-garde de l'industrie de la blockchain. Notre plateforme fournit l'infrastructure permettant aux utilisateurs et aux développeurs d'accéder et de construire des applications décentralisées alimentant des milliards de dollars en valeur.",
    "We are made up of diverse backgrounds and skillsets, with a combined passion for providing equitable access to open financial systems. While we strive for excellence in all that we do, we don't take ourselves too seriously either. We love to explore new frontiers and have fun in doing so.":
      "Nous sommes composés de divers antécédents et compétences, avec une passion commune pour fournir un accès équitable aux systèmes financiers ouverts. Bien que nous nous efforcions d'exceller dans tout ce que nous faisons, nous ne nous prenons pas trop au sérieux non plus. Nous aimons explorer de nouvelles frontières et nous amuser en le faisant.",
    "Understand Contract Functionalities":
      "Comprendre les fonctionnalités du contrat",
    "What are the functions or methods available in this contract?":
      "Quelles sont les fonctions ou méthodes disponibles dans ce contrat ?",
    "Design a WithdrawBalance() method to withdraw contract balance.":
      "Concevez une méthode WithdrawBalance() pour retirer le solde du contrat.",
    "What is the logical structure of this contract's code?":
      "Quelle est la structure logique du code de ce contrat ?",
    "Is this a proxy contract?": "Est-ce un contrat proxy ?",
    "Write me a smart contract of voting.":
      "Écrivez-moi un contrat intelligent de vote.",
    "What does this contract do?": "Que fait ce contrat ?",
    "How does SafeMath change the contract's state?":
      "Comment SafeMath change-t-il l'état du contrat ?",
    "Code Reader": "Lecteur de code",
    "NEW": "NOUVEAU",
    "Understand and use smart contracts better with CodeRunAI.":
      "Comprenez et utilisez mieux les contrats intelligents avec CodeRunAI.",
    "Mode": "Mode",
    "I have an API Key": "J'ai une clé API",
    "Any open source contract": "Tout contrat open source",
    "Any question": "Toute question",
    "I don't have an API Key": "Je n'ai pas de clé API",
    "Popular open source contracts": "Contrats open source populaires",
    "Example questions": "Questions exemples",
    "DEMO": "DÉMO",
    "OpenAI API key": "Clé API OpenAI",
    "Where to find my API Key?": "Où trouver ma clé API ?",
    "Save it to the browser": "Enregistrer dans le navigateur",
    "GPT Model": "Modèle GPT",
    "Smart Contract": "Contrat Intelligent",
    "Contract Address": "Adresse du Contrat",
    "Contract Code": "Code du Contrat",
    "Load": "Charger",
    "Trending": "Tendance",
    "Contract Files": "Fichiers de Contrat",
    "Source Code": "Code Source",
    "Popular Contracts": "Contrats Populaires",
    "No Record Found": "Aucun Enregistrement Trouvé",
    "Loading...": "Chargement...",
    "Hi, there.": "Salut là.",
    "I can help you in the following scenarios":
      "Je peux vous aider dans les scénarios suivants",
    "ME": "MOI",
    "Enter any question you are interested in":
      "Entrez toute question qui vous intéresse",
    "Signer": "Signataire",
    "Send native": "Envoyer natif",
    "Add one more": "Ajouter encore un",
    "Read": "Lire",
    "Write": "Écrire",
    "Loading": "Chargement",
    "Method Name": "Nom de la Méthode",
    "Permalink": "Permalien",
    "Code": "Code",
    "Read Contract": "Lire le Contrat",
    "Read Proxy": "Lire le Proxy",
    "Write Contract": "Écrire le Contrat",
    "Write Proxy": "Écrire le Proxy",
    "Contract Source Code Verified": "Code Source du Contrat Vérifié",
    "Are you the contract creator": "Êtes-vous le créateur du contrat",
    "Verify And Publish": "Vérifier et Publier",
    "your contract source code today": "votre code source de contrat aujourd'hui",
    "EVM Version": "Version EVM",
    "Optimization Runs": "Exécutions d'Optimisation",
    "Contract File Path": "Chemin du Fichier de Contrat",
    "Compiler Version": "Version du Compilateur",
    "Optimization Enabled": "Optimisation Activée",
    "Verified At": "Vérifié À",
    "Constructor Arguments": "Arguments du Constructeur",
    "ABI-encoded": "Encodé ABI",
    "Byte Code": "Code Binaire",
    "File": "Fichier",
    "of": "de",
    "ABI for the implementation contract at": "ABI pour le contrat d'implémentation à",
    "Type of Backing": "Type de Soutien",
    "Type of Peg": "Type de Fixation",
    "Collater­alization": "Collatéralisation",
    "Storage Of Assets": "Stockage des Actifs",
    "Market": "Marché",
    "Market Capitalization": "Capitalisation Boursière",
    "Circulating Supply": "Offre en Circulation",
    "Click here to": "Cliquez ici pour",
    "update": "mettre à jour",
    "the token information / general information": "l'information sur le jeton / l'information générale",
    "Metadata": "Métadonnées",
    "Missing": "Manquant",
    "Chain": "Chaîne",
    "Portfolio %": "Portefeuille %",
    "Price": "Prix",
    "Amount": "Montant",
    "Showing": "Affichage",
    "tokens with a value of": "jetons d'une valeur de",
    "Sponsored": "Sponsorisé",
    "Complier": "Compilateur",
    "Token Name": "Nom du Jeton",
    "Total Holders": "Total des Détenteurs",
    "Collection Name": "Nom de la Collection",
    "Validator": "Validateur",
    "in": "dans",
    "View": "Vue",
    "Latest Transactions": "Dernières Transactions",
    "Latest Blocks": "Derniers Blocs",
    "Customize": "Personnaliser",
    "NFT Address": "Adresse NFT",
    "all Blocks": "tous les Blocs",
    "Top Tokens": "Principaux Jetons",
    "Top Contracts": "Principaux Contrats",
    "Top NFTs": "Principaux NFTs",
    "Ask AI | Search by Address | Txn Hash | Block | Token... ":
      "Rechercher par adresse / hachage txn / bloc / jeton...",
    "No transactions found.": "Aucune transaction trouvée.",
    "Market Cap": "Cap. Marché",
    "Total Transactions": "Total des Transactions",
    "MED GAS PRICE": "PRIX MED GAS",
    "TOTAL BLOCK": "BLOC TOTAL",
    "GasTracker Estimate for Average Gas":
      "Estimation GasTracker pour Gaz Moyen",
    "Total Accounts": "Total des Comptes",
    "Daily Transactions": "Transactions Quotidiennes",
    "Contact Us": "Contactez-nous",


    "Feedback Form": "Formulaire de Retour d'Information",
    "Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that":
      "Veuillez comprendre que nous ne pouvons aider qu'avec les problèmes directement liés aux services d'exploration de blocs. Avant d'écrire, veuillez être informé que",
    "Pending Transaction": "Transaction en Attente",
    "We do not": "Nous ne",
    "process transactions": "traitons les transactions",
    "and are therefore unable to expedite, cancel or replace them. If you are having issues with pending transactions, please refer":
      "et ne pouvons donc pas les accélérer, les annuler ou les remplacer. Si vous rencontrez des problèmes avec les transactions en attente, veuillez vous référer",
    "here": "ici",
    "Blocksscan is an independent": "Blocksscan est indépendant",
    "block explorer": "explorateur de blocs",
    "unrelated to other service providers (unless stated explicitly otherwise) and is therefore unable to provide a precise response for inquiries that are specific to other service providers.":
      "sans lien avec d'autres fournisseurs de services (sauf indication explicite contraire) et ne peut donc pas fournir une réponse précise aux demandes spécifiques à d'autres fournisseurs de services.",
    "Knowledge Base": "Base de Connaissances",
    "The links on the right might already have the answer to your inquiry. If they don't, perhaps one of the other articles in our":
      "Les liens à droite peuvent déjà avoir la réponse à votre demande. Sinon, peut-être que l'un des autres articles dans notre",
    "page does.": "page le fait.",
    "Community Support": "Support Communautaire",
    "We will never ask for your private keys or passwords.":
      "Nous ne demanderons jamais vos clés privées ou mots de passe.",
    "NEVER send us these.": "NE LES ENVOYEZ JAMAIS.",
    "While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not":
      "Bien que nous fassions de notre mieux pour répondre en temps opportun, nous avons souvent un arriéré de demandes. Veuillez ne pas",
    "spam us": "nous spammer",
    "Wallet / Exchange / Project related issues":
      "Problèmes liés au portefeuille / à l'échange / au projet",
    "Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.":
      "Veuillez contacter votre fournisseur de services de portefeuille, les échanges ou le propriétaire du projet/contrat pour un soutien supplémentaire car ils sont mieux placés pour vous aider concernant les problèmes liés à leurs plateformes et en provenance de celles-ci.",
    "Subject": "Sujet",
    "Please Select Your Message Subject": "Veuillez sélectionner le sujet de votre message",
    "General Inquiry": "Demande Générale",
    "Advertising": "Publicité",
    "Update Token Info": "Mettre à Jour les Informations sur le Jeton",
    "Add Name Tag/Label": "Ajouter une Étiquette/Nom",
    "Request Removal of Name Tag": "Demander la Suppression de l'Étiquette",
    "Suggest Transaction Action": "Suggérer une Action de Transaction",
    "Update Proxy Contract's Implementation Address":
      "Mettre à Jour l'Adresse de Mise en Œuvre du Contrat Proxy",
    "Report Phishing Address": "Signaler une Adresse de Hameçonnage",
    "Security Audit": "Audit de Sécurité",
    "Priority Support": "Support Prioritaire",
    "API Support": "Support API",
    "1. Inquiries": "1. Demandes",
    "2. Submissions": "2. Soumissions",
    "3. Security": "3. Sécurité",
    "Help & Support": "Aide et Support",
    "24/7 support. Our team is here to help you and make sure your product is up to date.":
      "Support 24/7. Notre équipe est là pour vous aider et s'assurer que votre produit est à jour.",
    "Sales": "Ventes",
    "Have a presale question about our products and features? Or looking for a refund?":
      "Vous avez une question prévente sur nos produits et fonction   nalités ? Ou vous cherchez un remboursement ? ",
    "Media & Press": "Médias et Presse",
    "Online awards and publications. Get our online media resources and learn about our company.":
      "Récompenses et publications en ligne. Obtenez nos ressources médiatiques en ligne et apprenez à connaître notre entreprise.",
    "Tx Hash|Block|Age": "Hachage Tx|Bloc|Âge",
    "12 days ago": "Il y a 12 jours",
    "Event Logs": "Journaux d'Événements",
    "Mint": "Frappé",
    "Transaction": "Transaction",
    "Success": "Succès",
    "Failed": "Échoué",
    "Pending": "En attente",
    "Contract call": "Appel de contrat",
    "Contract creation": "Création de contrat",
    "Token transfer": "Transfert de jeton",
    "Token creation": "Création de jeton",
    "Coin transfer": "Transfert de pièce",
    "REMASC": "REMASC",
    "Bridge": "Pont",
    "Rank": "Rang",
    "Quantity": "Quantité",
    "Item": "Article",
    "Details": "Détails",
    "ID": "ID",
    "Last Traded": "Dernier Échangé",
    "Estimated Value on Day of Txn": "Valeur Estimée le Jour de la Txn",
    "Displaying current Value; Click to show Value on Day of Txn":
      "Affichage de la valeur actuelle ; Cliquez pour afficher la valeur le jour de la Txn",
    "Action": "Action",
    "Verify & Publish Contract Source Code": "Vérifier & Publier le Code Source du Contrat",
    "COMPILER TYPE AND VERSION SELECTION":
      "SÉLECTION DU TYPE ET DE LA VERSION DU COMPILATEUR",
    "Contract Licence": "Licence de Contrat",
    "For best practices, all contract source code holders, publishers and authors are encouraged to also specify the accompanying license for their verified contract source code provided.":
      "Pour de meilleures pratiques, il est encouragé à tous les détenteurs, éditeurs et auteurs de code source de contrat de spécifier également la licence accompagnant le code source de contrat vérifié fourni.",
    "Please select": "Veuillez sélectionner",
    "Verification Method": "Méthode de Vérification",
    "Compiler Type": "Type de Compilateur",
    "Enter name here...": "Entrez le nom ici...",
    "Is Yul Contract": "Est-ce un Contrat Yul",
    "The compiler version is specified in pragma solidity X.X.X. Use the compiler version rather than the nightly build. If using the Solidity compiler, run solc —version to check.":
      "La version du compilateur est spécifiée dans pragma solidité X.X.X. Utilisez la version du compilateur plutôt que la version de nuit. Si vous utilisez le compilateur Solidity, exécutez solc —version pour vérifier.",
    "The EVM version the contract is written for. If the bytecode does not match the version, we try to verify using the latest EVM version.":
      "La version EVM pour laquelle le contrat est écrit. Si le bytecode ne correspond pas à la version, nous essayons de vérifier en utilisant la dernière version EVM.",
    "Type here ...": "Tapez ici ...",
    "We recommend using flattened code. This is necessary if your code utilizes a library or inherits dependencies. Use the POA solidity flattener or the Truffle flattener.":
      "Nous recommandons d'utiliser un code aplati. Ceci est nécessaire si votre code utilise une bibliothèque ou hérite de dépendances. Utilisez l'aplatisseur de solidité POA ou l'aplatisseur Truffle.",
    "Enter Source Code...": "Entrez le Code Source...",
    "Standard JSON Input": "Entrée JSON Standard",
    "Sources and Metadata JSON": "Sources et Métadonnées JSON",
    "Sources *.sol or *.yul files": "Sources fichiers *.sol ou *.yul",
    "Main Interfaces (.vy or .json)": "Interfaces Principales (.vy ou .json)",
    "Upload the standard input JSON file created during contract compilation":
      "Téléchargez le fichier JSON d'entrée standard créé lors de la compilation du contrat",
    "Upload all Solidity contract source files and JSON metadata file(s) created during contract compilation.":
      "Téléchargez tous les fichiers source de contrat Solidity et les fichiers de métadonnées JSON créés lors de la compilation du contrat.",
    "Upload all Solidity or Yul contract source files":
      "Téléchargez tous les fichiers source de contrat Solidity ou Yul",
    "Primary compiled Vyper contract. Only add the main contract here whose bytecode has been deployed, all other files can be uploaded to the interfaces box below":
      "Contrat Vyper compilé primaire. Ajoutez uniquement ici le contrat principal dont le bytecode a été déployé, tous les autres fichiers peuvent être téléchargés dans la boîte des interfaces ci-dessous",
    "Drag & drop files here, or click to select files":
      "Glissez-déposez des fichiers ici, ou cliquez pour sélectionner des fichiers",
    "Verify & Publish": "Vérifier & Publier",
    "Reset": "Réinitialiser",
    "Gas tracker": "Suivi du gaz",
    "Network utilization": "Utilisation du réseau",
    "low load": "Faible charge",
    "Last updated": "Dernière mise à jour",
    "Fast": "Rapide",
    "Normal": "Normal",
    "Slow": "Lent",
    "Gas price history": "Historique du prix du gaz",
    "Charts & stats": "Graphiques & statistiques",
    "Account Balance Checker": "Vérificateur de Solde de Compte",
    "You can Lookup the Account": "Vous pouvez Rechercher le Compte",
    "Historical Balance at a specific Block No or Date.":
      "Solde Historique à un Numéro de Bloc ou une Date spécifique.",
    "Account": "Compte",
    "Date for the snapshot": "Date pour l'instantané",
    "Block Number": "Numéro de Bloc",
    "Lookup": "Recherche",
    "Choose desired language": "Choisissez la langue souhaitée",
    "Choose the theme": "Choisissez le thème",
    "Dark Mode": "Mode Sombre",
    "Do you want to show relative time? eg.": "Voulez-vous afficher le temps relatif ? par ex.",
    "Do you want to show UTC time? eg.": "Voulez-vous afficher l'heure UTC ? par ex.",
    "Change Fiat Currency": "Changer de Devise Fiat",
    "Use": "Utiliser",
    "prefix instead of": "préfixe au lieu de",
    "NFT Latest Mints": "Dernières Frappes NFT",
    "Token Id": "ID de Jeton",
    "NFT Transfer": "Transfert NFT",
    "Your browser does not support the video tag.":
      "Votre navigateur ne prend pas en charge la balise vidéo.",
    "Min Price": "Prix Min",
    "Last Sale": "Dernière Vente",
    "Current owner of this NFT.": "Propriétaire actuel de ce NFT.",
    "Address of this NFT contract.": "Adresse de ce contrat NFT.",
    "Address that deployed this NFT contract.":
      "Adresse qui a déployé ce contrat NFT.",
    "Creator": "Créateur",
    "Classification of NFT based on how the image is stored: On-Chain refers to those with data stored on the blockchain while Off-Chain refers to those with data stored elsewhere on the Internet.":
      "Classification des NFT basée sur la manière dont l'image est stockée : On-Chain fait référence à ceux dont les données sont stockées sur la blockchain tandis que Off-Chain fait référence à ceux dont les données sont stockées ailleurs sur Internet.",
    "Classification": "Classification",
    "This NFT's unique token ID.": "ID de jeton unique de ce NFT.",
    "The standard followed by this NFT.": "Le standard suivi par ce NFT.",
    "Token Standard": "Standard de Jeton",
    "Marketplaces trading this NFT.": "Places de marché échangeant ce NFT.",
    "Marketplaces": "Places de marché",
    "Properties": "Propriétés",
    "Description": "Description",
    "Collection": "Collection",
    "Volume": "Volume",
    "Change": "Changement",
    "Max Price": "Prix Max",
    "NFT Top Mints": "Top Frappes NFT",
    "Owners": "Propriétaires",
    "Total Assets": "Actifs Totals",
    "Note: Filtered by  totalSupply() , method returned a zero value.":
      "Note : Filtré par totalSupply(), la méthode a renvoyé une valeur nulle.",
    "A Paid Service": "Un Service Payant",
    "At BlocksScan, we handle a substantial volume of support requests daily. While we strive our best to respond to each one in a timely manner, we recognize that certain projects may want to submit their token information quicker than our standard turnaround time.":
      "Chez BlocksScan, nous traitons un volume substantiel de demandes de soutien quotidiennement. Bien que nous fassions de notre mieux pour répondre à chacune en temps opportun, nous reconnaissons que certains projets peuvent vouloir soumettre leurs informations de jeton plus rapidement que notre délai de réponse standard.",
    "In addition to expediting token updates, we also provide priority support for other urgent or technical matters, such as contract verification.":
      "En plus d'accélérer les mises à jour de jetons, nous fournissons également un soutien prioritaire pour d'autres questions urgentes ou techniques, telles que la vérification de contrats.",
    "Kindly follow the simple steps below to get your support prioritized.":
      "Veuillez suivre les étapes simples ci-dessous pour que votre soutien soit priorisé.",
    "Get Started": "Commencer",
    "How to Start": "Comment Commencer",
    "Simple steps for getting Priority Support.":
      "Étapes simples pour obtenir un Soutien Prioritaire.",
    "Step 1": "Étape 1",
    "Check Token Information": "Vérifier les Informations sur le Jeton",
    "Ensure that token information is submitted. Check our token update guidelines":
      "Assurez-vous que les informations sur le jeton sont soumises. Consultez nos directives de mise à jour des jetons",
    "Step 2": "Étape 2",
    "Provide Token Update Request": "Fournir une Demande de Mise à Jour du Jeton",
    "Provide us with the token update request information in the contact form below.":
      "Fournissez-nous les informations de demande de mise à jour du jeton dans le formulaire de contact ci-dessous.",
    "Step 3": "Étape 3",
    "We'll Review Your Request": "Nous Examinerons Votre Demande",
    "Our team will review your request and contact you using an official":
      "Notre équipe examinera votre demande et vous contactera en utilisant un",
    "Please use this form to get in touch for priority support or featured listing with our team.":
      "Veuillez utiliser ce formulaire pour prendre contact pour un soutien prioritaire ou une liste en vedette avec notre équipe.",
    "Requester Name": "Nom du Demandeur",
    "Requester Email": "Email du Demandeur",
    "Token Contract Address": "Adresse du Contrat de Jeton",
    "Choose Priority Service": "Choisir un Service Prioritaire",
    "Token Update": "Mise à Jour du Jeton",
    "Featured Listing": "Liste en Vedette",
    "Contract Verification": "Vérification de Contrat",
    "Others": "Autres",
    "Learn more about": "En savoir plus sur",
    "Helpdesk Ticket Number": "Numéro de Ticket d'Assistance",
    "Additional Notes": "Notes Supplémentaires",
    "Note": "Note",
    "We will": "Nous",
    "NOT": "Ne",
    "request payment via direct deposit. All payments will be directed through our official payment channel.":
      "demandons pas de paiement par dépôt direct. Tous les paiements seront dirigés par notre canal de paiement officiel.",
    "NEVER": "Jamais",
    "ask for your private key or seed words. Please":
      "demandez votre clé privée ou vos mots de graine. S'il vous plaît",
    "DO NOT SHARE": "NE PARTAGEZ PAS",
    "your private keys with anyone.": "vos clés privées avec qui que ce soit.",
    "Send Message": "Envoyer le Message",
    "Ex. John Doe": "Ex. John Doe",
    "If you have any additional notes, please leave them here":
      "Si vous avez des notes supplémentaires, veuillez les laisser ici",
    "Privacy Policy": "Politique de Confidentialité",
    "Welcome to Blocksscan Explorer Services":
      "Bienvenue chez les Services d'Exploration de Blocksscan",
    "we": "nous",
    "us": "nous",
    "our": "notre",
    "We are committed to respecting the privacy and security of our users' information. This Privacy Policy outlines the types of information we may collect from you or that you may provide when you use our services, and our practices for collecting, using, maintaining, protecting, and disclosing that information.":
      "Nous nous engageons à respecter la confidentialité et la sécurité des informations de nos utilisateurs. Cette Politique de Confidentialité décrit les types d'informations que nous pouvons collecter auprès de vous ou que vous pouvez fournir lorsque vous utilisez nos services, et nos pratiques pour collecter, utiliser, maintenir, protéger et divulguer ces informations.",
    "Information We Collect": "Informations que Nous Collectons",
    "We collect several types of information from and about users of our Blocksscan Explorer Services, including":
      "Nous collectons plusieurs types d'informations de et sur les utilisateurs de nos Services d'Exploration de Blocksscan, y compris",
    "Personal Information: Data that personally identifies you, such as your name, email address, and payment details, provided at registration or during the use of our services.":
      "Informations Personnelles : Données qui vous identifient personnellement, telles que votre nom, adresse email, et détails de paiement, fournies lors de l'inscription ou pendant l'utilisation de nos services.",
    "Non-Personal Information: Data that does not personally identify you but is linked to your usage of our services, such as IP addresses, browser types, and access times.":
      "Informations Non Personnelles : Données qui ne vous identifient pas personnellement mais sont liées à votre utilisation de nos services, telles que les adresses IP, les types de navigateurs et les heures d'accès.",
    "Transactional Information: Data related to transactions conducted on the network, which may include public blockchain data.":
      "Informations Transactionnelles : Données liées aux transactions effectuées sur le réseau, qui peuvent inclure des données de blockchain publiques.",
    "How We Use Your Information": "Comment Nous Utilisons Vos Informations",
    "We use the information that we collect about you or that you provide to us, including any personal information":
      "Nous utilisons les informations que nous collectons sur vous ou que vous nous fournissez, y compris toute information personnelle",
    "To present our services and its contents to you.":
      "Pour vous présenter nos services et leurs contenus.",
    "To provide you with information, products, or services that you request from us.":
      "Pour vous fournir des informations, produits ou services que vous nous demandez.",
    "To fulfill any other purpose for which you provide it.":
      "Pour remplir toute autre finalité pour laquelle vous le fournissez.",
    "To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.":
      "Pour remplir nos obligations et faire valoir nos droits découlant de tout contrat conclu entre vous et nous.",
    "To notify you about changes to our service or any products or services we offer or provide through it.":
      "Pour vous informer des changements apportés à notre service ou à tout produit ou service que nous offrons ou fournissons par son intermédiaire.",
    "For any other purpose with your consent.":
      "Pour toute autre finalité avec votre consentement.",
    "Data Security": "Sécurité des Données",
    "Data Retention": "Conservation des Données",
    "We will retain your personal information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.": "Nous conserverons vos informations personnelles aussi longtemps que votre compte est actif ou selon le besoin pour vous fournir des services. Nous conserverons et utiliserons vos informations selon le nécessaire pour respecter nos obligations légales, résoudre les litiges et faire respecter nos accords.",
    "Your Rights": "Vos Droits",
    "You have the right to request access to, correct, or delete any personal information that you have provided to us. You may also object to or request restrictions on the processing of your personal information.": "Vous avez le droit de demander l'accès, la correction ou la suppression de toute information personnelle que vous nous avez fournie. Vous pouvez également vous opposer au traitement de vos informations personnelles ou demander des restrictions sur ce traitement.",
    "Changes to Our Privacy Policy": "Modifications de Notre Politique de Confidentialité",
    "It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting our website and this privacy policy to check for any changes.": "Il est de notre politique de publier toute modification apportée à notre politique de confidentialité sur cette page. La date de la dernière révision de la politique de confidentialité est indiquée en haut de la page. Vous êtes responsable de nous fournir une adresse e-mail à jour, active et valide, et de visiter périodiquement notre site Web et cette politique de confidentialité pour vérifier les éventuelles modifications.",
    "Contact Information": "Informations de Contact",
    "To ask questions or comment about this privacy policy and our privacy practices, contact us at": "Pour poser des questions ou commenter cette politique de confidentialité et nos pratiques en matière de confidentialité, contactez-nous à",
    "By using Blocksscan Explorer Services, you consent to our Privacy Policy.": "En utilisant les Services d'Exploration Blocksscan, vous consentez à notre Politique de Confidentialité.",
    "Max Total Supply": "Approvisionnement Total Maximum",
    "Note: Filtered by  totalSupply() , method returned a zero value": "Remarque : Filtré par totalSupply(), la méthode a renvoyé une valeur nulle",
    "Holders": "Détenteurs",
    "Total Transfers": "Transferts Totals",
    "Implementation": "Implémentation",
    "Fully Diluted Market Cap": "Capitalisation Boursière Entièrement Diluée",
    "Circulating Supply Market Cap": "Capitalisation Boursière de l'Offre en Circulation",
    "Other Info": "Autres Informations",
    "Token Contract": "Contrat de Jeton",
    "FILTERED BY TOKEN HOLDER": "FILTRÉ PAR DÉTENTEUR DE JETON",
    "BALANCE": "SOLDE",
    "Inventory": "Inventaire",
    "Info": "Infos",
    "With": "Avec",
    "decimals": "décimales",
    "Volume 24h": "Volume 24h",
    "Circulating Market Cap": "Capitalisation Boursière Circulante",
    "Onchain Market Cap": "Capitalisation Boursière Onchain",
    "Token Transactions": "Transactions de Jetons",
    "X (Twitter)": "X (Twitter)",
    "Github": "Github",
    "Facebook": "Facebook",
    "Instagram": "Instagram",
    "Back to Top": "Retour en Haut",
    "Powered by BlocksScan": `Propulsé par ${process.env.REACT_APP_BASE_EXPLORER || "Blocksscan"}`,
    "BlocksScan is a Block Explorer and Analytics Platform for": `${process.env.REACT_APP_BASE_EXPLORER || "Blocksscan"} est une Plateforme d'Exploration et d'Analyse de Blocs pour`,
    "a decentralized smart contracts platform.": "une plateforme de contrats intelligents décentralisés.",
    "Company": "Entreprise",
    "About Us": "À Propos de Nous",
    "Brand Assets": "Actifs de Marque",
    "Careers": "Carrières",
    "We're Hiring": "Nous Recrutons",
    "Terms": "Conditions",
    "Privacy": "Confidentialité",
    "Community": "Communauté",
    "API Documentation": "Documentation API",
    "Network Status": "État du Réseau",
    "Newsletters": "Bulletins",
    "Products": "Produits",
    "Services": "Services",
    "Explorer-as-a-Service": "Explorateur en tant que Service",
    "API Plans": "Plans API",
    "All Rights Reserved": "Tous Droits Réservés",
    "Donations": "Dons",
    "Home": "Accueil",
    "CodeRunAI": "CodeRunAI",
    "Blockchain": "Blockchain",
    "Pending Transactions": "Transactions en Attente",
    "Contract Internal Transactions": "Transactions Internes de Contrat",
    "View Blocks": "Voir les Blocs",
    "Top Accounts": "Comptes Principaux",
    "Token Transfers": "Transferts de Jetons",
    "Top Mints": "Meilleures Frappes",
    "Latest Trades": "Derniers Échanges",
    "Latest Transfers": "Derniers Transferts",
    "Latest Mints": "Dernières Frappes",
    "Resources": "Ressources",
    "Charts And Stats": "Graphiques et Statistiques",
    "Top Statistics": "Statistiques Principales",
    "Leaderboard": "Classement",
    "Directory": "Annuaire",
    "Developers": "Développeurs",
    "Verify Contract": "Vérifier Contrat",
    "Similar Contract Search": "Recherche de Contrat Similaire",
    "Smart Contract Search": "Recherche de Contrat Intelligent",
    "Contract Diff Checker": "Vérificateur de Différences de Contrat",
    "Vyper Online Compiler": "Compilateur en Ligne Vyper",
    "Bytecode to Opcode": "Bytecode en Opcode",
    "Broadcast Transaction": "Diffuser Transaction",
    "More": "Plus",
    "Balance Checker": "Vérificateur de Solde",
    "Gas Tracker": "Suivi de Gaz",
    "Light": "Clair",
    "Dim": "Tamisé",
    "Dark": "Sombre",
    "Gas": "Gaz",
    "Last Updated": "Dernière Mise à Jour",
    "Gas tracker overview": "Aperçu du suivi de gaz",
    "All Transactions": "Toutes les Transactions",
    "Custom Card": "Carte Personnalisée",
    "Customize this card by selecting one of the options below": "Personnalisez cette carte en sélectionnant une des options ci-dessous",
    "Advanced Filters": "Filtres Avancés",
    "Present": "Présent",
    "Saved Filters": "Filtres Enregistrés",
    "Close": "Fermer",
    "Save Changes": "Sauvegarder les Modifications",
    "Top NFT": "Top NFT",
    "Top Contract": "Top Contrat",
    "Top Token": "Top Jeton",
    "Latest Transaction": "Dernière Transaction",
    "Latest Block": "Dernier Bloc",
    "Live": "En Direct",
    "WIP": "En Cours"
  }
};