import React, {FC} from 'react';
import BSkeleton from "./index";
import BSkeletonTable from "./b-skeleton-table";

interface BSkeletonPageProps {
    length?: number;
    col?: number;
}

const BSkeletonPage: FC<BSkeletonPageProps> = ({length, col}) => {


    return (
        <React.Fragment>
            <div className="py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bs-page-title">
                                <BSkeleton height="18px" width="200px"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bs-card">
                                <div className="bs-card-header py-3 px-4 d-flex align-items-center justify-content-between">
                                    <h2 className="bs-card-header-title"><BSkeleton height="18px" width="200px"/></h2>
                                    <div>
                                        <BSkeletonTable length={1} col={1}/>
                                    </div>
                                </div>
                                <div className="bs-card-body p-0">
                                    <div className={`bs-table`}>
                                        <BSkeletonTable length={50} col={7}/>
                                    </div>
                                </div>
                                <div className="bs-card-footer p-3">
                                    <div>
                                        <BSkeletonTable length={1} col={1}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BSkeletonPage;
