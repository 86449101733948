import React, {FC, useMemo, useState} from "react";
import BTooltip from "../../../components/b-tooltip";

interface BCopyProps {
    data?: any;
}

const formatMethodName = (methodName: string): string => {
    return methodName
        .split(/(?=[A-Z])/)
        .join(" ")
        .replace(/^\w/, (c) => c.toUpperCase());
};

const MethodName: FC<BCopyProps> = ({data}) => {
    const [copied, setIsCopied] = useState(false);

    const name = useMemo(() => data?.method_name ? formatMethodName(data?.method_name) : data?.method ? formatMethodName(data?.method) : 'Transfer', [])
    const method = useMemo(() => data?.method ? formatMethodName(data?.method) : 'Transfer', [])

    const handleCopy = (e: any) => {
        e?.preventDefault();
        if (navigator.clipboard) {
            navigator.clipboard.writeText(method as string).then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = method as string;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
    };

    return (
        <BTooltip text={name}>
            <span
                onClick={handleCopy}
                style={{maxWidth: "95px", padding: ".4rem .5rem", borderRadius: "10px"}}
                className="d-block badge border border-opacity-10 text-dark fw-normal text-truncate w-100 px-2 bg-light"
            >
                <span className="dot"></span>
                {copied ? 'Copied' : name}
            </span>
        </BTooltip>
    );
};

export default MethodName;
