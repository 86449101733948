import React from 'react';
import { useAccount } from 'wagmi';
import { useConnectModal, useAccountModal, useChainModal } from '@rainbow-me/rainbowkit';
import { useDisconnect } from 'wagmi';

export default function useWallet() {
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();
  const { disconnect } = useDisconnect();
  const [isClientLoaded, setIsClientLoaded] = React.useState(false);

  React.useEffect(() => {
    setIsClientLoaded(true);
  }, []);

  const handleConnect = React.useCallback(() => {
    if (openConnectModal) {
      openConnectModal();
    }
  }, [openConnectModal]);

  const handleAccountConnected = React.useCallback(({ isReconnected }: { isReconnected: boolean }) => {
    return () => {};
  }, []);

  const handleDisconnect = React.useCallback(() => {
    disconnect();
  }, [disconnect]);

  const { address, isDisconnected } = useAccount();

  const isWalletConnected = isClientLoaded && !isDisconnected && address !== undefined;

  return {
    isWalletConnected,
    addressHash: address || '',
    connect: handleConnect,
    disconnect: handleDisconnect,
    isModalOpen: openAccountModal !== undefined,
  };
}