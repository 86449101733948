import React, {FC, useMemo} from "react";
import BSkeletonTable from "../b-skeleton/b-skeleton-table";
import BEmpty from "../b-empty";
import BTooltip from "../b-tooltip";
import BCopy from "../b-copy";
import BNewPagination from "../b-pagination_new";

interface BTable2Props {
    title: any;
    header: any;
    list: any;
    loading?: boolean;
    pagination?: any;
    totalRecords?: any;
    onPagination?: any;
    className?: any;
    topHeaderPagination?: any;
    sort?: any,
    onSort?: any,
    options?: any,
    topHeading?: any;
}

interface BTable2HeaderData {
    title: any;
    param: any;
    visible?: any;
    order?: any;
    onSort?: any;
    sort?: any;
    sortFlag?: any;
    headerStyle?: any;
}

interface BTable2DataData {
    data: any;
    title: any;
    param: any;
    tooltip?: any;
    render?: any;
    index?: any;
    copy?: any;
    visible?: any;
    options?: any;
}

const BTable2: FC<BTable2Props> = (props) => {
    const isTopHeaderPaginationShow = typeof props?.topHeaderPagination == "boolean" ? props?.topHeaderPagination : true;
    const length = useMemo(() => {
        return props?.header.filter((e: any) => typeof e.visible === 'undefined' || e.visible).length;
    }, [props.header]);
    return (
        <div className="bs-card">
            {isTopHeaderPaginationShow && (<React.Fragment>
                {props?.topHeading && <div className="bs-card-header py-3 px-4 d-flex align-items-center justify-content-between" style={{borderBottom: 'none !important'}}>{props?.topHeading}</div>}
                <div className="bs-card-header p-3 bs-card-table-header">
                    <h2 className="bs-card-header-title">{props?.title}</h2>
                    <div>
                        <BNewPagination pagination={props?.pagination} totalRecords={props?.totalRecords} onPagination={(data) => props?.onPagination(data)}
                                        options={props?.options}/>
                    </div>
                </div>
            </React.Fragment>)}
            <div className="bs-card-body p-0" style={{overflowX: 'auto'}}>
                <table className={`bs-table ${props?.className}`}>
                    <tr className="bs-table-row bs-table-header">
                        {props?.header?.map((data: any) => {
                            if (typeof data?.visible === "boolean" && !data?.visible) return null;
                            else return <BTable2Header
                                title={data?.title}
                                headerStyle={data?.headerStyle}
                                param={data?.param}
                                order={props?.sort?.order}
                                onSort={(e: any) => props?.onSort(e)}
                                sort={props?.sort?.sort}
                                sortFlag={data?.sort}
                            />;
                        })}
                    </tr>
                    {props?.loading ? (
                        <BSkeletonTable length={50} col={length}/>
                    ) : (
                        <React.Fragment>
                            {props?.list?.map((data: any, i: number) => {
                                return (
                                    <tr className="bs-table-row" key={data?.tx_hash}>
                                        {props?.header.map((header: any) => {
                                            if (typeof header?.visible === "boolean" && !header?.visible) return null;
                                            else
                                                return (
                                                    <BTable2Data
                                                        title={header.title}
                                                        param={header.param}
                                                        data={data}
                                                        render={header?.render}
                                                        tooltip={header?.tooltip}
                                                        copy={header?.copy}
                                                        index={i}
                                                    />
                                                );
                                        })}
                                    </tr>
                                );
                            })}
                        </React.Fragment>
                    )}
                </table>
                {!props?.loading && props?.list?.length === 0 && <BEmpty text=""/>}
            </div>
            <div className="bs-card-footer p-3">
                <div>
                    {/* <BPagination pagination={props?.pagination} totalRecords={props?.totalRecords} onPagination={(data) => props?.onPagination(data)}/> */}
                    <BNewPagination pagination={props?.pagination} totalRecords={props?.totalRecords} onPagination={(data) => props?.onPagination(data)} options={props?.options}/>
                </div>
            </div>
        </div>
    );
};

const BTable2Header: FC<BTable2HeaderData> = React.memo((props) => {
    return (
        <React.Fragment>
            <th className="bs-table-cell" style={{...props?.headerStyle}}>
                <div className="d-flex bs-cp"
                     onClick={() => props?.sortFlag && props?.onSort({sort: props?.param, order: props?.order === 'desc' ? 'asc' : 'desc'})}>
                    {props?.title}
                    {props?.sort === props?.param && <div className="ms-1">
                        {props?.order === 'asc' && <span><i className="bi bi-sort-up-alt"></i></span>}
                        {props?.order === 'desc' && <span><i className="bi bi-sort-down"></i></span>}
                    </div>}
                </div>
            </th>
        </React.Fragment>
    );
});
const BTable2Data: FC<BTable2DataData> = React.memo((props) => {
    const render = useMemo(() => {
        return (props?.render ? props?.render(props?.data, props.index) : props?.data[props.param]) || "-";
    }, []);
    const tooltip = useMemo(() => {
        return typeof props?.tooltip === "function" ? props?.tooltip(props?.data) : typeof props?.tooltip === "string" ? props?.tooltip || "-" : render;
    }, []);
    const copy = useMemo(() => {
        return typeof props?.copy === "function" ? props?.copy(props?.data) : props?.copy || "-";
    }, []);
    return (
        <React.Fragment>
            <td className="bs-table-cell" data-title={props?.title}>
                <div className={!!props?.copy ? "d-flex align-item-center" : ""}>
                    {props?.tooltip ? (
                        <BTooltip text={<span>{tooltip}</span>} options={{}}>
                            <div>{render}</div>
                        </BTooltip>
                    ) : (
                        render
                    )}
                    {!!props.copy && <BCopy value={copy}/>}
                </div>
            </td>
        </React.Fragment>
    );
});

BTable2Header.defaultProps = {
    visible: true,
};
BTable2Data.defaultProps = {
    visible: true,
};
export default BTable2;
