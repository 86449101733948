import React, {FC} from 'react';
import Highcharts from 'highcharts';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsReact from 'highcharts-react-official';

HighchartsHeatmap(Highcharts);

const ChartGasPriceHeatMap: FC = () => {
    const chartOptions: Highcharts.Options = {
        chart: {
            type: 'heatmap',
            marginTop: 20,
            marginBottom: 110,
        },
        exporting: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22'],
            lineColor: '#FFF',
            tickColor: '#FFFFFF',
            gridLineColor: 'transparent',
            title: {
                text: 'Hours (UTC)',
                align: 'middle',
            },
            labels: {
                useHTML: true,
                formatter: function () {
                    return '<span class="text-muted">' + this.value + '</span>';
                },
            },
        },
        yAxis: {
            categories: ['Tue, 23 Jan', 'Mon, 22 Jan', 'Sun, 21 Jan', 'Sat, 20 Jan', 'Fri, 19 Jan', 'Thu, 18 Jan', 'Wed, 17 Jan', 'Tue, 16 Jan'],
            title: {
                text: '',
            },
            lineColor: '#FFF',
        },
        colorAxis: {
            min: 0,
            minColor: '#FFF7ED',
            maxColor: '#EA580C',
        },
        legend: {
            align: 'center',
            margin: 0,
            verticalAlign: 'bottom',
            y: 20,
            symbolHeight: 20,
        },
        tooltip: {
            formatter: function () {
                return '<b>Date:</b> ' + (this.series?.yAxis?.categories[this.point?.y as number] || '') + '<br>' +
                    '<b>Hour:</b> ' + (this.series?.xAxis?.categories[this.point?.x as number] || '') + '<br>' +
                    '<b>Gwei:</b> ' + (this.point?.value || '');
            },
        },
        series: [{
            name: 'Sales per month',
            borderWidth: 1,
            data: [
                [1, 1, 58], [1, 2, 70], [1, 3, 28], [1, 4, 11], [1, 5, 65], [1, 6, 46], [1, 0, 12],
                [2, 1, 51], [2, 2, 52], [2, 3, 69], [2, 4, 57], [2, 5, 78], [2, 6, 75], [2, 0, 69],
                [3, 1, 57], [3, 2, 37], [3, 3, 61], [3, 4, 6], [3, 5, 69], [3, 6, 51], [3, 0, 33],
                [4, 1, 62], [4, 2, 4], [4, 3, 60], [4, 4, 71], [4, 5, 2], [4, 6, 6], [4, 0, 10],
                [5, 1, 41], [5, 2, 69], [5, 3, 13], [5, 4, 19], [5, 5, 8], [5, 6, 63], [5, 7, 49],
                [6, 1, 50], [6, 2, 56], [6, 3, 63], [6, 4, 44], [6, 5, 36], [6, 6, 47], [6, 7, 23],
                [7, 1, 49], [7, 2, 63], [7, 3, 28], [7, 4, 59], [7, 5, 57], [7, 6, 15], [7, 7, 49],
                [8, 1, 68], [8, 2, 49], [8, 3, 39], [8, 4, 28], [8, 5, 63], [8, 6, 58], [8, 7, 19],
                [9, 1, 35], [9, 2, 61], [9, 3, 61], [9, 4, 21], [9, 5, 6], [9, 6, 10], [9, 7, 34],
                [10, 1, 74], [10, 2, 62], [10, 3, 31], [10, 4, 43], [10, 5, 40], [10, 6, 70], [10, 7, 55],
                [11, 1, 12], [11, 2, 14], [11, 3, 43], [11, 4, 69], [11, 5, 39], [11, 6, 53], [11, 7, 23],
                [12, 1, 20], [12, 2, 64], [12, 3, 64], [12, 4, 31], [12, 5, 67], [12, 6, 17], [12, 7, 26],
                [13, 1, 8], [13, 2, 44], [13, 3, 48], [13, 4, 72], [13, 5, 29], [13, 6, 43], [13, 7, 22],
                [14, 1, 28], [14, 2, 58], [14, 3, 39], [14, 4, 50], [14, 5, 2], [14, 6, 49], [14, 7, 36],
                [15, 1, 57], [15, 2, 53], [15, 3, 66], [15, 4, 39], [15, 5, 47], [15, 6, 10], [15, 7, 4],
                [16, 1, 60], [16, 2, 68], [16, 3, 17], [16, 4, 7], [16, 5, 2], [16, 6, 11], [16, 7, 28],
                [17, 1, 57], [17, 2, 40], [17, 3, 13], [17, 4, 59], [17, 5, 20], [17, 6, 61], [17, 7, 50],
                [18, 1, 20], [18, 2, 27], [18, 3, 68], [18, 4, 55], [18, 5, 48], [18, 6, 37], [18, 7, 76],
                [19, 1, 51], [19, 2, 13], [19, 3, 41], [19, 4, 36], [19, 5, 70], [19, 6, 50], [19, 7, 61],
                [20, 1, 37], [20, 2, 6], [20, 3, 48], [20, 4, 30], [20, 5, 71], [20, 6, 37], [20, 7, 41],
                [21, 1, 7], [21, 2, 42], [21, 3, 21], [21, 4, 45], [21, 5, 45], [21, 6, 2], [21, 7, 46],
                [22, 1, 64], [22, 2, 26], [22, 3, 75], [22, 4, 12], [22, 5, 27], [22, 6, 49], [22, 7, 62],
                [23, 1, 69], [23, 2, 58], [23, 3, 33], [23, 4, 64], [23, 5, 3], [23, 6, 56], [23, 7, 11],
                [24, 1, 32], [24, 2, 17], [24, 3, 60], [24, 4, 32], [24, 5, 66], [24, 6, 62], [24, 7, 73],
            ],
            dataLabels: {
                enabled: false,
                color: '#000000',
            },
        }] as any,
    };

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </div>
    );
};

export default ChartGasPriceHeatMap;
