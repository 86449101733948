import React, {FC, useState} from "react";
import BTooltip from "../b-tooltip";

interface BCopyProps {
    value?: string | number;
    tooltip?: string;
    icon?: string;
}

const BCopy: FC<BCopyProps> = ({value, tooltip = "text", icon = "bi-copy"}) => {
    const [copied, setIsCopied] = useState(false);

    const handleCopy = (e: any) => {
        e?.preventDefault();
        if (navigator.clipboard) {
            navigator.clipboard.writeText(value as string).then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = value as string;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
    };

    return (
        <BTooltip text={`Copy ${tooltip}`}>
            &nbsp;
            {copied ? (
                <i className="bi bi-check2-all bs-copy bs-copied"></i>
            ) : (
                <i className={`bi ${icon} bs-copy`} onClick={handleCopy}></i>
            )}
        </BTooltip>
    );
};

export default BCopy;
